<section class="container mobiledb-Container">
  <section class="row">
    <!-- ===================Left Part====================== -->
    <section class="col-lg-2 col-xl-2 reservation-Summary-Section" *ngIf = "displayActiveSeasons">
      <section class="container active-season-container" >
        <span class="active-Season">
          {{activeSeasons}} {{'selectVaccines.reservations'|cxTranslate}}
        </span>
        <section class="row" *ngIf="orderHistory?.length>4" >
          <ng-select 
         
          [items]="orderHistory"
          bindLabel="guid"
          bindValue="guid"
          [multiple]="false"
          [clearable]="false"
          [(ngModel)]="chosenReservation"
          (change)="changeReservation(null)"
          class="w-100 reservation-history-dropdown"
          
        >
        </ng-select>

       
        </section>
        
        <section class="row" *ngIf="orderHistory.length<=4">
          <section class="col-12 col-xs-12 col-sm-12 col-lg-12 guid-Row" 
          *ngFor="let data of orderHistory">
               <span (click)="changeReservation(data)"  class="guid-Span"> {{data.guid}}</span>
          </section>
        </section>
        <section class="row m-0">
          <span class="reserve-Now-Link" (click)="reserveNow()">{{'selectVaccines.reserve_now'|cxTranslate}}</span>
        </section>
      </section>

    </section>
    <!-- ===================Middle Part====================== -->
    <section class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-12 col-sm-12
     reserve-Div" *ngIf="orderHistory?.length==0">
     <section *ngIf = "displayActiveSeasons">
      <section class="noReserve-Middle-Section-Header">
        <section>
          <span class="influenza-Vaccine-Span">{{'orderConfirmation.infVaccines'|cxTranslate}}</span>
        </section>
        <section>
          <span class="influenza-Vaccine-Active-Season-Span">{{activeSeasons}} {{'selectVaccines.season'|cxTranslate}}</span>
        </section>
      </section>
      <section class="noReserve-Middle-Section-Content">
  
        <section  [innerHTML]="fluParagraph">
        </section>
        <section class="">
          <button class="btn btn-Reserve btn-reserve-align" (click)="reserveNow()">
            {{'selectVaccines.reserve_now'|cxTranslate}}
          </button>
        </section>
      </section>
    </section>
    </section>
  
    <section class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-12 col-sm-12 
    reserve-Div-Charts" *ngIf="orderHistory?.length>0">
      <section class="container mobiledb-Container" *ngIf="displayActiveSeasons">
        <section class=" row noReserve-Middle-Section-Header">
          <section [ngClass]="{'col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8':languageUsed ==='fr',
          'col-12 col-sm-7 col-md-7 col-lg-7 col-xl-9':languageUsed !=='fr'
        }">
            <section>
              <section class="iphone-Center">
                <span class="influenza-Vaccine-Span">{{'selectVaccines.reservation'|cxTranslate}} {{idofReservation}}</span>
              </section>
              <section class="iphone-Center">
                <span class="influenza-Vaccine-Active-Season-Span">{{activeSeasons}} {{'selectVaccines.season'|cxTranslate}} |
                  {{statusDisplay}}</span>
              </section>
            </section>
          </section>
          <section class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-3 iphone-Center">
            <button class="btn resBtn" (click)="viewReservation()">
              {{'orderConfirmation.viewResDetails'|cxTranslate}}
            </button>
          </section>
        </section>
      </section>

      <section class="myShipmnets-Section">
        <h3>{{'orderConfirmation.myShipments'|cxTranslate}}</h3>
      </section>

      <app-flu-charts *ngIf="displayActiveSeasons" ></app-flu-charts>
    </section>
    <!-- ==============================Footer Part============================ -->

    <section class="col-lg-12 col-xl-12 footer-Container">
      <app-influenza-dashbaord-footer [reserveNowClicked]="reserveNowClicked" [displayActiveSeasons]="displayActiveSeasons"
        (newItemEvent)="proceedToCategory($event)"></app-influenza-dashbaord-footer>
    </section>
  </section>
</section>