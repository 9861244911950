import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'faq-list',
  templateUrl: './faq-list.component.html',
})
export class FaqListComponent implements OnInit {
  @Input() searchResults: any;
  @Input() searchInput: any;
  constructor() {}
  ngOnInit(): void {}

  toggleChild(e): void {
    const targetItem =
      e.target.parentElement.parentElement.parentElement.querySelector(
        '.item-child-answer'
      );
    if (targetItem.classList.contains('show')) {
      targetItem.classList.remove('show');
      e.target.classList.remove('active');
    } else {
      targetItem.classList.add('show');
      e.target.classList.add('active');
    }
  }
}
