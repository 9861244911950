import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter, take } from "rxjs/operators";
import { CustomAddToCartService } from "../custom-add-to-cart/custom-add-to-cart.service";
import { LoactionStripService } from "../shared/modules/location-strip/loaction-strip.service";

@Component({
  selector: 'app-change-location-modal',
  templateUrl: './change-location-modal.component.html',
  styleUrls: ['./change-location-modal.component.scss'],
})
export class ChangeLocationModalComponent implements OnInit {
  constructor(
    protected modal: NgbModal,
    protected cartService: CustomAddToCartService,
    protected locationStripService: LoactionStripService
  ) { }

  listSelectedData;
  searchedClientList;
  getuserData;
  selectedData;
  cartId;
  hidemsg: boolean;
  shippingDefaultAddress;
  soldToAddress;
  isDefaultAddressChecked: boolean;
  ngOnInit(): void {
    this.cartService.userAccountDetails$.pipe(filter(Boolean), take(1)).subscribe((userDataget: any) => {
      this.getuserData = userDataget.res.shippingAddresses;
      this.soldToAddress = userDataget.res.soldToAddress;
      this.searchedClientList = this.getuserData;

      this.shippingDefaultAddress = this.searchedClientList.find(address => { return address.defaultAddress === true });
    })

    this.cartService.cartItems$.pipe(filter(Boolean), take(1)).subscribe((cart: any) => {
      this.cartId = cart.code;
      this.selectedData = cart.childCarts.find(el => el.cartType === "STANDARD")?.shipToParty;
    })
  }

  onOptionsSelected(data) {
    this.selectedData = data;
  }

  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchedClientList = this.getuserData.filter(
        (x) =>
          (x.companyName != undefined &&
            x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID != undefined &&
            x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line1 != undefined &&
            x.line1.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line2 != undefined &&
            x.line2.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.town != undefined &&
            x.town.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.postalCode != undefined &&
            x.postalCode.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.searchedClientList = this.getuserData;
    }

  }

  defaultCheck(event) {
    this.isDefaultAddressChecked = event.target['checked'];
  }

  onSubmit(): void {
    const unitId = this.selectedData?.uid || this.selectedData?.unitID;
    this.locationStripService.saveSelectedAccount(this.cartService.userId, this.soldToAddress?.unitID)
    this.locationStripService.saveSelectedShipToAddress(this.cartService.userId, unitId, !!this.isDefaultAddressChecked);
    if (this.cartId) {
      this.cartService.saveLocation(unitId, this.cartId, this.cartService.userId);
    }
  }
}