<section class="row">
    <section class="col-12 col-sm-12 col-md-12 col-lg-12">
        <span class="back-To-User float-right" (click)='backToView()'>
            <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left">
            {{'customerOverview.backToUser'| cxTranslate}}</span>
    </section>
</section>

<section class="container">
    <section class="row">
        <section class="col-12 col-xs-12 col-md-12 col-lg-2 col-xl-2 white-Bg-left-panel"></section>
        <section class="col-12 col-xs-12 col-md-12 col-lg-10 col-xl-10 white-Bg">
            <section class="row mt-b2b-row">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3>
                        <span class="header-Css">
                            {{'customerOverview.B2BAccountName'| cxTranslate}}
                        </span>
                    </h3>
                </section>
                <section class="row ml-0">
                    <section class="col-lg-2 col-3 col-xs-2 col-sm-2 col-md-2 margin-bottom-b2b"><span
                            class="inner-Headers">
                            {{'customerOverview.id'| cxTranslate}}
                        </span></section>
                    <section class="col-lg-10 col-9 col-xs-10 col-sm-10 col-md-10 "><span>
                            {{loginUserInfo?.unitId}}</span></section>

                    <section class="col-lg-2 col-3 col-xs-2 col-sm-2 col-md-2 margin-bottom-b2b">
                        <span class="inner-Headers">{{'profile.name'| cxTranslate}}</span>
                    </section>
                    <section class="col-lg-10  col-9 col-xs-10 col-sm-10 col-md-10 ">
                        <span>{{loginUserInfo?.unitName}}</span>
                    </section>

                    <section class="col-lg-2 col-3 col-xs-2 col-sm-2 col-md-2 margin-bottom-b2b">
                        <span class="inner-Headers">{{'customerOverview.phone'| cxTranslate}}</span>
                    </section>
                    <section class="col-lg-10  col-9 col-xs-10 col-sm-10 col-md-10 ">
                        <span>{{loginUserInfo?.unitPhone}}</span>
                    </section>

                    <section class="col-lg-2 col-3 col-xs-2 col-sm-2 col-md-2 margin-bottom-b2b">
                        <span class="inner-Headers">{{'customerOverview.custUserId'| cxTranslate}}</span>
                    </section>
                    <section class="col-lg-10  col-9 col-xs-10 col-sm-10 col-md-10 ">
                        <span>{{loginUserInfo?.userName}}</span>
                    </section>
                </section>
            </section>
            <hr />
            <!-- =========================Name Form============================================ -->
            <section class="row">

                <section class="col-md-9 col-9 col-sm-9">
                    <h3>
                        <span class="header-Css">
                            {{'profile.name'| cxTranslate}}
                        </span>
                    </h3>
                </section>
                <section class="col-md-3 col-3 col-sm-3">
                    <h3 class="float-right">
                        <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Image"
                            *ngIf="nameEditIsNotClicked">
                        <span class="edit-Option" (click)="editNameField()" *ngIf="nameEditIsNotClicked">
                            {{'profile.edit'| cxTranslate}}
                        </span>
                    </h3>
                </section>
            </section>
            <section class="container p-0 " [ngClass]="{'margin-Down':nameEditIsNotClicked,
  'edited-Fields-Margin-Value':!nameEditIsNotClicked}">
                <section class="row" *ngIf="nameEditIsNotClicked">
                    <section class="col-md-12 ">
                        <label class="inner-Headers"> {{'profile.firstName'| cxTranslate}}</label>
                    </section>
                    <section class="col-md-12">
                        <span class="inner-Content inner-Content-Font-Color">{{
                            nameForm.get("actualFirstName").value
                            }}</span>
                    </section>
                    <section class="col-md-12">
                        <label class="inner-Headers">{{'profile.lastName'| cxTranslate}}</label>
                    </section>
                    <section class="col-md-12">
                        <span class="inner-Content inner-Content-Font-Color">{{
                            nameForm.get("actualLastName").value
                            }}</span>
                    </section>
                </section>

                <section class="row" [formGroup]="nameForm" *ngIf="!nameEditIsNotClicked">
                    <section class="col-md-12 ">
                        <label class="inner-Headers">{{'profile.firstName'| cxTranslate}}</label>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <input class="form-control  text-Field-Styling" formControlName="actualFirstName" [ngClass]="{
            required:
            nameForm.get('actualFirstName').invalid &&
              (nameForm.get('actualFirstName').touched ||nameForm.get('actualFirstName').dirty)
          }" />
                        <span class="text-danger" *ngIf="
            nameForm.get('actualFirstName').value.length==0 &&
            (nameForm.get('actualFirstName').touched ||nameForm.get('actualFirstName').dirty)
          ">{{'profile.requiredError'| cxTranslate}}</span>
                        <span class="text-danger" *ngIf="
          nameForm.get('actualFirstName').value.length!=0 &&
          nameForm.get('actualFirstName').invalid &&
          (nameForm.get('actualFirstName').touched ||nameForm.get('actualFirstName').dirty)
        ">{{'profile.invalidName'| cxTranslate}}</span>

                    </section>
                    <section class="col-md-12">
                        <label class="inner-Headers">{{'profile.lastName'| cxTranslate}}</label>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <input class="form-control  text-Field-Styling" formControlName="actualLastName" [ngClass]="{
            required:
              nameForm.get('actualLastName').invalid &&
              (nameForm.get('actualLastName').touched ||nameForm.get('actualLastName').dirty)
          }" />
                        <span class="text-danger" *ngIf="
          (nameForm.get('actualLastName').touched ||nameForm.get('actualLastName').dirty) &&
            nameForm.get('actualLastName').value.length == 0
          ">{{'profile.requiredError'| cxTranslate}}</span>
                        <span class="text-danger" *ngIf="
          nameForm.get('actualLastName').value.length!=0 &&
          nameForm.get('actualLastName').invalid &&
          (nameForm.get('actualLastName').touched ||nameForm.get('actualLastName').dirty)
        ">{{'profile.invalidName'| cxTranslate}}</span>
                    </section>
                </section>
                <section class="row w-100  btn-Row" *ngIf="!nameEditIsNotClicked">
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
                        <button class="btn btn-default cancelBtn-Css " (click)="cancelNameUpdate()">
                            {{'profile.cancel'|cxTranslate}}
                        </button>
                    </section>
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding">
                        <button class="btn btn-info updateBtn-Css " (click)="updateNameField()" [disabled]="
            nameForm.get('actualFirstName').invalid || nameForm.get('actualLastName').invalid 
          ">
                            {{'profile.update'|cxTranslate}}
                        </button>
                    </section>
                </section>
            </section>
            <hr />

            <!-- =========================Role============================================ -->
            <section class="row ">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3><span class="header-Css">{{'profile.role'| cxTranslate}}</span></h3>
                </section>
                <section class="col-md-3 col-3 col-sm-3">
                    <h3 class="float-right">
                        <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Image"
                            *ngIf="roleEditIsNotClicked">
                        <span class="edit-Option" (click)="editRoleField()"
                            *ngIf="roleEditIsNotClicked">{{'profile.edit'| cxTranslate}}</span>
                    </h3>
                </section>
            </section>
            <section class="container p-0" [ngClass]="{'margin-Down':roleEditIsNotClicked,
  'edited-Fields-Margin-Value':!roleEditIsNotClicked}">
                <section class="row" *ngIf="roleEditIsNotClicked">
                    <section class="col-md-12">
                        <span class="inner-Content inner-Content-Font-Color">{{ displayRoleofUser }}</span>
                    </section>
                </section>
                <section class="row " *ngIf="!roleEditIsNotClicked">
                    <section class="col-md-12 col-12 col-sm-12 col-lg-12 col-xs-12 col-xl-5
       fields-Margin-Value role-Margin-Bottom user-Profile">
                        <ng-select class="w-100" [items]="roleOfUserArray" bindLabel="name" bindValue="code"
                            [multiple]="false" name="chosenRoleofUser" [clearable]="false" placeholder="Select Role"
                            [(ngModel)]="chosenRoleofUser">
                        </ng-select>


                    </section>
                </section>
                <section class="row w-100  btn-Row" *ngIf="!roleEditIsNotClicked">
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
                        <button class="btn btn-default cancelBtn-Css" (click)="cancelRoleUpdate()">

                            {{'profile.cancel'|cxTranslate}}
                        </button>
                    </section>
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding">
                        <button class="btn btn-default updateBtn-Css" (click)="updateRoleField()">
                            {{'profile.update'|cxTranslate}}

                        </button>
                    </section>
                </section>
            </section>
            <hr />
            <!-- =========================reset link============================================ -->
            <section class="row ">
                <section class="col-xl-8 col-lg-8 col-md-8 col-10 col-xs-8 col-sm-9">
                    <h3><span class="header-Css">
                            {{'customerOverview.PasswordReset'| cxTranslate}}</span></h3>
                </section>
                <section class="col-xl-4 xol-lg-4 col-md-4 col-10 col-xs-6 col-sm-6 padding-right-btn-div">
                    <button class="btn btn-default updateBtn-Css" (click)="resetPasswordLink()">
                        <span class="resetLink-btn-text"> {{'customerOverview.SendResetLink'| cxTranslate}}</span>

                    </button>
                </section>
                <section class="col-md-12 col-12 col-lg-12 col-xs-12">
                    <span *ngIf="resetPasswordBoolean===true">
                        {{'customerOverview.successMsg'| cxTranslate}} {{loginUserInfo?.email}}
                    </span>
                    <span class="text-danger" *ngIf="resetPasswordBoolean===false">
                        {{'customerOverview.failureMsg'| cxTranslate}} {{loginUserInfo?.email}}
                    </span>
                </section>
            </section>

            <hr />
            <!-- ==========================================Password Recocecry============================== -->
            <section class="row ">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3 class="m-0"><span class="header-Css">{{"profile.passRecQue"|cxTranslate}} </span></h3>
                </section>

            </section>
            <section class="container p-0 " [ngClass]="{'margin-Down':passRecEditIsNotClicked,
  'edited-Fields-Margin-Value':!passRecEditIsNotClicked}">
                <section class="row">
                    <section class="col-md-12">
                        <span class="inner-Content inner-Content-Font-Color">{{ displayQueForPassRecovery }}</span>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <span class="inner-Content-Font-Color passRec-Ans-Bold">{{ansForQuePassRecovery}}</span>

                    </section>

                </section>
                <section class="row" *ngIf="!passRecEditIsNotClicked">
                    <section class="col-md-12 ">
                        <label class="inner-Headers">{{"profile.passRecQue"|cxTranslate}} </label>
                    </section>
                    <section class="col-md-12 col-12 col-sm-12 col-lg-12 col-xs-12 col-xl-5 
      fields-Margin-Value user-Profile">
                        <ng-select [items]="passRecQuesArray" bindLabel="name" bindValue="code" [multiple]="false"
                            name="chosenRoleofUser" [clearable]="false" placeholder="Select Question"
                            [(ngModel)]="chosenQueForPassRecovery" class="w-100">
                        </ng-select>

                    </section>
                    <section class="col-md-12 ">
                        <label class="inner-Headers"> {{"profile.passRecAns"|cxTranslate}} </label>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <input class="w-100 form-control  text-Field-Styling"
                            [ngClass]="{'required':ansForQuePassRecovery=='' && ansForQuePassRecovery!=null}"
                            [(ngModel)]="ansForQuePassRecovery" />
                        <span class="text-danger" *ngIf="ansForQuePassRecovery=='' && ansForQuePassRecovery!=null ">
                            {{'profile.requiredError'|cxTranslate}}
                        </span>
                    </section>

                </section>
                <section class="row w-100  btn-Row" *ngIf="!passRecEditIsNotClicked">
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
                        <button class="btn btn-default cancelBtn-Css" (click)="cancelPassRecUpdate()">
                            {{'profile.cancel'|cxTranslate}}
                        </button>
                    </section>
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding">
                        <button [disabled]="ansForQuePassRecovery == '' || chosenQueForPassRecovery=='' "
                            class="btn btn-default updateBtn-Css">
                            {{'profile.update'|cxTranslate}}

                        </button>
                    </section>
                </section>
            </section>
            <hr />
            <!-- ==========================================Email============================== -->
            <section class="row">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3 class="m-0"><span class="header-Css"> {{"profile.email"|cxTranslate}}</span></h3>
                </section>
                <section class="col-md-3 col-3 col-sm-3">
                    <h3 class="float-right">
                        <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Image"
                            *ngIf="emailEditIsNotClicked" />
                        <span class="edit-Option" (click)="editEmailField()"
                            *ngIf="emailEditIsNotClicked">{{'profile.edit'| cxTranslate}}</span>
                    </h3>
                </section>
            </section>
            <section class="container p-0" [ngClass]="{'margin-Down':emailEditIsNotClicked,
  'edited-Fields-Margin-Value':!emailEditIsNotClicked}">
                <section class="row" *ngIf="emailEditIsNotClicked">
                    <section class="col-md-12">
                        <span class="inner-Content inner-Content-Font-Color">{{ emailForm.get("emailId").value }}</span>
                    </section>
                </section>

                <section class="row" [formGroup]="emailForm" *ngIf="!emailEditIsNotClicked">
                    <section class="col-md-12 ">
                        <label class="inner-Headers"> {{"profile.email"|cxTranslate}}</label>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <input class="form-control  text-Field-Styling" formControlName="emailId" [ngClass]="{
            required:
              (emailForm.get('emailId').value.length == 0  || emailForm.get('emailId').invalid) &&
              (emailForm.get('emailId').touched ||  emailForm.get('emailId').dirty)
          }" />

                        <span class="text-danger" *ngIf="emailForm.get('emailId').invalid && 
        (emailForm.get('emailId').dirty || emailForm.get('emailId').touched) 
        && emailForm.get('emailId').value.length!=0">
                            {{'profile.invalidEmail'| cxTranslate}}
                        </span>
                        <span class="text-danger" *ngIf="(emailForm.get('emailId').dirty || emailForm.get('emailId').touched) 
        && emailForm.get('emailId').value.length==0 "> {{'profile.requiredError'| cxTranslate}}
                        </span>
                    </section>

                    <section class="col-md-12">
                        <label class="inner-Headers">{{'profile.confirmEmail'| cxTranslate}}</label>
                    </section>
                    <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value">
                        <input class="form-control  text-Field-Styling" formControlName="confirmEmailId" [ngClass]="{
            required:
              (emailForm.get('confirmEmailId').value.length == 0 ||
              emailForm.get('confirmEmailId').value != emailForm.get('emailId').value) &&
              (emailForm.get('confirmEmailId').touched || emailForm.get('confirmEmailId').dirty),
            block: emailForm.get('emailId').value.length == 0
          }" />
                        <span class="text-danger" *ngIf="
            emailForm.get('confirmEmailId').value !=
              emailForm.get('emailId').value &&  emailForm.get('confirmEmailId').value !=0 &&
              (emailForm.get('confirmEmailId').touched || emailForm.get('confirmEmailId').dirty)
          ">
                            {{"profile.EmailIdnotMisMatch"|cxTranslate}}
                        </span>
                        <span class="text-danger" *ngIf="
          emailForm.get('confirmEmailId').value ==0
            &&
            (emailForm.get('confirmEmailId').touched || emailForm.get('confirmEmailId').dirty)
        ">
                            {{"profile.requiredError"|cxTranslate}}
                        </span>

                    </section>
                </section>
                <section class="row w-100  btn-Row" *ngIf="!emailEditIsNotClicked">
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
                        <button class="btn btn-default cancelBtn-Css" (click)="cancelEmailUpdate()">
                            {{'profile.cancel'|cxTranslate}}
                        </button>
                    </section>
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding">
                        <button class="btn btn-info updateBtn-Css" (click)="updateEmailField()" [disabled]="
          emailForm.invalid ||
            (emailForm.get('confirmEmailId').value !=
              emailForm.get('emailId').value)
          ">
                            {{'profile.update'|cxTranslate}}

                        </button>
                    </section>
                </section>
            </section>
            <hr />
            <!-- ==================================first radio========================================== -->
            <section class="row">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3 class="m-0"><span class="header-Css">
                            {{'customerOverview.DisableUserID'| cxTranslate}}</span></h3>
                </section>
                <section class="col-md-3 col-3 col-sm-3">
                    <h3 class="float-right">
                        <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Image"
                            *ngIf="userIdEditIsNotClicked" />
                        <span class="edit-Option" (click)="editUserIdRadio()"
                            *ngIf="userIdEditIsNotClicked">{{'profile.edit'| cxTranslate}}</span>
                    </h3>
                </section>
            </section>
            <section class="container p-0">
                <section class="row" *ngIf="userIdEditIsNotClicked">
                    <section class="row radio-Div w-100">
                        <section class="col-xl-1 col-lg-1 col-md-3 col-3 col-sm-3 w-50">
                            <div class="custom-control custom-radio radio_grp label-location">
                                <input type="radio" id="emailConsentYesRadio" name="emailConsent"
                                    class="custom-control-input" value='true' disabled
                                    [(ngModel)]="userIdDisableReadOnly" />
                                <label class="custom-control-label secloct" for="emailConsentYesRadio">
                                    {{'customerOverview.true'| cxTranslate}}
                                </label>
                            </div>

                        </section>
                        <section class="col-xl-1 col-lg-1 col-md-3 col-3 col-sm-3 p-0">
                            <div class="custom-control custom-radio radio_grp label-location w-50">
                                <input type="radio" id="emailConsentNoRadio" name="emailConsent"
                                    class="custom-control-input" value='false' [(ngModel)]="userIdDisableReadOnly"
                                    disabled />
                                <label class="custom-control-label secloct" for="emailConsentNoRadio">
                                    {{'customerOverview.false'| cxTranslate}}
                                </label>
                            </div>

                        </section>
                    </section>
                </section>
                <section class="row" *ngIf="!userIdEditIsNotClicked">
                    <section class="row radio-Div w-100">
                        <section class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 w-100">
                            <div class="custom-control custom-radio radio_grp label-location">
                                <input type="radio" id="emailConsentYesRadio" name="emailConsent"
                                    class="custom-control-input" value="true" [(ngModel)]="userIdDisable" />
                                <label class="custom-control-label secloct" for="emailConsentYesRadio">
                                    {{'customerOverview.true'| cxTranslate}}
                                </label>
                            </div>

                        </section>
                        <section class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                            <div class="custom-control custom-radio radio_grp label-location w-50">
                                <input type="radio" id="emailConsentNoRadio" name="emailConsent"
                                    class="custom-control-input" value="false" [(ngModel)]="userIdDisable" />
                                <label class="custom-control-label secloct" for="emailConsentNoRadio">
                                    {{'customerOverview.false'| cxTranslate}}
                                </label>
                            </div>

                        </section>
                    </section>
                </section>
                <section class="row w-100  btn-Row userId-btn-row" *ngIf="!userIdEditIsNotClicked">
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
                        <button class="btn btn-default cancelBtn-Css" (click)="cancelUserIdDisable()">
                            {{'profile.cancel'|cxTranslate}}
                        </button>
                    </section>
                    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding">
                        <button class="btn btn-info updateBtn-Css" (click)="updateUserId()">
                            {{'profile.update'|cxTranslate}}

                        </button>
                    </section>
                </section>



            </section>
            <hr class="mb-1" />

            <!-- ==============================TABLE======================== -->
            <section class="row">
                <section class="col-md-9 col-9 col-sm-9">
                    <h3 class="m-0"><span class="header-Css">
                            {{'customerOverview.LastChangedHistory'| cxTranslate}} :</span></h3>
                </section>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-lg-12  tableDiv largeDevice-Table">
                <table aria-describedby="cardDetailsTable" class="table">
                    <thead>
                        <tr class="header-row">
                            <th scope="col" class="header">{{'customerOverview.changedAtrribute'| cxTranslate}}</th>
                            <th scope="col" class="header">{{'customerOverview.userChanged'| cxTranslate}} </th>
                            <th scope="col" class="header">{{'customerOverview.ChangedTime'| cxTranslate}}</th>
                            <th scope="col" class="header">{{'customerOverview.oldValue'| cxTranslate}}</th>
                            <th scope="col" class="header">{{'customerOverview.newValue'| cxTranslate}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="content-row" *ngFor="let i of changeHistoryArray">
                            <td class="statusTd col-Divider">
                                {{i?.modifiedAttribute}}
                            </td>
                            <td class="col-Divider">
                                {{i?.user}}
                            </td>
                            <td class="col-Divider">
                                {{i?.modifiedTime}}
                            </td>
                            <td class="col-Divider">
                                {{i?.oldValue}}

                            </td>
                            <td class="col-Divider">
                                {{i?.newValue}}
                            </td>


                        </tr>
                        <tr class="content-row" *ngIf="changeHistoryArray?.length===0">

                            <td colspan="5" class="text-center"><span ></span>No Records Found</td>

                        </tr>
                    </tbody>
                </table>

            </section>

            <!-- ===================Small Device Table================================ -->
            <section class="container smallDevice-Table" *ngFor="let i of changeHistoryArray,let j=index">
                <section class="row smallTable-Row" [ngClass]="{'bg-Color':j%2==0}">
                    <section class="col-6 col-xs-6 col-md-6  small-table-padding">
                        <span class="small-table-heading">{{'customerOverview.changedAtrribute'|
                            cxTranslate}}</span>
                    </section>
                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span> {{i?.modifiedAttribute}}</span>
                    </section>

                    <section class="col-6 col-xs-6 col-md-6 small-table-padding ">
                        <span class="small-table-heading">{{'customerOverview.userChanged'|
                            cxTranslate}}</span>
                    </section>
                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span> {{i?.user}}</span>
                    </section>

                    <section class="col-6 col-xs-6 col-md-6 small-table-padding ">
                        <span class="small-table-heading">{{'customerOverview.ChangedTime'|
                            cxTranslate}}</span>
                    </section>
                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span> {{i?.modifiedTime}}</span>
                    </section>

                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span class="small-table-heading">{{'customerOverview.oldValue'| cxTranslate}}</span>
                    </section>
                    <section class="col-6 col-xs-6 col-md-6  small-table-padding">
                        <span>{{i?.oldValue}}</span>
                    </section>

                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span class="small-table-heading">{{'customerOverview.newValue'| cxTranslate}}</span>
                    </section>
                    <section class="col-6 col-xs-6 col-md-6 small-table-padding">
                        <span>{{i?.newValue}}</span>
                    </section>


                </section>
            </section>
            <!-- ======================/TABLE==================================== -->
            <section class="row">
                <section class="col-md-2 col-lg-2 col-xs-3 col-sm-3">
                    <button class="btn btn-info updateBtn-Css btn-margin-back " (click)="backToView()">
                        {{'customerOverview.back'| cxTranslate}}</button>
                </section>
            </section>

            <!-- ======================================================================================== -->

        </section>
    </section>
</section>