import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prod-help-tab',
  templateUrl: './prod-help-tab.component.html',
})
export class ProdHelpTabComponent {
  @Input() selectedTab = '';
  @Output() childSelectedTab = new EventEmitter<string>();
  tabStatus: string = 'product';

  toggleTab(tab, event): void {
    // Remove all active class
    const parent = event.target.parentNode.children;
    parent.forEach((el) => {
      el.classList.remove('active');
    });

    // Add active class on clicked tab
    event.target.classList.add('active');

    // Set the value of tab status for parent component
    tab == 'product' ? (this.tabStatus = 'product') : (this.tabStatus = 'help');
    this.childSelectedTab.emit(tab);
  }
}
