import { Component, OnInit } from "@angular/core";
import { OccEndpointsService } from "@spartacus/core";
import { take } from "rxjs/operators";
import { BaseService } from "../shared/services/base.service";

@Component({
  selector: 'app-custom-inventory-report',
  template: '<div class="inventory-report-container pb-3 pl-3 mt-3"><span (click)="downloadPdf()" class="ml-md-4 c-button c-button--primary"><i class="cx-icon fa-solid fa-clipboard-check"></i>View potency report</span></div>',
  styles: [
      `.inventory-report-container { 
        border-bottom: 1px solid var(--lib-color-neutral-200);
      }
      @media screen and (min-width:1280px) {
        .inventory-report-container {border-bottom: none;}
      }`
    ]
})
export class CustomInventoryComponent implements OnInit {
  baseUrl: any;
  potencyReportData: any;
  constructor(
    private readonly occEndPoint: OccEndpointsService,
    private baseService: BaseService,
  ){}
  ngOnInit(): void {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }
  downloadPdf() : void {
    this.baseService.get('/inventory-report').pipe(take(1)).subscribe((res) => {
      if (res != undefined || res != null) {
        const file = new Blob([this.baseUrl+res?.downloadUrl], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(this.baseUrl+res?.downloadUrl);
      }
    })
  }
}