import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
import { Subscription } from 'rxjs';
import { MakeAPaymentService } from '../../custom-make-a-payment/make-a-payment.service';
import {
  NumberTen,
  TwoThousand,
} from '../../shared/utils/constants/global.constants';
import { OpenStatementsService } from '../open-statements.service';

@Component({
  selector: 'app-statement-display',
  templateUrl: './statement-display.component.html',
  styleUrls: ['./statement-display.component.scss'],
  host: {
    '[style.overflow]': '"hidden"',
    '[style.width]': '"100%"',
  },
})
export class StatementDisplayComponent implements OnInit, OnDestroy {
  @Input() toShow: boolean;
  @Input() statement: any;
  someInputField: any;
  allStatementData: any;
  accountNumber = null;
  loadSub: Subscription;
  userAccSubs: Subscription;
  allStatementsSub: Subscription;
  showPopUp: boolean;
  isPDF = false;

  constructor(
    private readonly stService: OpenStatementsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly makeAPayment: MakeAPaymentService,
    public activeModal?: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.showPopUp = false;
    this.allStatementData = [];
    this.cdr.detectChanges();
    this.stService.getAccountId();
    this.userAccSubs = this.stService.userAccountIdDetails.subscribe((res) => {
      if (this.accountNumber == null) {
        this.accountNumber = res.defaultB2BUnit.uid;
        this.getAllStatements();
      }
    });

    this.loadSub = this.stService.loadStatements.subscribe((res) => {
      if (!!res !== false) {
        this.getAllStatements();
      }
    });
  }

  checkNegativeValue(data, checkNegative = false) {
    return checkNegative ? Number(data) < 0 : data?.transType === 'Credit Memo';
  }

  generateSavePdf() {
    this.isPDF = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      const options = {
        margin: [NumberTen, NumberTen],
        filename: 'statement.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pagebreak: { avoid: ['.lower-section', '.table-row-section'] },
      };
      const element: Element = document.getElementById('productList-areax');
      html2pdf().from(element).set(options).save();
      this.stService.reloadComponent.next(true);
    }, TwoThousand);
  }

  generateInvoicePDF(invoiceNo) {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  onPrint() {
    window.print(); // only modal content should get print
  }

  getAllStatements() {
    this.allStatementsSub = this.stService
      .getAllStatements({
        accountId: this.accountNumber,
        endDate: this.statement.toDte,
        fields: 'DEFAULT',
      })
      .subscribe((res) => {
        if (res) {
          this.allStatementData = JSON.parse(JSON.stringify(res));
          this.showPopUp = true;

          this.cdr.detectChanges();
          if (this.toShow === false) {
            this.generateSavePdf();
          }
        }
      });
  }

  ngOnDestroy() {
    this.showPopUp = false;
    this.loadSub?.unsubscribe();
    this.userAccSubs?.unsubscribe();
    this.allStatementsSub?.unsubscribe();
  }
}
