import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { I18nModule } from "@spartacus/core";
import { ChangeLocationModalComponent } from "./change-location-modal.component";
import { IconModule } from "@spartacus/storefront";

@NgModule({
  imports: [I18nModule, CommonModule, IconModule],
  exports: [ChangeLocationModalComponent],
  declarations: [ChangeLocationModalComponent]
})
export class ChangeLocationModalModule {}