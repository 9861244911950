<div class="modal-content">
  <div class="checkout-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="close">
          <i
            class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
            (click)="dismissModal()"
          ></i>
        </div>
        <div class="modal-body">
          <h3 class="font-bold">
            {{ "externalpopover.modalheader" | cxTranslate }}
          </h3>
          <p class="mb-2 text-fixed-medium mt-4">
            {{ "externalpopover.modalContentBody" | cxTranslate }}
          </p>
          <div class="row u-border-top-grey pt-5 mt-4">
            <div class="col-md-6 d-flex justify-content-center">
              <button
                type="button"
                class="c-button w-100 c-button--secondary mb-4"
                (click)="dismissModal()"
              >
                {{ "externalpopover.modalBluebtn" | cxTranslate }}
              </button>
            </div>

            <div class="col-md-6 d-flex justify-content-center">
              <button
                type="submit"
                class="c-button w-100 c-button--primary mb-4"
                (click)="redirectto()"
              >
                {{ "externalpopover.modalWhiteBtn" | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
