import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';
import { DataLayerService } from '../../services/data-layer.service';
import { SharedService } from '../../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  b2bUnitID: any;
  updatedContact: any;
  orderDataForinlfuenza: any;
  userAccountData: any;
  public tabselected = new BehaviorSubject<any>(false);
  tabselected$ = this.tabselected.asObservable();
  public emails = new BehaviorSubject<any>(false);
  emails$ = this.emails.asObservable();
  updatedOrderDetails: any;
  public updatedStdOrderDetails = new BehaviorSubject<any>(false);
  updatedStdOrderDetails$ = this.updatedStdOrderDetails.asObservable();
  public userAccountDetails = new BehaviorSubject<any>(false);
  public userAccountDetails$ = this.userAccountDetails.asObservable();
  constructor(
    private readonly baseService: BaseService,
    private readonly sharedService: SharedService,
    private readonly dataLayerService: DataLayerService
  ) {
    this.updatedContact = new Subject();
    this.updatedOrderDetails = new Subject();
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      this.b2bUnitID = res?.defaultB2BUnit?.uid;
    });
  }

  updateContact(params, userId, cartId): void {
    this.baseService
      .post(
        config.USER_ACCOUNT +
          userId +
          '/saveOrderContact?cartId=' +
          cartId +
          '&fields=DEFAULT',
        params
      )
      .subscribe((res) => {
        if (res) {
          this.updatedContact.next(res);
        }
      });
  }
  getEmails(userId, b2bUnitID): void {
    this.emails.next(false);
    this.baseService
      .get(
        config.USER_ACCOUNT + userId + '/customerEmails?b2bUnitId=' + b2bUnitID
      )
      .subscribe((res) => {
        if (res) {
          this.emails.next(res);
        }
      });
  }
  updateOrder(userId, cartId, params): void {
    this.updatedStdOrderDetails.next(false);
    this.updatedOrderDetails.next(false);
    this.baseService
      .post(
        config.USER_ACCOUNT +
          userId +
          '/place-order?cartId=' +
          cartId +
          '&fields=DEFAULT',
        params
      )
      .subscribe((res) => {
        if (res) {
          this.orderDataForinlfuenza = res;
          this.sharedService.setSessionStorage(
            'orderDataForinlfuenza',
            this.orderDataForinlfuenza
          );
          if (res?.cartType === 'INFLUENZA') {
            this.updatedOrderDetails.next(res);
          } else {
            this.updatedStdOrderDetails.next(res);
            this.dataLayerService.purchaseDataLayer(res);
          }
        }
      });
  }
  tabSelection(params): void {
    this.tabselected.next(params);
  }
  public getUserAccountDataFromDb(userId): void {
    this.userAccountData = new Subject<any>();
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/`, { fields: 'FULL' })
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userAccountData.next({ res });
        }
      });
  }
  public getUserAccountDetailsDataFromDb(userId, accountId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/${accountId}`, {
        fields: 'FULL',
      })
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userAccountDetails.next({ res });
        }
      });
  }
}
