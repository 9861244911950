<div class="acc-overviewtabs c-financial-list">
  <div class="mx-0 mx-sm-4">
    <h3 class="c-heading c-heading-h2">
      {{ 'accountOverview.Financials.label' | cxTranslate }}
    </h3>
  </div>
  <div id="tab_1" class="tab_content current">
    <app-loader
      *ngIf="!isLoadingDone && selectedTab !== 'STATEMENTS'"
    ></app-loader>
    <div
      class="text-center no-invoices"
      *ngIf="
        !!paymentList &&
        paymentList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      "
    >
      {{ 'accountOverview.Financials.no_invoice_found' | cxTranslate }}
    </div>
    <section class="row">
      <section
        class="col-md-12 col-xs-12 col-sm-12 fin-Table-Pad"
        id="invoice-table"
      >
        <section
          class="payment-section"
          *ngIf="
            !!paymentList &&
            paymentList.length > 0 &&
            isLoadingDone &&
            selectedTab === 'INVOICES'
          "
        >
          <table
            aria-describedby="invoicesTable"
            class="c-table c-table--branded d-none d-lg-table"
            [ngClass]="{ 'not-pdf': isPDF === false }"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  id="blineDate"
                  (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
                >
                  {{
                    'accountOverview.Financials.invoices.date_label'
                      | cxTranslate
                  }}
                  &nbsp;
                  <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
                </th>
                <th
                  scope="col"
                  id="docNo"
                  (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
                >
                  {{
                    'accountOverview.Financials.invoices.invoice_no'
                      | cxTranslate
                  }}
                  &nbsp;
                  <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
                </th>
                <th
                  scope="col"
                  id="dueDate"
                  (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
                >
                  {{
                    'accountOverview.Financials.invoices.due_date' | cxTranslate
                  }}
                  &nbsp;
                  <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
                </th>
                <!-- <th scope="col" class="text-right" id="amount"
                  (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                  {{
                  "accountOverview.Financials.invoices.invoice_amount"
                  | cxTranslate
                  }}
                  &nbsp;
                  <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
                </th> -->
                <th
                  scope="col"
                  id="amountDue"
                  (click)="sortColumn($event, 'amount', paymentList, 'Number')"
                >
                  {{
                    'accountOverview.Financials.invoices.amount_due'
                      | cxTranslate
                  }}
                  &nbsp;
                  <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of paymentList; let i = index">
                <td class="pay">{{ data?.blineDate | euStringToUSDate }}</td>
                <td class="pay">
                  <a
                    *ngIf="
                      data?.docType === 'RV' ||
                      data?.docType === 'RD' ||
                      data?.docType === 'RF'
                    "
                    href="javascript: void(0);"
                    class="underline"
                    (click)="invoicePdf(data.refDocNo)"
                  >
                    {{ data.refDocNo }}
                  </a>
                  <a
                    *ngIf="
                      data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                    "
                    href="javascript: void(0);"
                    class="underline"
                    (click)="invoicePdf(data.allocNmbr)"
                  >
                    {{ data.allocNmbr }}
                  </a>
                  <span
                    *ngIf="
                      data?.docType === 'DR' ||
                      data?.docType === 'DG' ||
                      data?.docType === 'DY'
                    "
                  >
                    {{ data.refDocNo }}
                  </span>
                  <span
                    *ngIf="
                      data?.docType === 'DZ' &&
                      !data?.allocNmbr?.startsWith('7')
                    "
                  >
                    {{ data.allocNmbr }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data.refhdr }}
                  </span>
                </td>
                <td class="pay">
                  <span
                    [ngClass]="{
                      red: !!data?.dueDate && dateComparison(data?.dueDate)
                    }"
                    >{{ data?.dueDate | euStringToUSDate }}
                    <ng-template #popSaveContent>
                      <span class="popover-content">{{
                        'makeAPayment.past_due_date' | cxTranslate
                      }}</span>
                    </ng-template>
                    <span
                      [ngbPopover]="popSaveContent"
                      triggers="manual"
                      #p1="ngbPopover"
                      placement="top"
                      (click)="toggle(p1)"
                      container="body"
                      popoverClass="popover-links-invoice"
                      *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)"
                    >
                      <img
                        src="assets/icons/warning_red.png"
                        alt="warningIcon"
                        class="warningIconEvent"
                      />
                    </span>
                  </span>
                </td>
                <!-- <td class="pay inv-text-end amount-Wrap">
                  {{ data?.amount | currency }}
                </td> -->
                <td class="pay inv-text-end amount-Wrap">
                  {{ data?.amount | currency }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            aria-describedby="invoicesTable"
            id="applyInvoiceRWD"
            class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
            [ngClass]="{ 'not-pdf': isPDF === false }"
          >
            <tr *ngFor="let data of paymentList; let i = index">
              <td class="header">
                {{
                  'accountOverview.Financials.invoices.date_label' | cxTranslate
                }}
              </td>
              <td>
                {{ data?.blineDate | euStringToUSDate }}
              </td>
              <td class="header">
                {{
                  'accountOverview.Financials.invoices.invoice_no' | cxTranslate
                }}
              </td>
              <td>
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data.refDocNo)"
                >
                  {{ data.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data.allocNmbr)"
                >
                  {{ data.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                  "
                >
                  {{ data.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data.refhdr }}
                </span>
              </td>
              <td class="header">
                {{
                  'accountOverview.Financials.invoices.due_date' | cxTranslate
                }}
              </td>
              <td>
                <span
                  [ngClass]="{
                    red: !!data?.dueDate && dateComparison(data?.dueDate)
                  }"
                  >{{ data?.dueDate | euStringToUSDate }}
                  <ng-template #popSaveContent>
                    <span class="popover-content">{{
                      'makeAPayment.past_due_date' | cxTranslate
                    }}</span>
                  </ng-template>
                  <span
                    [ngbPopover]="popSaveContent"
                    triggers="manual"
                    #p1="ngbPopover"
                    placement="top"
                    (click)="toggle(p1)"
                    container="body"
                    popoverClass="popover-links-invoice"
                    *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)"
                  >
                    <img
                      src="assets/icons/warning_red.png"
                      alt="warningIcon"
                      class="warningIconEvent"
                    />
                  </span>
                </span>
              </td>
              <!-- <td class="header">
                {{
                  "accountOverview.Financials.invoices.invoice_amount"
                    | cxTranslate
                }}
              </td>
              <td>{{ data?.amount | currency }}</td> -->
              <td class="header">
                {{
                  'accountOverview.Financials.invoices.amount_due' | cxTranslate
                }}
              </td>
              <td>{{ data?.amount | currency }}</td>
            </tr>
          </table>
        </section>
      </section>
    </section>
    <section id="pdf-dummy" *ngIf="isPDF"></section>

    <section
      *ngIf="
        !!creditList &&
        creditList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      "
      id="credits-table"
      class="credits-section"
    >
      <table
        aria-describedby="creditsTable"
        id="applyCreditsTable"
        class="c-table c-table--branded d-none d-lg-table"
        [ngClass]="{ 'not-pdf': isPDF === false }"
      >
        <thead>
          <tr>
            <th
              scope="col"
              (click)="sortColumn($event, 'blineDate', creditList, 'Date')"
              id="creditDate"
            >
              {{ 'makeAPayment.credit_date' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
            </th>
            <th
              scope="col"
              id="creditNo"
              (click)="sortColumn($event, 'docNo', creditList, 'normal')"
            >
              {{ 'makeAPayment.credit#' | cxTranslate }}&nbsp;
              <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
            </th>
            <th
              scope="col"
              class="text-left"
              id="creditAmt"
              (click)="sortColumn($event, 'amount', creditList, 'Number')"
            >
              {{ 'makeAPayment.credit_amount' | cxTranslate }}
              <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of creditList; let i = index">
            <td>
              {{ data?.blineDate | euStringToUSDate }}
            </td>
            <td>
              <a
                *ngIf="
                  data?.docType === 'RV' ||
                  data?.docType === 'RD' ||
                  data?.docType === 'RF'
                "
                href="javascript: void(0);"
                class="underline"
                (click)="creditPdf(data.refDocNo)"
              >
                {{ data.refDocNo }}
              </a>
              <a
                *ngIf="
                  data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                "
                href="javascript: void(0);"
                class="underline"
                (click)="creditPdf(data.allocNmbr)"
              >
                {{ data.allocNmbr }}
              </a>
              <span
                *ngIf="
                  data?.docType === 'DR' ||
                  data?.docType === 'DG' ||
                  data?.docType === 'DY'
                "
              >
                {{ data.refDocNo }}
              </span>
              <span
                *ngIf="
                  data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                "
              >
                {{ data.allocNmbr }}
              </span>
              <span *ngIf="data?.docType === 'YR'">
                {{ data.refhdr }}
              </span>
            </td>
            <td class="text-left">{{ data?.amount | currency }}</td>
          </tr>
        </tbody>
      </table>

      <table
        aria-describedby="creditsTable"
        id="applyCreditsRWD"
        class="c-table c-table--branded c-table--branded--two-col d-table d-md-none"
        [ngClass]="{ 'not-pdf': isPDF === false }"
      >
        <tr *ngFor="let data of creditList; let i = index">
          <td class="header">
            {{ 'makeAPayment.credit_date' | cxTranslate }}
          </td>
          <td>{{ data?.blineDate | euStringToUSDate }}</td>
          <td class="header">
            {{ 'makeAPayment.credit#' | cxTranslate }}
          </td>
          <td>
            <a
              *ngIf="
                data?.docType === 'RV' ||
                data?.docType === 'RD' ||
                data?.docType === 'RF'
              "
              href="javascript: void(0);"
              class="underline"
              (click)="creditPdf(data.refDocNo)"
            >
              {{ data.refDocNo }}
            </a>
            <a
              *ngIf="data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')"
              href="javascript: void(0);"
              class="underline"
              (click)="creditPdf(data.allocNmbr)"
            >
              {{ data.allocNmbr }}
            </a>
            <span
              *ngIf="
                data?.docType === 'DR' ||
                data?.docType === 'DG' ||
                data?.docType === 'DY'
              "
            >
              {{ data.refDocNo }}
            </span>
            <span
              *ngIf="
                data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
              "
            >
              {{ data.allocNmbr }}
            </span>
            <span *ngIf="data?.docType === 'YR'">
              {{ data.refhdr }}
            </span>
          </td>
          <td class="header">
            {{ 'makeAPayment.credit_amount' | cxTranslate }}
          </td>
          <td class="text-left">
            {{ data?.amount | currency }}
          </td>
        </tr>
      </table>
    </section>
    <section *ngIf="selectedTab === 'STATEMENTS'">
      <app-open-statements
        [hideAside]="removeAside"
        [restrictToTen]="true"
      ></app-open-statements>
    </section>
    <div
      *ngIf="
        !!paymentList &&
        paymentList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      "
    >
      <button
        type="button"
        class="c-button c-button--link c-button--link-primary-lg"
        data-di-id="di-id-41a05d18-6b04a424"
        [routerLink]="['/my-account/invoices']"
      >
        {{ 'accountOverview.Financials.button_txt' | cxTranslate }}
      </button>
    </div>
    <div
      *ngIf="
        !!creditList &&
        creditList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      "
    >
      <button
        type="button"
        class="c-button c-button--link c-button--link-primary-lg"
        data-di-id="di-id-41a05d18-6b04a424"
        [routerLink]="['/my-account/invoices']"
      >
        {{ 'accountOverview.Financials.credits.credit_btn_txt' | cxTranslate }}
      </button>
    </div>
    <div *ngIf="selectedTab === 'STATEMENTS' && !!isLoadingDone">
      <button
        type="button"
        class="c-button c-button--link c-button--link-primary-lg"
        data-di-id="di-id-41a05d18-6b04a424"
        [routerLink]="['/my-account/statements']"
      >
        {{
          'accountOverview.Financials.statements.statement_btn_txt'
            | cxTranslate
        }}
      </button>
    </div>
  </div>
</div>
<ng-template #invoicePastDueDateMsg>{{
  'accountOverview.Financials.pdf_label' | cxTranslate
}}</ng-template>
<div class="hide-section" *ngIf="isPDF">
  <div class="col-md-12" id="pdf-header">
    <div class="mx-0 mx-sm-4">
      <h3 class="c-heading c-heading-h2">
        {{ 'accountOverview.Financials.label' | cxTranslate }}
      </h3>
    </div>
  </div>
  <div class="col-md-12" id="pdf-subheader">
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'INVOICES'">
      {{ 'accountOverview.Financials.invoice_label' | cxTranslate }}
    </h3>
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'CREDITS'">
      {{ 'accountOverview.Financials.credits_label' | cxTranslate }}
    </h3>
  </div>
  <div class="line-top-sanofi-pdf" id="line-sanofi"></div>
</div>
