<section class="row mb-4">
  <section class="col-md-12">
    <h2 class="font-bold">{{ "myAcountPandPre.myAcount" | cxTranslate }}</h2>
  </section>
</section>
<section class="row mb-3">
  <section class="col-md-5">
    <h6 class="font-bold">
      {{ "myAcountPandPre.accountName" | cxTranslate }}
    </h6>
  </section>
  <section class="col-md-7">
    {{ defaultB2BUnitObject?.name }}
  </section>
</section>
<section class="row mb-4 pb-4 u-border-bottom-grey">
  <section class="col-md-5">
    <h6 class="font-bold">
      {{ "myAcountPandPre.phone" | cxTranslate }}
    </h6>
  </section>
  <section class="col-md-7">
    {{ usergetData?.phone }}
  </section>
</section>
<section class="row mb-4 pb-4 u-border-bottom-grey">
  <div class="d-none d-lg-block col-lg-2">
    <img src="../../../assets/icons/invoice.svg" class="price-Mater-Icon" />
  </div>
  <div class="col-12 col-md-12 col-lg-10">
    <span class="d-inline-block d-lg-none mr-2">
      <img src="../../../assets/icons/invoice.svg" class="price-Mater-Icon"
    /></span>
    <h3 class="d-inline-block d-lg-block font-bold">
      {{ "myAcountPandPre.billing" | cxTranslate }}
    </h3>
    <p class="text-fixed-medium m-0">
      {{ usergetData?.unitID }}<br />
      {{ usergetData?.companyName }}<br />
      {{ usergetData?.line1 }} {{ usergetData?.line2 }}<br />
      {{ usergetData?.town + "," }}
      {{ usergetData?.region?.isocodeShort + "," }}
      {{ usergetData?.postalCode }}
    </p>
  </div>
</section>
<section class="row mb-4 pb-4 u-border-bottom-grey">
  <div class="d-none d-lg-block col-lg-2">
    <img src="/assets/icons/price-master.svg" class="price-Mater-Icon" />
  </div>
  <div class="col-12 col-md-12 col-lg-10">
    <span class="d-inline-block d-lg-none mr-2"
      ><img src="/assets/icons/price-master.svg" class="price-Mater-Icon"
    /></span>
    <h3 class="d-inline-block d-lg-block font-bold">
      {{ "myAcountPandPre.payer" | cxTranslate }}
    </h3>
    <p class="text-fixed-medium m-0">
      {{ payerObject?.unitID }}<br />
      {{ payerObject?.companyName }}<br />
      {{ payerObject?.line1 }} {{ payerObject?.line2 }}<br />
      {{ payerObject?.town + "," }}
      {{ payerObject?.region?.isocodeShort + "," }}
      {{ payerObject?.postalCode }}
    </p>
  </div>
</section>
<section class="row">
  <section class="col-md-12">
    <app-profile-ad-banner></app-profile-ad-banner>
  </section>
</section>
