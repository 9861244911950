import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService } from '@spartacus/core';
@Component({
  selector: 'app-product-details-influenza',
  templateUrl: './product-details-influenza.component.html',
  styleUrls: ['./product-details-influenza.component.scss']
})
export class ProductDetailsInfluenzaComponent implements OnInit {
  @Input() public productDetails;
  @Input() public standardCartCode;
  baseUrl: any;
  monograph: any;
  monographLink: any;
  fallbackImage = '../../assets/i18n-assets/sanofishoppe_missing_product.png';
  constructor(private readonly modalService: NgbModal,
    private readonly occEndPoint: OccEndpointsService) {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }

  ngOnInit(): void {
    this.monograph = this.productDetails?.productDocuments;
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = link.url;
      }
    }

  }
  close(): void {
    this.modalService.dismissAll();
  }
}
