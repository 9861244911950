import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigModule } from "@spartacus/core";
import { CustomInventoryComponent } from "./custom-inventory-report.component";

@NgModule({
  declarations: [CustomInventoryComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SanofiInventoryReportCMSComponent: {
          component: CustomInventoryComponent
        }
      }
    })
  ]
})

export class CustomInventoryReportModule { }