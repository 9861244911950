import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  Sixty,
  Thousand,
  TwentyFour,
} from '../../shared/utils/constants/global.constants';
import { OpenStatementsService } from '../open-statements.service';
import { StatementDisplayComponent } from '../statement-display/statement-display.component';

const statementCssClass = 'col-md-10 col-sm-9';
const accountOverviewCssClass = 'col-md-12 col-sm-12';
const dateFormat = 'yyyy-MM-dd';

@Component({
  selector: 'app-open-statements',
  templateUrl: './open-statements.component.html',
  styleUrls: ['./open-statements.component.scss'],
})
export class OpenStatementsComponent implements OnInit, OnDestroy {
  @Input() hideAside: boolean;
  showSection = false;
  recordLength = 10;
  statementWidth: string = statementCssClass;
  statementsData: any;
  @Input() restrictToTen? = false;
  showPortal = false;
  details: any;
  showModal = false;
  accountId = null;
  userAcccountSub: Subscription;
  statementSub: Subscription;
  reloadSub: Subscription;

  constructor(
    private readonly stService: OpenStatementsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly modalService: NgbModal
  ) {}
  public windowReference = null;

  ngOnInit(): void {
    if (!!this.hideAside) {
      this.statementWidth = accountOverviewCssClass;
      this.showSection = this.hideAside;
    } else {
      this.statementWidth = statementCssClass;
      this.showSection = false;
    }
    this.stService.getAccountId();
    this.userAcccountSub = this.stService.userAccountIdDetails.subscribe(
      (res) => {
        this.accountId = res.defaultB2BUnit.uid;
      }
    );

    this.statementSub = this.stService
      .getStatements({
        accountId: this.accountId,
        fields: 'DEFAULT',
      })
      .subscribe((res) => {
        if (res) {
          if(this.restrictToTen){
            this.recordLength = 10;
          }
          else{
            this.recordLength = res.dateMap.length;
          }
          this.statementsData = JSON.parse(JSON.stringify(res));
          this.cdr.detectChanges();
        }
      });

    this.reloadSub = this.stService.reloadComponent.subscribe((res) => {
      this.showPortal = !res;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  openNewModalPopup(endDate, startDate) {
    this.details = { fromDte: startDate, toDte: endDate };
    const modalRef = this.modalService.open(StatementDisplayComponent, {
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.toShow = true;
    modalRef.componentInstance.statement = this.details;
  }

  generateSavePdf(endDate, startDate) {
    this.showPortal = true;
    this.details = { fromDte: startDate, toDte: endDate };
    this.stService.loadStatements.next(this.details);
  }

  viewCurrent() {
    const currentDate = new Date();
    const myDate = this.datePipe.transform(currentDate, dateFormat);
    const fromDateTimeStamp = new Date(
      new Date(this.statementsData?.dateMap[0].value).getTime() +
        TwentyFour * Sixty * Sixty * Thousand
    );
    const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
    this.openNewModalPopup(myDate, fromDate);
  }

  viewPDF() {
    if (!!this.statementsData) {
      const currentDate = new Date();
      const myDate = this.datePipe.transform(currentDate, dateFormat);
      const fromDateTimeStamp = new Date(
        new Date(this.statementsData?.dateMap[0].value).getTime() +
          TwentyFour * Sixty * Sixty * Thousand
      );
      const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
      this.generateSavePdf(myDate, fromDate);
    }
  }

  ngOnDestroy() {
    this.userAcccountSub?.unsubscribe();
    this.statementSub?.unsubscribe();
    this.reloadSub?.unsubscribe();
  }
}
