import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { I18nModule } from "@spartacus/core";
import { OutletPosition, provideOutlet } from "@spartacus/storefront";
import { FeedbackComponent } from "./feedback.component";

@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    I18nModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [],
  providers: [
    provideOutlet({
      id: 'Footer',
      position: OutletPosition.AFTER,
      component: FeedbackComponent,
    }),
  ],
})
export class FeedbackModule {}