import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserAccessManagementService } from '../user-access-management.service';
import { AuthService, CostCenterOccModule, TranslationService, UserIdService } from '@spartacus/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isJSDocThisTag } from 'typescript';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit,OnDestroy {
  pageLength :any;
  pageList:any
  pageSizeperPage:any;
  pageSize = 18;
  selectedFilter:any;
  b2BUnitFilter:any;
  emailFilter:any;
  cusNameFilter:any;
  userNameFilter:any;
  b2BUnitNameFilter:any;
  searchFilters:any;
  b2BUnitId=null;
  b2BUnitName=null;
  emailId=null;
  customerName=null;
  userName=null;
  b2BUnitListSub:Subscription;
  userSubscription:Subscription;
  b2bUnitUsers:any;
  totalRecords:any;
  @ViewChild('facetsRwd', { static: false }) private readonly facetsRwd;
  constructor(private readonly userManagementService:UserAccessManagementService,
    private readonly cdr: ChangeDetectorRef,private readonly router: Router,
    private readonly sharedService:SharedService,
    private readonly modalService: NgbModal,
    private readonly translation: TranslationService) { }

  ngOnInit(): void {
    this.translation.translate('ordersList.language').subscribe((res) => {
        if(res==='fr'){
          this.pageLength = '10 par page';
          this.pageSizeperPage='10 par page';
          this.pageList = [this.pageLength, '18 par page', '36 par page','72 par page'];
          this.selectedFilter='equals';
          this.b2BUnitNameFilter='contains';
          this.searchFilters=[{name:'Équivaut à',code:'equals'},{name:'Contient',code:'contains'},{name:'Commence avec',code:'starts'},{name:'Se termine par',code:'ends'}];
          this.b2BUnitFilter=this.selectedFilter;
          this.emailFilter=this.selectedFilter;
          this.cusNameFilter=this.selectedFilter;
          this.userNameFilter=this.selectedFilter;
        }else{
          this.pageLength = '10 per page';
          this.pageSizeperPage='10 per page';
          this.pageList = [this.pageLength, '18 per page', '36 per page','72 per page'];
          this.selectedFilter='equals';
          this.b2BUnitNameFilter='contains';
          this.searchFilters=[{name:'Equals',code:'equals'},{name:'Contains',code:'contains'},{name:'Starts with',code:'starts'},{name:'End with',code:'ends'}];
          this.b2BUnitFilter=this.selectedFilter;
          this.emailFilter=this.selectedFilter;
          this.cusNameFilter=this.selectedFilter;
          this.userNameFilter=this.selectedFilter;
        }
    });

    let params=this.sharedService.getSessionStorage('filterAttributes');
    this.b2BUnitName=params?.unitName;
    this.b2BUnitId=params?.unitId;
    this.emailId=params?.email;
    this.customerName=params?.customerName;
    this.userName=params?.userName;
    this.b2BUnitNameFilter=this.sharedService.getSessionStorage('b2BUnitNameFilter')?this.sharedService.getSessionStorage('b2BUnitNameFilter'):'contains';
    this.b2BUnitFilter=this.sharedService.getSessionStorage('b2BUnitFilter')?this.sharedService.getSessionStorage('b2BUnitFilter'):this.selectedFilter;
    this.emailFilter=this.sharedService.getSessionStorage('emailFilter')?this.sharedService.getSessionStorage('emailFilter'):this.selectedFilter;
    this.cusNameFilter=this.sharedService.getSessionStorage('cusNameFilter')?this.sharedService.getSessionStorage('cusNameFilter'):this.selectedFilter;
    this.userNameFilter=this.sharedService.getSessionStorage('userNameFilter')?this.sharedService.getSessionStorage('userNameFilter'):this.selectedFilter;
    this.pageSizeperPage=this.sharedService.getSessionStorage('pageSizeperPage')?this.sharedService.getSessionStorage('pageSizeperPage'):this.pageLength;
    if(params)
    this.userManagementList(params);
  }
  userManagementList(params?){
    this.sharedService.setSessionStorage('filterAttributes',params);
    this.b2BUnitListSub=this.userManagementService.getb2bUnitList(params).subscribe((res:any)=>{
      if(res){
        this.b2bUnitUsers=res?.users;
        this.totalRecords=res?.pagination;
        this.cdr.detectChanges();
      }
    });
  }
  clearFilters() {
    this.b2BUnitId=null
    this.b2BUnitName=null;
    this.emailId=null;
    this.customerName=null;
    this.userName=null;
    this.b2BUnitNameFilter='contains';
    this.b2BUnitFilter=this.selectedFilter;
    this.emailFilter=this.selectedFilter;
    this.cusNameFilter=this.selectedFilter;
    this.userNameFilter=this.selectedFilter;
    sessionStorage.removeItem('b2BUnitNameFilter');
    sessionStorage.removeItem('b2BUnitFilter');
    sessionStorage.removeItem('emailFilter');
    sessionStorage.removeItem('cusNameFilter');
    sessionStorage.removeItem('userNameFilter');
    this.sharedService.setSessionStorage('pageSizeperPage',this.pageSizeperPage);
    this.b2bUnitUsers=[];
    this.totalRecords=null;
    // const params={currentPage:0,pageSize:parseInt(this.pageSizeperPage.slice(0,2))};
    // this.userManagementList(params);
  }
  applyFilter(pageNo?:any) {
    this.sharedService.setSessionStorage('b2BUnitNameFilter',this.b2BUnitNameFilter);
    this.sharedService.setSessionStorage('b2BUnitFilter',this.b2BUnitFilter);
    this.sharedService.setSessionStorage('emailFilter',this.emailFilter);
    this.sharedService.setSessionStorage('cusNameFilter',this.cusNameFilter);
    this.sharedService.setSessionStorage('userNameFilter',this.userNameFilter);
    this.sharedService.setSessionStorage('pageSizeperPage',this.pageSizeperPage);
    let params;
    if(this.b2BUnitName!="" && this.b2BUnitName!=null){
      const unitParams={unitName:this.b2BUnitName,unitNameClause:this.b2BUnitNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...unitParams};
    }
    if(this.b2BUnitId!="" && this.b2BUnitId!=null){
      const unitParams={unitId:this.b2BUnitId,unitIdClause:this.b2BUnitFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...unitParams};
    }
    if(this.emailId!="" && this.emailId!=null){
      const emailidParams={email:this.emailId,emailClause:this.emailFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...emailidParams};
    }
    if(this.customerName!="" && this.customerName!=null){
      const cusNameParams={customerName:this.customerName,customerNameClause:this.cusNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...cusNameParams};
    }
    if(this.userName!="" && this.userName!=null){
      const usernameParams={userName:this.userName,userNameClause:this.userNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...usernameParams};
    }

    if(params){
    const pageParams={currentPage:pageNo,pageSize:parseInt(this.pageSizeperPage.slice(0,2))};
    params={...params,...pageParams};
    this.userManagementList(params);
    }else{
      this.b2bUnitUsers=[];
      this.totalRecords=null;
      this.pageSizeperPage=this.pageList[0];
    }
    
    this.hide();
  }
  changePageSize() {
    if(this.b2bUnitUsers?.length>0){
    this.applyFilter(0);
    }
    // const params={currentPage:0,pageSize:parseInt(this.pageSizeperPage.slice(0,2))};
    // this.userManagementList(params);
  }
  paginationNext(){
    this.applyFilter(this.totalRecords?.currentPage+1);
    // const params={currentPage:this.totalRecords?.currentPage+1,pageSize:parseInt(this.pageSizeperPage.slice(0,2))};
    // this.userManagementList(params);
  }
  paginationPrev(){
    this.applyFilter(this.totalRecords?.currentPage-1);
    // const params={currentPage:this.totalRecords?.currentPage-1,pageSize:parseInt(this.pageSizeperPage.slice(0,2))};
    // this.userManagementList(params);
  }
  openCustomerDetails(userName){
    
    this.userManagementService.getCustomerDetails(userName,null);
   this.sharedService.setSessionStorage('customerId',userName)
    this.router.navigate(['/', 'customer-details']);
  }
  refine(): void {
    this.modalService.open(this.facetsRwd);
  }
  hide(): void {
    this.modalService.dismissAll(this.facetsRwd);
  }
  generateXls() {
    const usersReport = [];
    let params={pageSize:this.totalRecords?.totalResults?this.totalRecords?.totalResults:0};
    if(this.b2BUnitName!="" && this.b2BUnitName!=null){
      const unitParams={unitName:this.b2BUnitName,unitNameClause:this.b2BUnitNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...unitParams};
    }
    if(this.b2BUnitId!="" && this.b2BUnitId!=null){
      const unitParams={unitId:this.b2BUnitId,unitIdClause:this.b2BUnitFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...unitParams};
    }
    if(this.emailId!="" && this.emailId!=null){
      const emailidParams={email:this.emailId,emailClause:this.emailFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...emailidParams};
    }
    if(this.customerName!="" && this.customerName!=null){
      const cusNameParams={customerName:this.customerName,customerNameClause:this.cusNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...cusNameParams};
    }
    if(this.userName!="" && this.userName!=null){
      const usernameParams={userName:this.userName,userNameClause:this.userNameFilter?.split(' ')[0]?.toLowerCase()};
      params={...params,...usernameParams};
    }
    let b2bUnitUsers1;
    this.b2BUnitListSub=this.userManagementService.getb2bUnitList(params).subscribe((res:any)=>{
      if(res){
        b2bUnitUsers1=res?.users;
        if(b2bUnitUsers1?.length===0){
          usersReport.push({
            'B2B Unit / Account #': '',
            'B2B Unit / Account Name': '',
            'B2B Customer Name': '',
            'Email': '',
            'User Name': '',
            'User Id Disabled Y/N': ''
          });
        }
        b2bUnitUsers1.forEach((element) => {
          usersReport.push({
            'B2B Unit / Account #': element?.unitId,
            'B2B Unit / Account Name': element?.unitName,
            'B2B Customer Name': element?.customerName,
            'Email': element?.email,
            'User Name': element?.userName,
            'User Id Disabled Y/N': element?.userDisabled ? 'Y' : 'N'
          });
        });
        this.userManagementService.downloadXls(usersReport, 'UserAccessReport');
      }
    });
  }
  ngOnDestroy(){
    this.b2BUnitListSub?.unsubscribe();
  }
}
