import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './user-management/user-management.component';
import { CustomerOverviewComponent } from './customer-overview/customer-overview.component';
import {CmsConfig,ConfigModule,I18nModule,provideConfig} from '@spartacus/core';
import { translationChunksConfig } from '@spartacus/assets';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [UserManagementComponent, CustomerOverviewComponent],
  imports: [
    CommonModule,
    I18nModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UserManagementListComponent: {
          component: UserManagementComponent,
        },
        CustomerDetailsComponent: {
          component: CustomerOverviewComponent,
        }
      },
    } as CmsConfig),
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class UserAccessManagementModule { }
