import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';
import { CustomRegistrationService } from 'src/app/custom-user-registration/custom-registration.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';
@Component({
  selector: 'select-vaccines',
  templateUrl: './select-vaccines.component.html',
  styleUrls: ['./select-vaccines.component.scss'],
})
export class SelectVaccinesComponent implements OnInit,OnDestroy {
  header: any = 'selectVaccine';
  productsInCartArray: any = [];
  dosesOfVaccines: any = [];
  duplicateProdCheckArray: any = [];
  @Input() selectedHeader: string;
  unitForm = new FormGroup({
    units: new FormControl(1),
  });
  doses: any;
  rowId: any;
  baseUrl: any;
  screenwidth: any;
  userData: any = [];
  fluProductsList = [];
  entriesFromCart:any=[];
  names: any = [];
  units: any;
  compName = 'SanofiFluDashboardBannerComponent';
  buttonCounter: any = 0;
  fallbackImage = '../../assets/i18n-assets/sanofishoppe_missing_product.png';
  activeCartId ='';
  closeResult = '';
  fluProductsFromCartList:any=[];
  influenzaChildCartId:any='';
  stopApiCounter:any=0;
  cartProductsInSelectVaccineSubscriber:any;
  activeSeasonId:any=this.sharedService.getSessionStorage('activeSeasons');
  fluProductsSubscriber:any;
  activeSeasonSubscriber: any;
  productsForCartInsertArray: any=[];
  updateAndInsert =false;
  updateFluCartSubscriber: any;
  disableButton = false;
  disableButtonForProductsZero = false;
  apiCallUnderProcess = false;
  createCartSubscriber: any;
  fluFromCartSubscriber: any;
  addProductsToCartSubscriber: any;
  cartIdActiveSubscriber: any;
  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService,
    private readonly modalService: NgbModal,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart: CustomAddToCartService
  ) {
    this.screenwidth = window.screen.width;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  async ngOnInit() {

    this.proceedToShipping();
    this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
        this.influenzaService.getActiveSeasonsFromDb(this.userData.userName);
        this.influenzaService.getFluOrderContact(this.userData.userName);
        this.influenzaService.getCartIdofUser(this.userData.userName);
      }
    });

    this.cartIdActiveSubscriber=this.influenzaService.cartIdActiveOfUser.subscribe((cartIdData) => {
      if (cartIdData?.carts?.length > 0) {
        const cartIdResponse = cartIdData?.carts;
        let activeCartIdArray=[]
         activeCartIdArray= cartIdResponse?.filter((item)=>item?.saveTime===undefined);
        this.activeCartId = activeCartIdArray[activeCartIdArray?.length - 1]?.code;

      }
      this.cartIdActiveSubscriber?.unsubscribe();
  });

 this.activeSeasonSubscriber= this.influenzaService.activeSeaons.subscribe(async (data)=>{
    if(data){
      this.activeSeasonId=data?.categoryData?.[0]?.code;
      const booleanFromFluGetApi= await this.subscribeOfFluProductsGetApi();
      if(booleanFromFluGetApi){
         this.updateProductsOfCart();
      }
    }
    this.activeSeasonSubscriber?.unsubscribe();
  });
  }

  ngOnDestroy(){
    this.cartProductsInSelectVaccineSubscriber?.unsubscribe();
    this.createCartSubscriber?.unsubscribe();
    this.fluFromCartSubscriber?.unsubscribe();
    this.addProductsToCartSubscriber?.unsubscribe();
    this.cartIdActiveSubscriber?.unsubscribe();
    this.activeSeasonSubscriber?.unsubscribe();
    this.fluProductsSubscriber?.unsubscribe();
  }



  subscribeOfFluProductsGetApi() {
    return new Promise((resolve)=>{
      this.influenzaService.getFluProducts( this.userData.userName, this.activeSeasonId  );
       this.fluProductsSubscriber= this.influenzaService.fluProducts.subscribe((response) => {
          this.fluProductsList = response.products;
          this.fluProductsList?.forEach((ele,indexOfFlu) => {
            let thumbNailImg = '';
            if (ele?.images) {
              thumbNailImg = ele.images[2]?.url;
            }
            ele['thumbNailImg'] = thumbNailImg;

            if(indexOfFlu+1==this.fluProductsList.length){
              this.ref.detectChanges();

              resolve(true);

            }
          });
          this.fluProductsSubscriber?.unsubscribe();


        });
    });

  }
  OnFluPrint(){
    window.print();
  }
  updateProductsOfCart() {

      this.influenzaService.getFluProductsFromCart(  this.userData.userName, this.activeCartId,'select');
    this.cartProductsInSelectVaccineSubscriber=  this.influenzaService.fluProductsFromCart.subscribe((FluProducts) => {
        if (FluProducts) {
          let fluArray = [];
          let fluEntries = [];
          const fluCartProducts = [];
          fluArray = FluProducts?.childCarts;
          if (fluArray) {
            fluArray.forEach((element) => {
              if (element.cartType === 'INFLUENZA') {
                this.influenzaChildCartId = element.code;
                fluEntries = element.entries;
              }
            });
          }
          fluEntries?.forEach((ele,index) => {
          if(ele?.product?.available && !ele?.product?.discontinued){
              const product = ele.product;
              const totalQty = ele.quantity;
              const entNo = ele.entryNumber;
              const dosPeruni = product.quantityPerUnit;
              let thumbImg = '';
              if (product?.images) {
                thumbImg = product?.images[2]?.url;
              }
              const cartProductFluName = product.name;
              const cartProdCode = product.code;
              const cartOnsnBoolean = product.onsn;
              const cartDescriptionFluProduct = product.description;
              const cartPriceOfProduct = ele.basePrice?.value;
              const cartTotalDosesOfFluProduct = parseInt(totalQty) * parseInt(dosPeruni);
              const obj = {
                entryNum: entNo,
                totalQuantity: totalQty,
                dosesPerUnit: dosPeruni,
                thumbNailImg: thumbImg,
                code: cartProdCode,
                onsn: cartOnsnBoolean,
                desc: cartDescriptionFluProduct,
                prodName: cartProductFluName,
                price: cartPriceOfProduct,
                totalDoses: cartTotalDosesOfFluProduct,
                availableFlag:product.available,
                discontinuedFlag:product.discontinued
              };

              fluCartProducts.push(obj);

            }
            if(index+1===fluEntries.length){
             this.fluProductsFromCartList = fluCartProducts;
             this.updateQuantityandDoses();
            }
          });
        }
        this.cartProductsInSelectVaccineSubscriber?.unsubscribe();
        this.ref.detectChanges();
      });



  }
  updateQuantityandDoses() {

    let stopCounter = 0;
    this.fluProductsList?.forEach((ele, i) => {
      let totalDoses;
      let totalQuantity;
      ele['totalDoses'] = totalDoses;
      ele['totalQuantity'] = totalQuantity;
      if (stopCounter < this.fluProductsFromCartList.length) {
        this.fluProductsFromCartList?.forEach((element, index) => {
          if (element.code === ele.code) {
            stopCounter += 1;
            ele.totalDoses = element.totalDoses;
            ele.totalQuantity = element.totalQuantity;
            const obj = {
              'productCode': element?.code,
              'quantity': element?.totalQuantity,
              'entryNumber':element?.entryNum
            };
            this.ref.detectChanges();
            ProductUtils.alterQuantityforInfluenza('', ele.code, element?.dosesPerUnit,'category','');
            if (this.duplicateProdCheckArray.indexOf(obj.productCode) === -1) {
              this.duplicateProdCheckArray.push(obj.productCode);
              this.productsInCartArray.push(obj);
            }
          }

        });
      }
      this.ref.detectChanges();
    });
  }
  backToDashBoard(content,boolean) {
    if(!boolean && this.duplicateProdCheckArray.length>0){
      this.influenzaService.getFluProductsFromCart(this.userData.userName,this.activeCartId,'select2');
      this.getInfluenzaProducts();
      if(this.entriesFromCart.length>0 ){
      this.router.goByUrl('/influenza/dashboard');
      } else{
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
        result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    } else{
      this.router.goByUrl('influenza/dashboard');
    }

  }

  moveProductsToCart() {
    if (!this.activeCartId) {
      this.influenzaService.CreateCartIdofUser(this.userData.userName);
     this.createCartSubscriber= this.influenzaService.createCartOfUser.subscribe((responseCartId) => {
        if (responseCartId) {
          this.activeCartId= responseCartId?.code;
          this.proceedToCart();
        }
        this.createCartSubscriber?.unsubscribe();
      });
    } else {
      this.proceedToCart();
    }



  }
  getInfluenzaProducts() {
   this.fluFromCartSubscriber= this.influenzaService.fluProductsFromCart.subscribe((FluProducts)=>{
      if (FluProducts) {
        let array = [];
        array = FluProducts?.childCarts;

        if (array !== undefined) {
          array.forEach((element) => {
            if (element.cartType === 'INFLUENZA') {
              this.entriesFromCart = element.entries;
            }
          });
        }
    }
    this.fluFromCartSubscriber?.unsubscribe();
  });
  }
  proceedToCart() {
    this.insertProductsToCartDb(this.productsInCartArray);
  }
  insertProductsToCartDb(array){
    this.apiCallUnderProcess=true;
    if (this.userData && this.activeCartId) {
      const params = {
        'addToCartForm': array
      };
      this.influenzaService.addFluProductsToCart(this.userData.userName, this.activeCartId, params);
      this.addProductsToCartSubscriber=this.influenzaService.addedProductsToCart.subscribe((response) => {
        this.addToCart.getAllCarts(this.userData?.userName);
        this.apiCallUnderProcess=false;
        this.addProductsToCartSubscriber?.unsubscribe();
        this.router.goByUrl('/influenza/cart');
      });
  }
  }

  proceedToShipping() {
    const selectvaccine = { header: 'selectvaccine' };
    this.accountRegService.sectionChange.next(selectvaccine);
  }

  performQuantityComputation(operator, codeOfProduct, quantityOfUnits) {
    let isEntryNumber='';
     this.fluProductsFromCartList?.find((element,index)=>{
         element?.code===codeOfProduct ?  isEntryNumber= element?.entryNum:'';
    });
    ProductUtils.alterQuantityforInfluenza(operator, codeOfProduct, quantityOfUnits,'category','');
    const doses = document.getElementById(`noOfDoses${codeOfProduct}`) as HTMLInputElement;
    const quantity = document.getElementById(`prodQuantity${codeOfProduct}`) as HTMLInputElement;
    this.doses = doses.innerHTML;
    const obj = {
      'productCode': codeOfProduct,
      'quantity': parseInt(quantity?.value),
      'entryNumber':isEntryNumber
    };
    if (this.duplicateProdCheckArray.indexOf(obj.productCode) === -1) {
      this.duplicateProdCheckArray.push(codeOfProduct);

        this.productsInCartArray.push(obj);
        this.checkEmptyUnits();
    } else {
      let indexOfProdCode='';
      this.productsInCartArray.filter((data, index) => {
        if (data.productCode === codeOfProduct) {
          indexOfProdCode = index;
         }
        });
  //  this IF method will update products which are already in cart or usual product quantity update in sv
        if(indexOfProdCode!==''){
            this.prodUpdForExistingProducts(quantity,indexOfProdCode,codeOfProduct);
        }

    }
    this.ref.detectChanges();

  }
  //products already in cart
  prodUpdForExistingProducts(quantity,indexOfProdCode,codeOfProduct){
    if (parseInt(quantity?.value) === 0) {
     if(this.fluProductsFromCartList?.length===0){
      this.productsInCartArray?.splice(indexOfProdCode, 1);
      this.duplicateProdCheckArray?.splice(this.duplicateProdCheckArray.indexOf(codeOfProduct), 1);
     } else{
      this.productsInCartArray[indexOfProdCode].quantity = parseInt(quantity?.value);
     }

    } else {
      this.productsInCartArray[indexOfProdCode].quantity = parseInt(quantity?.value);
    }
    this.checkEmptyUnits();
  }


  checkEmptyUnits(){
    let counter=0;
    this.productsInCartArray?.forEach((data,index) => {
          if(parseInt(data.quantity)>0){
              counter+=1;
          }
    });

counter===0 ? this.disableButton=true : this.disableButton=false;

  }
  productDetails(productDetails) {
    const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent, { windowClass: 'product-details-inf-modal' });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = productDetails.code;
  }
}
