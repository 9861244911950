import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { filter } from 'rxjs/operators';
import { DefaultB2BUnit } from 'src/app/shared/models/user.model';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
})
export class MyAccountComponent implements OnInit, OnDestroy {
  usergetData: any;
  payerObject: any;
  defaultB2BUnitObject: any;
  userAccountgetData: any;
  accountId: any;
  userAccountDataSubscriber: any;

  constructor(
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef
  ) {}

  getUserAccountData(userId): void {
    this.userAccountDataSubscriber = this.pfService.userAccountData.pipe(filter(Boolean)).subscribe(
      (userData: DefaultB2BUnit) => {
        if (userData) {
          this.usergetData = userData?.billingAddress;

          this.payerObject = userData?.payerAddress;
          this.defaultB2BUnitObject = userData;

          this.ref.detectChanges();
        }
        this.userAccountDataSubscriber?.unsubscribe();
      }
    );
  }

  ngOnDestroy() {
    this.userAccountDataSubscriber?.unsubscribe();
  }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
      }
    });
  }
}
