<section class="row makePaymentView c-list-orders" id="productList-area">
  <aside class="d-none d-md-block col-md-4 col-lg-3 applied-filter pr-0">
    <div class="c-list-orders--aside">
      <fieldset id="filter-by">
        <legend
          class="c-text c-heading-h3 font-weight-bold u-border-bottom-grey pb-3"
        >
          {{ 'ordersList.searchBy' | cxTranslate }}
        </legend>
        <h4 class="c-text c-heading-h3 font-weight-bold mt-4 mb-0">
          {{ 'ordersList.timeFrame' | cxTranslate }}
        </h4>
        <ng-select
          name="timeFrame"
          [items]="timeFrameList"
          [searchable]="false"
          [clearable]="false"
          bindLabel="name"
          bindValue="code"
          class="c-select"
          (ngModelChange)="clearDateRange()"
          [(ngModel)]="timeFrame"
        ></ng-select>
        <h4 class="c-text c-heading-h3 font-weight-bold mb-0">
          {{ 'ordersList.dateRange' | cxTranslate }}
        </h4>
        <div class="fields-container c-text-input">
          <label>{{ 'ordersList.from' | cxTranslate }}</label>
          <div class="c-text-input--with-icon-right">
            <input
              type="text"
              placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}"
              [(ngModel)]="fromDate"
              (ngModelChange)="validateDate()"
              bsDatepicker
              [maxDate]="maxSelectionDate"
              [daysDisabled]="[0, 6]"
              [bsConfig]="{
                containerClass: 'order-list-date',
                dateInputFormat: dateFormat.toUpperCase()
              }"
            />
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </div>
        <div class="fields-container c-text-input">
          <label>{{ 'ordersList.to' | cxTranslate }}</label>
          <div class="c-text-input--with-icon-right">
            <input
              type="text"
              placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}"
              [(ngModel)]="toDate"
              (ngModelChange)="validateDate()"
              bsDatepicker
              [maxDate]="maxSelectionDate"
              [daysDisabled]="[0, 6]"
              [bsConfig]="{
                containerClass: 'order-list-date',
                dateInputFormat: dateFormat.toUpperCase()
              }"
            />
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
          <span class="dateError" *ngIf="dateError">{{
            'ordersList.invalidDateErrMsg' | cxTranslate
          }}</span>
          <span class="dateError" *ngIf="singleDateError">{{
            'ordersList.selectBothDate' | cxTranslate
          }}</span>
        </div>
        <div class="fields-container c-text-input">
          <label>{{ 'ordersList.invoice' | cxTranslate }}</label>
          <div class="c-text-input--with-icon-left">
            <input
              placeholder="Search"
              type="text"
              autocomplete="off"
              aria-describedby="initialDescription"
              aria-controls="results"
              aria-autocomplete="both"
              [(ngModel)]="invoice"
            />
            <button role="presentation">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="fields-container c-text-input">
          <label>{{ 'ordersList.po' | cxTranslate }}</label>
          <div class="c-text-input--with-icon-left">
            <input
              placeholder="Search"
              type="text"
              autocomplete="off"
              aria-describedby="initialDescription"
              aria-controls="results"
              aria-autocomplete="both"
              [(ngModel)]="poNumber"
            />
            <button role="presentation">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="fields-container c-text-input">
          <label>{{ 'ordersList.order' | cxTranslate }}</label>
          <div class="c-text-input--with-icon-left">
            <button role="presentation">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
            <input
              placeholder="Search"
              type="text"
              autocomplete="off"
              aria-describedby="initialDescription"
              aria-controls="results"
              aria-autocomplete="both"
              [(ngModel)]="order"
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="c-button c-button--primary mb-4 w-100"
            data-di-id="di-id-362f2c77-5f23e741"
            (click)="applyFilter()"
          >
            {{ 'ordersList.applyFilters' | cxTranslate }}
          </button>

          <button
            type="button"
            id="clearOrderFiltersBtn"
            class="c-button c-button--secondary w-100"
            data-di-id="#clearOrderFiltersBtn"
            (click)="clearFilters()"
          >
            {{ 'ordersList.clearFilters' | cxTranslate }}
          </button>
        </div>
      </fieldset>
      <div class="line-intersection"></div>
      <section>
        <div>
          <app-need-to-go-back></app-need-to-go-back>
        </div>
        <div class="line-intersection"></div>
      </section>
      <section>
        <div class="banner-img">
          <app-flu-dashboard-banner
            bannerComponent="OrderHistoryBannerComponent"
          ></app-flu-dashboard-banner>
        </div>
      </section>
    </div>
  </aside>

  <section class="col-xs-12 col-sm-12 col-md-8 col-lg-9 table-s-section">
    <div class="d-none d-md-block px-3 px-lg-4 py-3 color-normal-content">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <span class="d-none d-sm-block text-fixed-medium">
            {{ 'ordersList.showing' | cxTranslate }} {{ end }}
            {{ 'ordersList.of' | cxTranslate }}
            {{ totalOrders }}
            {{ 'ordersList.orders' | cxTranslate }}
          </span>
        </div>
        <div>
          <div class="time-frame text-fixed-medium">
            <span class="top-page-id mr-2 pt-2" id="accPageInfo">
              {{ 'ordersList.page' | cxTranslate }} {{ curPage }}
              {{ 'ordersList.of' | cxTranslate }}
              {{ numberOfPages() }}
            </span>
            <div class="c-pagination">
              <button
                [disabled]="curPage == 1"
                (click)="getCurrentPage('prev')"
                (click)="getOrderList()"
                class="c-pagination-btns mr-1"
              >
                <!-- <span class="chevronIconWhite prev"></span> -->
                <i class="fa fa-chevron-left"></i>
              </button>

              <button
                class="c-pagination-btns"
                [disabled]="
                  curPage >= totalOrders / pageSize ||
                  (totalOrders === 0 && pageSize === 0)
                "
                (click)="getCurrentPage('next')"
                (click)="getOrderList()"
              >
                <!-- <span class="chevronIconWhite next"></span> -->
                <i class="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="hidden-xs m-3" />
      <div class="d-flex justify-content-between mt-2">
        <div class="text-fixed-medium d-flex gap-05 align-items-center">
          {{ 'ordersList.filtersApplied' | cxTranslate }}
          <span *ngIf="!facetsList.length">
            {{ 'ordersList.none' | cxTranslate }}
          </span>
          <span *ngFor="let filter of facetsList">
            <button
              type="button"
              *ngIf="facetsList.length"
              class="c-button c-button--primary c-button--with-icon c-button--chips"
              (click)="removeFacet(filter)"
            >
              <i class="fa fa-times-circle" aria-hidden="true"></i>
              {{ filter.value }}
            </button>
          </span>
          <button
            type="button"
            *ngIf="facetsList.length"
            class="c-button c-button--link-primary ml-1"
            (click)="clearFilters()"
          >
            {{ 'ordersList.clearAllFilters' | cxTranslate }}
          </button>
        </div>
        <div>
          <ng-select
            name="pageSize"
            [items]="pageList"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="c-select page-size-select"
            [(ngModel)]="pageSizeperPage"
            (change)="changePageSize()"
          ></ng-select>
        </div>
      </div>
    </div>

    <section class="payment-section filter-section">
      <section class="d-block d-md-none">
        <ng-template #facetsRwd class="select-refine">
          <section class="modal-class">
            <section class="modal-heading">
              <span
                class="close-icon c-icon-primary c-icon-primary-md position-absolute"
                aria-hidden="true"
                (click)="hide()"
                >&times;</span
              >

              <h2 class="p-4">
                {{ 'plp.selectRefinement' | cxTranslate }}
              </h2>
            </section>
            <section>
              <fieldset id="filter-by">
                <h3 class="px-4 mb-4">Search by</h3>
                <!-- Timeframe -->
                <div class="px-4 input-search px-4">
                  <div class="c-text-input mb-2">
                    <label for="invoice">{{
                      'ordersList.timeFrame' | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="timeFrame"
                    [items]="timeFrameList"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="c-select ng-select ng-select-single dropdown-credit"
                    (ngModelChange)="clearDateRange()"
                    [(ngModel)]="timeFrame"
                  >
                  </ng-select>
                  <hr />
                </div>
                <!-- Date Range -->
                <div class="px-4">
                  <div class="c-text-input mb-2">
                    <label for="invoice" class="mt-4">{{
                      'ordersList.dateRange' | cxTranslate
                    }}</label>
                  </div>
                  <div class="time-frame">
                    <div class="pr-4 template-date">
                      <div class="control-group mb-2">
                        <label
                          for="fromDate"
                          aria-labelledby="dataRange fromDate"
                          >{{ 'ordersList.from' | cxTranslate }}</label
                        >
                      </div>
                      <div class="headSearch-holder">
                        <label class="searchbox orders-filter time-frame">
                          <img
                            src="../../../assets/icons/calendar.svg"
                            alt=""
                          />
                          <input
                            type="text"
                            placeholder="{{
                              'ordersList.dateFormat' | cxTranslate
                            }}"
                            class="input-filter delivery-pickup-calendar"
                            [(ngModel)]="fromDate"
                            (ngModelChange)="validateDate()"
                            bsDatepicker
                            [maxDate]="maxSelectionDate"
                            [daysDisabled]="[0, 6]"
                            [bsConfig]="{
                              containerClass: 'order-list-date',
                              dateInputFormat: dateFormat
                            }"
                          />
                        </label>
                      </div>
                    </div>
                    <div class="template-to-date">
                      <div class="control-group mb-2">
                        <label
                          for="toDate"
                          aria-labelledby="dataRange toDate"
                          >{{ 'ordersList.to' | cxTranslate }}</label
                        >
                      </div>
                      <div class="headSearch-holder">
                        <label class="searchbox orders-filter time-frame">
                          <img
                            src="../../../assets/icons/calendar.svg"
                            alt=""
                          />
                          <input
                            type="text"
                            placeholder="{{
                              'ordersList.dateFormat' | cxTranslate
                            }}"
                            class="input-filter"
                            [(ngModel)]="toDate"
                            (ngModelChange)="validateDate()"
                            bsDatepicker
                            [maxDate]="maxSelectionDate"
                            [daysDisabled]="[0, 6]"
                            [bsConfig]="{
                              containerClass: 'order-list-date',
                              dateInputFormat: dateFormat
                            }"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <span class="dateError" *ngIf="dateError">{{
                    'ordersList.invalidDateErrMsg' | cxTranslate
                  }}</span>
                  <span class="dateError" *ngIf="singleDateError">{{
                    'ordersList.selectBothDate' | cxTranslate
                  }}</span>
                  <hr />
                </div>
                <!-- Invoice -->
                <div class="px-4 mb-4">
                  <div class="c-text-input mb-2 mt-2">
                    <label for="invoice">{{
                      'ordersList.invoice' | cxTranslate
                    }}</label>
                  </div>
                  <!-- Invoice -->
                  <div class="c-text-input mb-4">
                    <div class="c-text-input--with-icon-left">
                      <input
                        placeholder="Search"
                        type="text"
                        autocomplete="off"
                        aria-describedby="initialDescription"
                        aria-controls="results"
                        aria-autocomplete="both"
                        [(ngModel)]="invoice"
                      />
                      <button><i class="fa fa-search"></i></button>
                    </div>
                  </div>
                  <!-- Po -->
                  <div class="c-text-input mb-2 mt-2">
                    <label for="poNumber">{{
                      'ordersList.po' | cxTranslate
                    }}</label>
                  </div>
                  <div class="c-text-input mb-4">
                    <div class="c-text-input--with-icon-left">
                      <input
                        placeholder="Search"
                        type="text"
                        autocomplete="off"
                        aria-describedby="initialDescription"
                        aria-controls="results"
                        aria-autocomplete="both"
                        [(ngModel)]="poNumber"
                      />
                      <button><i class="fa fa-search"></i></button>
                    </div>
                  </div>
                  <!-- Order no -->
                  <div class="c-text-input mb-2 mt-2">
                    <label for="orderNumber">{{
                      'ordersList.order' | cxTranslate
                    }}</label>
                  </div>
                  <div class="c-text-input">
                    <div class="c-text-input--with-icon-left">
                      <input
                        placeholder="Search"
                        type="text"
                        autocomplete="off"
                        aria-describedby="initialDescription"
                        aria-controls="results"
                        aria-autocomplete="both"
                        [(ngModel)]="order"
                      />
                      <button><i class="fa fa-search"></i></button>
                    </div>
                  </div>
                </div>
                <div class="px-4">
                  <button
                    type="button"
                    class="c-button c-button--primary mb-2 w-50"
                    (click)="applyFilter('mobile')"
                  >
                    {{ 'ordersList.applyFilters' | cxTranslate }}
                  </button>
                  <button
                    type="button"
                    id="clearOrderFiltersBtn"
                    class="c-button c-button--secondary mb-2 w-50"
                    (click)="clearFilters()"
                  >
                    {{ 'ordersList.clearFilters' | cxTranslate }}
                  </button>
                </div>
              </fieldset>
            </section>
            <footer class="px-4">
              <button
                class="c-button c-button--primary mb-2 w-50"
                (click)="applyFilter('mobile')"
              >
                {{ 'ordersList.done' | cxTranslate }}
              </button>
            </footer>
          </section>
        </ng-template>
        <div class="account-order-filter-btn mb-4">
          <button
            class="c-button c-button--secondary w-100"
            data-di-id="#refineBtn"
            (click)="refine()"
          >
            {{ 'ordersList.refine' | cxTranslate }}
          </button>
        </div>
        <div class="sortBy-select pb-4 mobile-view-select">
          <ng-select
            name="pageSize"
            [items]="pageList"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="c-select dropdown-credit set-pagination"
            [(ngModel)]="pageSizeperPage"
            (change)="changePageSize()"
          ></ng-select>
        </div>
      </section>

      <div
        class="d-flex justify-content-between pb-4 pb-md-2 pb-lg-0 px-3 px-lg-0"
      >
        <div class="text-fixed-legal-copy color-secondary font-weight-bold">
          {{ 'ordersList.past' | cxTranslate }}&nbsp;{{ selectedTimeFrame }}
        </div>
        <div class="d-flex gap-1">
          <a
            aria-label="download order history as"
            id="orderXLS"
            class="c-link"
            href="javascript:;"
            (click)="generateSaveXls()"
          >
            {{ 'ordersList.xls' | cxTranslate }}
            <i class="fa fa-table ml-1" aria-hidden="true"></i>
          </a>
          <a
            aria-label="download order history as"
            id="orderPDF"
            class="c-link"
            href="javascript:;"
            (click)="generateSavePdf()"
          >
            {{ 'ordersList.pdf' | cxTranslate }}
            <i class="fa fa-file-pdf ml-1" aria-hidden="true"></i>
          </a>
        </div>
      </div>

      <table
        aria-describedby="invoicesTable"
        id="ordersTable"
        class="c-table c-table--branded d-none d-lg-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              (click)="sortColumn($event, 'placed', orders, 'Date')"
              class="date-col"
            >
              {{ 'ordersList.date' | cxTranslate }}&nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              class="type-title"
              (click)="sortColumn($event, 'sapSystemType', orders, 'normal')"
            >
              {{ 'ordersList.type' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              (click)="sortColumn($event, 'guid', orders, 'Number')"
              class="order-title"
            >
              {{ 'ordersList.order' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              (click)="sortColumn($event, 'statusDisplay', orders, 'normal')"
              class="status-title"
            >
              {{ 'ordersList.status' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              class="po-title"
              (click)="
                sortColumn($event, 'purchaseOrderNumber', orders, 'normal')
              "
            >
              {{ 'ordersList.po' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th scope="col" class="invoice-title">
              {{ 'ordersList.invoice' | cxTranslate }} &nbsp;
            </th>
            <th scope="col" class="tracking-title">
              {{ 'ordersList.tracking' | cxTranslate }} &nbsp;
            </th>
            <th
              scope="col"
              class="po-title"
              (click)="sortColumn($event, 'shipToId', orders, 'normal')"
            >
              {{ 'ordersList.shipToId' | cxTranslate }} &nbsp;
              <i class="my-icon fas fa-caret-down"></i>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="totalOrders">
          <tr *ngFor="let data of ordersList; let i = index">
            <td>
              <span>{{ data?.placed | date : dateFormat }}</span>
            </td>
            <td>
              <span>{{ data.sapSystemType }}</span>
            </td>
            <td class="td-long-content">
              <a
                [routerLink]="['/my-account/order', data.guid]"
                href="javascript: void(0);"
              >
                {{ data.guid }}</a
              >
            </td>
            <td>{{ data?.statusDisplay }}</td>
            <td class="po-col">{{ getPONumber(data.purchaseOrderNumber) }}</td>
            <td class="td-long-content">
              <div *ngFor="let invoice of data.invoices">
                <a
                  (click)="generateInvoicePDF(invoice.invoiceNumber)"
                  class="pdf-text c-link"
                  >{{ invoice.invoiceNumber }}</a
                >
              </div>
            </td>
            <td class="td-long-content">
              <div *ngFor="let tracking of data?.tracking">
                <a
                  *ngIf="tracking?.carrier !== 'YB'"
                  class="pdf-text app-navigation-links"
                  href="unsafe:javascript:void(0)"
                  rel=""
                  type="external"
                  (click)="
                    navigateToTracking(
                      tracking?.trackingNumber,
                      tracking?.trackingURL,
                      $event
                    )
                  "
                >
                  {{ tracking?.trackingNumber }}
                </a>
              </div>
            </td>
            <td class="shipto-col">
              {{ data.shipToName }}<br />{{ data.shipToId }}
            </td>
          </tr>
        </tbody>
      </table>

      <section class="orders-row-table">
        <table
          aria-describedby="ordersTable"
          class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
        >
          <tr *ngFor="let data of ordersList; let i = index">
            <td class="header">
              {{ 'ordersList.date' | cxTranslate }}
            </td>
            <td>
              {{ data.placed | date : dateFormat }}
            </td>
            <td class="header">
              {{ 'ordersList.type' | cxTranslate }}
            </td>
            <td>
              {{ data.sapSystemType }}
            </td>
            <td class="header">
              {{ 'ordersList.order' | cxTranslate }}
            </td>
            <td class="long-content-cell">
              <a
                [routerLink]="['/my-account/order', data.guid]"
                href="javascript: void(0);"
              >
                {{ data.guid }}
              </a>
            </td>
            <td class="header">
              {{ 'ordersList.status' | cxTranslate }}
            </td>
            <td>{{ data.statusDisplay }}</td>
            <td class="header">
              {{ 'ordersList.po' | cxTranslate }}
            </td>
            <td>
              {{ data.purchaseOrderNumber }}
            </td>
            <td class="header">
              {{ 'ordersList.invoice' | cxTranslate }}
            </td>
            <td>
              <div *ngFor="let invoice of data.invoices" class="set-margin">
                <a
                  (click)="generateInvoicePDF(invoice.invoiceNumber)"
                  class="pdf-text invoice-number"
                >
                  {{ invoice.invoiceNumber }}
                </a>
              </div>
            </td>
            <td class="header">
              {{ 'ordersList.tracking' | cxTranslate }}
            </td>
            <td>
              <div *ngFor="let tracking of data.tracking" class="set-margin">
                <a
                  *ngIf="tracking?.carrier !== 'YB'"
                  class="pdf-text invoice-number app-navigation-links"
                  href="unsafe:javascript:void(0)"
                  rel=""
                  type="external"
                  (click)="
                    navigateToTracking(
                      tracking?.trackingNumber,
                      tracking?.trackingURL,
                      $event
                    )
                  "
                >
                  {{ tracking?.trackingNumber }}
                </a>
              </div>
            </td>
            <td class="header">Ship To</td>
            <td>{{ data.shipToName }} {{ data.shipToId }}</td>
          </tr>
        </table>
      </section>

      <div *ngIf="!totalOrders" class="empty-list">
        {{ 'ordersList.noOrders' | cxTranslate }}
      </div>
    </section>

    <section>
      <div id="prod-pagination">
        <p class="text-fixed-medium mb-2">
          {{ 'ordersList.showing' | cxTranslate }} &nbsp;
          <span class="no_rec">{{ end }}</span
          >&nbsp; {{ 'ordersList.of' | cxTranslate }} &nbsp;
          <span class="no_rec">{{ totalOrders }}</span
          >&nbsp; {{ 'ordersList.orders' | cxTranslate }}
        </p>
        <div class="c-pagination align-btns">
          <button
            [disabled]="curPage == 1"
            (click)="getCurrentPage('prev')"
            (click)="getOrderList()"
            class="c-pagination-btns mr-1"
          >
            <!-- <span class="chevronIconWhite prev"></span> -->
            <i class="fa fa-chevron-left"></i>
          </button>

          <button
            class="c-pagination-btns"
            [disabled]="
              curPage >= totalOrders / pageSize ||
              (totalOrders === 0 && pageSize === 0)
            "
            (click)="getCurrentPage('next')"
            (click)="getOrderList()"
          >
            <!-- <span class="chevronIconWhite next"></span> -->
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
        <p class="text-fixed-medium">
          <span
            >{{ 'ordersList.page' | cxTranslate }} {{ curPage }}
            {{ 'ordersList.of' | cxTranslate }} {{ numberOfPages() }}</span
          >
        </p>
      </div>
    </section>

    <div class="line-intersection mobile-view"></div>
    <section class="mobile-view">
      <div>
        <app-need-to-go-back></app-need-to-go-back>
      </div>
      <div class="line-intersection"></div>
    </section>
    <section class="mobile-view">
      <div class="banner-img">
        <app-flu-dashboard-banner
          bannerComponent="OrderHistoryBannerComponent"
        ></app-flu-dashboard-banner>
      </div>
    </section>
  </section>
</section>
