import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DataLayerService } from './data-layer.service';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class ExcelDownloadService {
  constructor(private dataLayerService: DataLayerService) {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    filename_change_not_needed?: boolean
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(
      excelBuffer,
      excelFileName,
      filename_change_not_needed
    );
  }

  private saveAsExcelFile(
    buffer: any,
    fileName: string,
    filename_change_not_needed?: boolean
  ): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    if (!!filename_change_not_needed) {
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    } else {
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    }
    this.dataLayerService.downloadDataLayer(fileName, 'xlsx', fileName);
  }
}
