import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalMessageService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { EmailPopupComponent } from 'src/app/shared/modules/email-popup/email-popup/email-popup.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UserAccessManagementService } from '../user-access-management.service';
import {
  GlobalMessageType, LanguageService,
  OccEndpointsService, RoutingService
} from '@spartacus/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-overview',
  templateUrl: './customer-overview.component.html',
  styleUrls: ['./customer-overview.component.scss']
})
export class CustomerOverviewComponent implements OnInit, OnDestroy {
  nameEditIsNotClicked = true;
  roleEditIsNotClicked = true;
  passwordEditIsNotClicked = true;
  passRecEditIsNotClicked = true;
  emailEditIsNotClicked = true;
  commConfmEditIsNotClicked = true;
  nameForm: FormGroup;
  passwordForm: FormGroup;
  emailForm: FormGroup;
  alternateEmailForm: FormGroup;
  chosenRoleofUser = '';
  passwordYetToMatch: boolean;
  passwordIsNotValid = true;
  passwordConfirmStatus = '';
  chosenQueForPassRecovery = '';
  ansForQuePassRecovery = null;
  loginUserInfo: any;
  emailCommunicationStatus = '';
  userIdDisable: any = 'true';
  userIdDisableReadOnly: any;
  roleOfUserArray = [];
  passRecQuesArray = [];
  displayRoleofUser: any;
  displayQueForPassRecovery: any;
  showAlternateEmailField = false;
  currentPasswordIsNotValid = true;
  cPassError: any = '';
  emailRegex = '';
  userDtaaSubscriber: any;
  userRolesSubscriber: any;
  userDtaUpdateSubscriber: any;
  passQueSubscriber: any;
  userDataUpdateSubscriber2: any;
  changeLogArray: any = [];
  userIdEditIsNotClicked = true;
  customerId: any;
  updateDetailsSubscriber: any;
  cstDetailsSubscriber: any;
  resetPasswordBoolean: any = null;
  changeHistorySubscriber: any;
  changeHistoryArray: any = [];
  constructor(
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly userManagementService: UserAccessManagementService,
    private readonly sharedService: SharedService,
    private readonly globalMessage: GlobalMessageService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.customerId = this.sharedService.getSessionStorage('customerId')
    this.formInitialisation();
    this.cstDetailsSubscriber = this.userManagementService.customerDetails.subscribe((userData: any) => {
      if (userData?.res) {
        this.loginUserInfo = userData?.res;
        this.getChangeHistory();
        this.userIdDisableReadOnly = (this.loginUserInfo?.userDisabled).toString();
        this.userIdDisable = (this.loginUserInfo?.userDisabled).toString();
        if (userData?.id === null) {
          this.setAllFormField();
          this.userRoleGetMethod();
          this.getPassWordRecQues();
        }
        else {
          if (userData.id === 'name') {
            this.setNameField();
          } else if (userData.id === 'role') {
            this.userRoleGetMethod();
          }
          else if (userData.id === 'email') {
            this.setEmailForm();
          }
          else {
            // No Change
          }
        }
        this.ref.detectChanges();
      }
    })

  }

  ngOnDestroy() {
    this.changeHistorySubscriber?.unsubscribe();
    this.updateDetailsSubscriber?.unsubscribe();
    this.passQueSubscriber?.unsubscribe();
    this.userRolesSubscriber?.unsubscribe();
    this.cstDetailsSubscriber?.unsubscribe();

  }
  setAllFormField() {
    this.setNameField();
    this.setPassRecField();
    this.setEmailForm();

  }
  // ===================================ChangeHistory===============================
  getChangeHistory() {
    this.userManagementService.getChangeHistory(this.customerId);
    this.changeHistorySubscriber = this.userManagementService.changeHistory.subscribe((data:any) => {
      if (data) {
        this.changeHistoryArray=[];
        this.changeHistoryArray = data?.modifiedEntries;
        this.ref.detectChanges();
      }
    })
  }
  // =======================All forms Initilaisation=============================
  formInitialisation() {
    this.nameForm = new FormGroup({
      actualFirstName: new FormControl('', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9 '-]*$")
      ]),
      actualLastName: new FormControl('', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9 '-]*$")
      ]),
    });
    this.passwordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    });
    this.emailForm = new FormGroup({
      emailId: new FormControl('', [Validators.required, Validators.email,

      Validators.pattern('^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$')]),
      confirmEmailId: new FormControl('', Validators.required),
    });
    this.alternateEmailForm = new FormGroup({
      alternateEmailId: new FormControl('', [Validators.required, Validators.email,
      Validators.pattern('^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$')]),
      confirmAlternateEmailId: new FormControl('', Validators.required),
    });
  }
  // ===============================Name field functions=======================
  setNameField() {
    this.nameForm.reset();
    let firstName = '';
    let lastName = '';
    if (this.loginUserInfo?.customerFirstName) {
      firstName = this.loginUserInfo.customerFirstName;
    }
    if (this.loginUserInfo?.customerLastName) {
      lastName = this.loginUserInfo.customerLastName;
    }

    this.nameForm.setValue({
      actualFirstName: firstName,
      actualLastName: lastName,
    });
    this.ref.detectChanges();
  }
  editNameField() {
    this.nameEditIsNotClicked = false;
  }
  updateNameField() {
    this.nameEditIsNotClicked = true;

    const params = {
      customerFirstName: this.nameForm.controls['actualFirstName'].value,
      customerLastName: this.nameForm.controls['actualLastName'].value,
      userName: this.customerId,
      profileUpdateType: "NAME"
    }
    this.updateAllUserDataFields(params, 'name');

  }
  cancelNameUpdate() {
    this.nameEditIsNotClicked = true;
    this.setNameField();
  }
  // ===============================Role field functions=======================

  userRoleGetMethod() {
    this.pfService.getUserRoles();
    this.userRolesSubscriber = this.pfService.userRoles.subscribe((data) => {
      if (data) {
        this.roleOfUserArray = data;
        this.userRoleMapping();
      }
    });
  }

  userRoleMapping() {

    this.roleOfUserArray.forEach((res) => {

      if (this.loginUserInfo?.customerRole && res.name === this.loginUserInfo?.customerRole) {
        this.chosenRoleofUser = res.code;
        this.displayRoleofUser = res.name;
        this.ref.detectChanges();

      }

    });
  }

  editRoleField() {
    this.roleEditIsNotClicked = false;
  }
  updateRoleField() {
    this.roleEditIsNotClicked = true;
    this.roleOfUserArray.forEach((res) => {
      if (res.code === this.chosenRoleofUser) {
        this.displayRoleofUser = res.name;
        this.ref.detectChanges();
      }
    });

    const params = {
      customerRole: this.chosenRoleofUser,
      userName: this.customerId,
      profileUpdateType: "ROLE"
    }
    this.updateAllUserDataFields(params, 'role');
  }
  cancelRoleUpdate() {
    this.roleEditIsNotClicked = true;
    this.userRoleMapping();
  }
  // ==================================================user disable========================

  editUserIdRadio() {
    this.userIdEditIsNotClicked = false;
  }
  cancelUserIdDisable() {
    this.userIdEditIsNotClicked = true;
    this.userIdDisableReadOnly = (this.loginUserInfo?.userDisabled).toString();
    this.userIdDisable = (this.loginUserInfo?.userDisabled).toString();
  }

  updateUserId() {

    const params = {
      userDisabled: this.userIdDisable,
      userName: this.customerId,
      profileUpdateType: "USER_DISABLED"
    }
    this.userIdEditIsNotClicked = true;
    this.updateAllUserDataFields(params, 'userId')
  }
  // ===============================PassRec==========================================

  getPassWordRecQues() {
    this.pfService.getHintQuestions();
    this.passQueSubscriber = this.pfService.passQues.subscribe((data) => {
      if (data) {
        this.passRecQuesArray = data;
        this.passRecQueMapping();
        this.ref.detectChanges();

      }

    });
  }

  passRecQueMapping() {
    this.passRecQuesArray.forEach((res) => {
      if (this.loginUserInfo?.passwordQuestion &&
        this.loginUserInfo?.passwordQuestion !== null &&
        res.name === this.loginUserInfo?.passwordQuestion) {
        this.chosenQueForPassRecovery = res.code;

      }

    });
  }

  setPassRecField() {
    if (this.loginUserInfo?.passwordQuestion &&
      this.loginUserInfo?.passwordAnswer) {
      this.displayQueForPassRecovery = this.loginUserInfo?.passwordQuestion;
      this.ansForQuePassRecovery = this.loginUserInfo?.passwordAnswer;
    } else {
      this.displayQueForPassRecovery = '';
      this.ansForQuePassRecovery = null;
    }

    this.ref.detectChanges();
  }
  editPassRecField() {
    this.passRecEditIsNotClicked = false;
  }
  cancelPassRecUpdate() {
    this.passRecEditIsNotClicked = true;
    this.setPassRecField();
    this.passRecQueMapping();
  }


  // ===========================================Email Functions==============================
  setEmailForm() {
    this.emailForm.reset();
    this.emailForm.setValue({
      emailId: this.loginUserInfo?.email,
      confirmEmailId: '',
    });
    this.ref.detectChanges();
  }
  cancelEmailUpdate() {
    this.emailEditIsNotClicked = true;
    this.setEmailForm();
  }
  updateEmailField() {

    const params = {
      email: this.emailForm.controls['emailId'].value,
      confirmEmail: this.emailForm.controls['confirmEmailId'].value,
      userName: this.customerId,
      profileUpdateType: "EMAIL"
    }
    this.updateAllUserDataFields(params, 'email');
    this.emailEditIsNotClicked = true;
  }
  editEmailField() {
    this.emailEditIsNotClicked = false;
  }

  // ==================================UpdateDetals==========================
  updateAllUserDataFields(params, id) {
    this.userManagementService.updateCustomerDetails(this.customerId, params);
    this.updateDetailsSubscriber = this.userManagementService.updateCustDetails.subscribe((res) => {
      if (res === null) {
        this.userManagementService.getCustomerDetails(this.customerId, id);
      }


    },
      (err) => { });
  }

  // ============================resetPasswordLink====================
  resetPasswordLink() {
    this.userManagementService.resetPasswordService(this.customerId, {});
    this.userManagementService.resetPasswordDetails.subscribe((data: any) => {
      if (data) {
        if (data?.resetPasswordEmailSent) {
          this.resetPasswordBoolean = data?.resetPasswordEmailSent;
        }
        else {
          this.resetPasswordBoolean = false;
        }
        this.ref.detectChanges()
      }
    })
  }
  backToView() {
    this.router.navigate(['/', 'user-management']);
  }
}
