import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService, LanguageService, ProductService, RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { CheckoutService } from '../../shared/modules/checkout/checkout.service';
import { AccountOverviewService } from '../account-overview.service';

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent implements OnInit , OnDestroy{
  accountNumber: string = null;
  compNameAdBanner = 'SanofiAccountOverviewBannerComponent4';
  compNameAdBanner2 = 'SanofiAccountOverviewBannerComponent2';
  compNameAdBanner3 = 'SanofiAccountOverviewBannerComponent3';
  compNameAdBanner6 = 'SanofiAccountOverviewBannerComponent6';
  compNameAdBanner1 = 'SanofiAccountOverviewBannerComponent';
  cmsResponse: any;
  productCodes: any;
  prodcutsInfo: any = [];
  prodDocs: any = [];
  prodDocus: any = [];
  fluResevartion: boolean;
  userAccSub: Subscription;

  constructor(
    private readonly acService: AccountOverviewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly cmsData: CmsService,
    protected productService: ProductService,
    private readonly checkoutService: CheckoutService,
    private readonly sanitizer: DomSanitizer,
    private readonly lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.lang.getActive().subscribe((res)=>{
      if(res){
        this.prodcutsInfo = [];
      }
    });
    this.acService.getAccountId();
    this.userAccSub = this.acService.userAccountIdDetailsData.subscribe((res) => {
      if (this.accountNumber === null) {
        this.accountNumber = res.defaultB2BUnit.uid;
        this.cdr.detectChanges();
        this.userAccSub?.unsubscribe();
      }
    });
    this.cmsData
      .getComponentData('ProductMonographComponent')
      .subscribe((res) => {
        this.cmsResponse = res;
      });
    // this.productMonograph();
  }

  navigateToAccountDetails() {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  productMonograph() {
    this.productCodes = this.cmsResponse.productCodes;
    this.productCodes = this.productCodes.split(' ');
    this.productCodes.forEach((element) => {
      this.productService.get(element).subscribe((res) => {
        if (res) {
          this.prodcutsInfo.push(res);
          this.cdr.detectChanges();
        }
      });
    });
  }
  ngOnDestroy() {
    this.userAccSub?.unsubscribe();
  }
}
