import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-stock-availability',
  templateUrl: './stock-availability.component.html'
})
export class StockAvailabilityComponent implements OnInit {
  @Input() productDetails;
  @Input() placement = 'top';

  stockAvailableStatusForWarehouses: [];

  ngOnInit(): void {
    this.filterWarehouses()
  }

  filterWarehouses() {
    this.stockAvailableStatusForWarehouses = this.productDetails.stockAvailableStatusForWarehouses.filter(
      el => el.warehouseCode !== "ZOR"
    ).map(el => {
      if(el.stockAvailableStatus === "LIMITED" && !this.productDetails.allowLimitedStockProducts) {
        return {
          ...el,
          stockAvailableStatus: "ENDED"
        } 
      }
      return el;
    })

  }
  
}