<section class="c-feedback" 
  *ngIf="userIsLoggedIn | async"  [ngbPopover]="popFeedback"
  triggers="manual"
  popoverClass="popover-links saveorder-incart" 
  #p1="ngbPopover"
  (mouseover)="p1.open()"
  (mouseleave)="p1.close()"
  container="body"
  placement="left"
  (click)="openModal(feedbackModal)" >
  <div class="c-feedback-btn">
    {{ "feedback.feedbackTitle" | cxTranslate }}
  </div>
  <ng-template #popFeedback>
    <p class="py-4 px-2 text-fixed-legal-copy">
      {{ "feedback.toolTipContent" | cxTranslate }}
    </p>
  </ng-template>
  <section class="checkout-modal">
    <section class="modal-dialog">
      <section class="modal-content">
        <ng-template #feedbackModal let-modal>
          <section class="feedback-modal header-modal checkout-modal modal-selector modal-with-select">
            <section class="modal-dialog d-flex">
              <section class="modal-content" >
                <div class="close">
                  <i
                    class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
                <section class="modal-header cart-Modal-Header">
                  <h2
                    class="c-heading-36 font-bold w-100 mb-0"
                    id="modal-basic-title"
                  >
                 {{"feedback.sendFeedBack" | cxTranslate }}
                  </h2>
                </section>
                <form [formGroup]="userFormGrp">
                  <section class="modal-body">
                    <p class="u-border-bottom-grey pb-4 mb-4">{{"feedback.copy" | cxTranslate }}</p>
                    <section class="input-group loc-SearchBar" >
                      <div class="c-text-input mb-4">
                        <label>{{"feedback.email" | cxTranslate }}</label>
                        <input
                          type="text"
                          formControlName="email"
                          class="form-control searchboxnew pl-3 color-grey-dark email"
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          disabled
                          readonly
                          placeholder="{{userFormGrp.controls['email'].value}}"
                        />
                      </div>
                      <div class="c-text-input mb-4">
                        <label>{{'feedback.subject' | cxTranslate }} *</label>
                        <input
                          type="text"
                          class="form-control searchboxnew pl-3"
                          formControlName="subject"
                          id="subject"
                          name="subject"
                          maxlength="30"
                          placeholder="{{'feedback.feedbackTitle' | cxTranslate }}"
                        />
                      </div>
                      <div class="c-text-input mb-4">
                        <label>{{'feedback.feedbackTitle' | cxTranslate }} *</label>
                        <textarea
                          cols="30" rows="2"
                          id="message"
                          name="message"
                          class="form-control searchboxnew"
                          maxlength="200"
                          formControlName="message"
                          required
                          placeholder="{{'feedback.feedbackPlaceholder' | cxTranslate }}"
                        ></textarea>
                      </div>
                      <p class="color-error" *ngIf="sendFail">
                        {{"feedback.errorMessage" | cxTranslate }}
                      </p>
                    </section>
                  </section>
                  <section class="modal-footer justify-content-start">
                    <button
                      type="submit"
                      class="c-button c-button--primary ml-3"
                      (click)="onSubmit($event);$event.preventDefault()"
                      [disabled]="userFormGrp.status == 'INVALID'"
                    >
                    {{"feedback.send" | cxTranslate }}
                    </button>
                  </section>
                </form>
              </section>
            </section>
          </section>
        </ng-template>
      </section>
    </section>
  </section>
</section>