import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomHeaderChangeAccountComponent } from './custom-header-change-account/custom-header-change-account.component';
import { CustomLogoutComponent } from './custom-logout/custom-logout.component';
import { LogoutModalComponent } from './custom-logout/logout-modal/logout-modal';

@NgModule({
  declarations: [
    CustomHeaderChangeAccountComponent,
    CustomLogoutComponent,
    LogoutModalComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ChangeAccountComponent: {
          component: CustomHeaderChangeAccountComponent,
        },
        CustomSignOutLink: {
          component: CustomLogoutComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class CustomHeaderModule {}
