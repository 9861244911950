<section class="container">
  <div class="row">
    <div class="col-md-8 text-md-right mb-4 mb-md-0">
      <a
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        href="/USD/Open-Catalogue/c/1"
        target=""
        class="c-link"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ 'addToCartItems.continueShopping' | cxTranslate }}
      </a>
    </div>
    <div
      class="col-md-4 text-right d-flex flex-wrap gap-1 justify-content-md-end mb-4 mb-md-0"
    >
      <ng-template #popSaveTitle
        ><div class="minicart-std-popover text-left">
          {{ 'addToCartItems.saveOrderTitle' | cxTranslate }}
        </div></ng-template
      >
      <ng-template #popSaveContent>
        <div class="c-text-input">
          <input
            type="text"
            class="save-order save-order-mini mx-0 mt-0 mb-1"
            required
            [(ngModel)]="saveCartName"
            maxlength="30"
            (keypress)="omit_special_char($event)"
          />
        </div>

        <div>
          <span class="common-error required">{{ errorMsg }}</span>
        </div>
        <div>
          <div class="d-flex justify-content-between mt-2 mb-2">
            <button
              type="reset"
              class="c-button c-button--secondary"
              (click)="toggle(p2)"
            >
              {{ 'addToCartItems.cancel' | cxTranslate }}
            </button>
            <button
              class="c-button c-button--primary"
              type="submit"
              data-di-id="di-id-3064f17d-e97cc628"
              (click)="saveOrder()"
            >
              {{ 'addToCartItems.save' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popSaveContent"
        [popoverTitle]="popSaveTitle"
        popoverClass="popover-links saveorder-popover-incart"
        triggers="manual"
        placement="top"
        container="body"
        #p2="ngbPopover"
        (click)="toggle(p2)"
        href="javascript:;"
      >
        <span>{{ 'addToCartItems.saveOrder' | cxTranslate }}</span>
        <fa-icon [icon]="faRectangleList" class="icon"></fa-icon>
      </a>
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        class="c-link"
        aria-label="download as"
        href="javascript:void(0)"
        target=""
        (click)="exportXls()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        onclick="window.print();"
        target=""
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</section>
<app-checkout
  [cartDetails]="cartDetails"
  [stdCartType]="stdCart"
></app-checkout>
