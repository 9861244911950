import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { translationChunksConfig } from '@spartacus/assets';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  RoutingService,
  SearchboxService,
  TranslationService,
  UrlModule,
  WindowRef,
  provideConfig,
} from '@spartacus/core';
import {
  IconModule,
  MediaModule,
  OutletPosition,
  SearchBoxComponentService,
  provideOutlet,
} from '@spartacus/storefront';
import { SearchBoxComponent } from './search-box/search-box.component';

// SearchBox
@NgModule({
  declarations: [SearchBoxComponent],
  imports: [
    CommonModule,
    MediaModule,
    IconModule,
    UrlModule,
    I18nModule,
    RouterModule,
    FormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SearchBox: {
          component: SearchBoxComponent,
          providers: [
            {
              provide: SearchBoxComponentService,
              useClass: SearchBoxComponentService,
              deps: [
                SearchboxService,
                RoutingService,
                TranslationService,
                WindowRef,
              ],
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  providers: [
    provideOutlet({
      id: 'SearchBox',
      position: OutletPosition.REPLACE,
      component: SearchBoxComponent,
    }),
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],

  exports: [SearchBoxComponent],
})
export class SearchBoxModule {}
