<section class="container">
  <div class="row">
    <div class="col-md-8 text-md-right mb-4 mb-md-0">
      <a
        href="/USD/Open-Catalogue/c/1"
        target=""
        id="continueShoppingId"
        data-di-id="#continueShoppingId"
        class="c-link"
        (click)="navigateSaveOrder()"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>
        {{ "addToCartItems.continueShopping" | cxTranslate }}
      </a>
    </div>
    <div class="col-md-4"></div>
  </div>
</section>

<section class="container my-4">
  <div class="row">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <div class="text-fixed-medium u-border-top-grey py-4 c-prod-details">
        <div class="row mb-md-4">
          <!-- icon & name -->
          <div
            class="col-md-12 col-lg-4 d-flex align-items-center mb-4 mb-md-0"
          >
            <img
              class="c-link-icon"
              src="../../assets/icons/cart-icon.svg"
              alt=""
              style="width: 20px"
            />&nbsp;
            <p class="font-bold m-0">
              {{ cartData?.name }}
            </p>
          </div>
          <!-- Rename / Delete -->
          <div class="col-md-6 col-lg-3 d-flex align-items-center mb-4 mb-md-0">
            <a
              id="linkViewEdit"
              aria-labelledby="cartHeading"
              aria-describedby="linkViewEdit"
              target=""
              class="c-link mr-4"
              data-di-id="#linkViewEdit"
              [ngbPopover]="popRenameContent"
              [popoverTitle]="popRenameTitle"
              triggers="manual"
              #p2="ngbPopover"
              placement="top"
              (click)="toggle(p2)"
              container="body"
              popoverClass="popover-links"
              >{{ "addToCartItems.rename" | cxTranslate
              }}<img
                class="edit-icon"
                src="../../assets/icons/pencil.svg"
                alt=""
            /></a>
            <a
              href="javascript:void(0)"
              class="c-link"
              data-toggle="popover"
              data-placement="top"
              data-target="save-cart-0"
              data-original-title=""
              id="linkViewDelete"
              aria-labelledby="cartHeading"
              data-di-id="#linkViewDelete"
              [ngbPopover]="popDeltContent"
              [popoverTitle]="popDeltTitle"
              triggers="manual"
              #p3="ngbPopover"
              placement="top"
              (click)="toggle(p3)"
              container="body"
              popoverClass="popover-links"
            >
              {{ "addToCartItems.delete" | cxTranslate }}
              <img class="delt-icon" src="../../assets/icons/trash.svg" alt=""
            /></a>
            <!--Popover content for Save cart start-->
            <!--Popover content for Save cart end-->
            <ng-template #popRenameTitle
              ><span class="text-header rename-in-savedcart">{{
                "addToCartItems.renameInSavedOrder" | cxTranslate
              }}</span>
            </ng-template>
            <ng-template #popRenameContent>
              <input
                type="text"
                class="save-order input-in-savedcart"
                #name="ngModel"
                maxlength="30"
                required
                [(ngModel)]="renameInSavedCart"
                (keypress)="omit_special_char($event)"
              />
              <span
                *ngIf="name.errors?.required"
                class="common-error required"
                >{{ errorMsg }}</span
              >
              <span class="common-error required">{{ errorMsg }}</span>
              <div class="c-popover">
                <button
                  type="reset"
                  class="popover-close popover-cancel-button c-button c-button--secondary"
                  data-di-id="di-id-d01008be-fb6e135c"
                  (click)="toggle(p2)"
                >
                  {{ "addToCartItems.cancel" | cxTranslate }}
                </button>
                <button
                  class="popover-cancel-button c-button c-button--primary"
                  type="submit"
                  data-di-id="di-id-3064f17d-e97cc628"
                  (click)="saveOrder()"
                >
                  {{ "addToCartItems.save" | cxTranslate }}
                </button>
              </div>
            </ng-template>
            <ng-template #popDeltTitle
              ><span class="text-header"
                >{{ "addToCartItems.delete" | cxTranslate }}
                {{ cartData?.name }}
                {{ "addToCartItems.deleteTitleSavedOrder" | cxTranslate }}</span
              >
            </ng-template>
            <ng-template #popDeltContent>
              <div class="c-popover">
                <button
                  type="reset"
                  class="popover-close popover-cancel-button c-button c-button--secondary"
                  data-di-id="di-id-d01008be-fb6e135c"
                  (click)="toggle(p3)"
                >
                  {{ "addToCartItems.cancel" | cxTranslate }}
                </button>
                <button
                  class="popover-cancel-button c-button c-button--primary"
                  type="submit"
                  data-di-id="di-id-3064f17d-e97cc628"
                  (click)="deleteCart()"
                >
                  {{ "addToCartItems.delete" | cxTranslate }}
                </button>
              </div>
            </ng-template>
          </div>
          <!-- Buttons -->
          <div
            class="col-md-6 col-lg-5 mb-4 mb-md-0 d-flex justify-content-start justify-content-md-end"
          >
            <button
              type="button"
              class="c-button c-button--secondary mr-4"
              (click)="navigateSaveOrder()"
            >
              {{ "addToCartItems.cancel" | cxTranslate }}
            </button>
            <button
              type="submit"
              name="saveCartChanges"
              class="c-button c-button--primary"
              [disabled]="disabled"
              (click)="updateCart()"
            >
              {{ "addToCartItems.saveChanges" | cxTranslate }}
            </button>
          </div>
        </div>
        <!-- table -->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <table
              class="c-table c-table--branded d-none d-lg-table mt-0"
              aria-describedby="saveCart"
            >
              <thead>
                <tr>
                  <th id="restoreTableCol1">
                    {{ "addToCartItems.mySavedOrder_TotalUnits" | cxTranslate }}
                  </th>
                  <th id="restoreTableCol2">
                    {{
                      "addToCartItems.mySavedOrder_LastOrdered" | cxTranslate
                    }}
                  </th>
                  <th id="restoreTableCol3">
                    {{
                      "addToCartItems.mySavedOrder_LastUpdated" | cxTranslate
                    }}
                  </th>
                  <th id="restoreTableCol4">
                    {{
                      "addToCartItems.mySavedOrder_dateCreated" | cxTranslate
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td headers="restoreTableCol1">
                    {{ getQuantity(cartData?.childCarts) }}
                  </td>
                  <td headers="restoreTableCol2">
                    {{ getOrders(cartData?.childCarts) }}
                  </td>
                  <td headers="restoreTableCol3">
                    {{
                      cartData?.modifiedTime
                        | slice : 0 : 10
                        | date : "dd/MM/yyyy"
                    }}
                  </td>
                  <td headers="restoreTableCol4">
                    {{
                      cartData?.saveTime | slice : 0 : 10 | date : "dd/MM/yyyy"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none mb-4"
            >
              <tr class="mb-0">
                <td class="header">
                  {{ "addToCartItems.mySavedOrder_TotalUnits" | cxTranslate }}
                </td>
                <td>{{ getQuantity(cartData?.childCarts) }}</td>
                <td class="header">
                  {{ "addToCartItems.mySavedOrder_LastOrdered" | cxTranslate }}
                </td>
                <td>{{ getOrders(cartData?.childCarts) }}</td>
                <td class="header">
                  {{ "addToCartItems.mySavedOrder_LastUpdated" | cxTranslate }}
                </td>
                <td>
                  {{
                    cartData?.modifiedTime
                      | slice : 0 : 10
                      | date : "dd/MM/yyyy"
                  }}
                </td>
                <td class="header">
                  {{ "addToCartItems.mySavedOrder_dateCreated" | cxTranslate }}
                </td>
                <td>
                  {{
                    cartData?.saveTime | slice : 0 : 10 | date : "dd/MM/yyyy"
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- A/C -->
        <div class="row mb-4">
          <div class="col-md-12">
            <div
              _ngcontent-serverapp-c225=""
              id="location-select"
              class="mt-4 pt-2 ml-2"
            >
              <i class="fa fa-map-marker-alt font-n"></i
              ><span class="px-3 text-fixed-medium">{{
                selectedLocation
              }}</span>
            </div>
          </div>
        </div>
        <!-- Products -->
        <div
          class="row pdp-list-data"
          *ngFor="let productEntry of productEntries"
        >
          <div class="col-md-2">
            <a (click)="productDetails(productEntry)">
              <img
                *ngIf="productEntry?.PRIMARY !== undefined"
                src="{{ productEntry?.PRIMARY?.url }}"
                alt=""
              />

              <img
                *ngIf="productEntry?.PRIMARY === undefined"
                [src]="fallbackImage"
                alt=""
              />
            </a>
          </div>
          <div class="col-md-6 col-lg-7">
            <a (click)="productDetails(productEntry)">
              <h2
                class="c-heading c-heading-h4 mb-4"
                [innerHTML]="productEntry?.product?.name"
              ></h2>
              <p
                class="text-fixed-medium color-grey"
                [innerHTML]="productEntry?.product?.description"
              ></p>
              <p class="text-fixed-medium color-grey">
                <span class="font-bold"
                  >{{ "plp.productNumber" | cxTranslate }} </span
                >:
                {{ productEntry?.product?.sapEan }}
              </p>
            </a>
          </div>
          <div class="col-md-4 col-lg-3 unit-list">
            <div class="mb-2 text-center font-weight-bold color-grey-dark">
              {{ "plp.units" | cxTranslate }}
            </div>
            <section
              class="unit-area d-flex flex-nowrap align-items-center justify-content-center"
            >
              <span
                class="disabled"
                aria-hidden="true"
                id="{{ 'minusIcon' + productEntry?.product?.code }}"
                (click)="
                  performQuantityComputation(
                    2,
                    productEntry?.product?.code,
                    productEntry?.product?.quantityPerUnit
                  )
                "
              >
                <img
                  src="../../assets/icons/less.svg"
                  id="{{ 'unitMinus' + productEntry?.product?.code }}"
                  [ngClass]="{
                    'disabled-img': productEntry?.quantity === 1
                  }"
                  alt=""
                />
                <img
                  src="../../assets/icons/less-disable.svg"
                  id="{{ 'unitMinusDisable' + productEntry?.product?.code }}"
                  [ngClass]="{
                    'disabled-img': productEntry?.quantity > 1
                  }"
                  alt=""
                />
              </span>
              <input
                type="number"
                class="prod-quantity text-center"
                value="{{ productEntry?.quantity }}"
                (keyup)="
                  performQuantityComputation(
                    null,
                    productEntry?.product?.code,
                    productEntry?.product?.quantityPerUnit
                  )
                "
                id="{{ 'prodQuantity' + productEntry?.product?.code }}"
                min="1"
                max="9999"
                onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
              />
              <span
                class=""
                aria-hidden="true"
                id="{{ 'plusIcon' + productEntry?.product?.code }}"
                (click)="
                  performQuantityComputation(
                    1,
                    productEntry?.product?.code,
                    productEntry?.product?.quantityPerUnit
                  )
                "
              >
                <img
                  src="../../assets/icons/more.svg"
                  id="{{ 'unitPlus' + productEntry?.product?.code }}"
                  alt=""
                />
                <img
                  src="../../assets/icons/more-disable.svg"
                  id="{{ 'unitPlusDisable' + productEntry?.product?.code }}"
                  class="disabled-img"
                  alt=""
                />
              </span>
            </section>
          </div>
        </div>
      </div>
    </div>
    <aside class="col-md-4 pl-md-4 pl-lg-5">
      <div class="c-widget c-quick-order-widget">
        <div>
          <h3 class="c-widget-title c-heading c-heading-h3">
            {{ "addToCartItems.addProducts" | cxTranslate }}
          </h3>
          <h6 class="c-heading c-heading-h5 color-secondary">
            {{ "addToCartItems.quickOrder" | cxTranslate }}
          </h6>
          <p class="text-fixed-legal-copy">
            {{ "addToCartItems.searchItems" | cxTranslate }}
          </p>
        </div>
        <app-quick-order class="w-100"></app-quick-order>
      </div>
    </aside>
  </div>
</section>
