import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { Subscription } from 'rxjs';
import { MakeAPaymentService } from '../../../custom-make-a-payment/make-a-payment.service';
import { CommonUtils } from '../../../shared/utils/common.utils';
import {
  NumberTen,
  NumberThree,
  NumberTwo
} from '../../../shared/utils/constants/global.constants';
import { InvoiceUtils } from '../../../shared/utils/invoice.utils';
import { AccountOverviewService } from '../../account-overview.service';
const symbolNarrow = 'symbol-narrow';
const tabs = ['INVOICES', 'CREDITS', 'STATEMENTS'];
@Component({
  selector: 'app-financials-list',
  templateUrl: './financials-list.component.html',
  styleUrls: ['./financials-list.component.scss'],
})
export class FinancialsListComponent implements OnInit, OnDestroy {
  isLoadingDone = false;
  selectedTab: string = tabs[0];
  removeAside = true;
  currentDate = new Date();
  paymentList: any = [];
  showdropdown = false;
  creditList: any = [];
  payment: any;
  myDate: string;
  userAccSub: Subscription;
  creditItems: any = [];
  invoiceItems: any = [];
  creditDropDownSource: any[];
  listofSelectedInvoices: any = [];
  indexOfSelectedInvoice: any;
  accountNumber: string = null;
  isPDF = false;
  showXlsPdf = false;
  excelInvoiceHeaderIds: string[] = [
    'blineDate',
    'docNo',
    'dueDate',
    //'amount',
    'amountDue',
  ];
  excelCreditsHeaderIds: string[] = ['creditDate', 'creditNo', 'creditAmt'];
  creditInvoiceSub: Subscription;
  constructor(
    private readonly datePipe: DatePipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly makeAPayment: MakeAPaymentService,
    private readonly cdr: ChangeDetectorRef,
    private readonly acService: AccountOverviewService
  ) { }

  ngOnInit(): void {
    this.invoiceItems = [];
    this.creditItems = [];
    sessionStorage.removeItem('creditData');
    sessionStorage.removeItem('invoicesData');
    this.myDate = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
    this.userAccSub = this.acService.userAccountIdDetailsData.subscribe(
      (res) => {
        if (this.accountNumber === null) {
          this.accountNumber = res.defaultB2BUnit.uid;
          this.getInvoicesCreditData();
          this.cdr.detectChanges();
          this.userAccSub?.unsubscribe();
        }
      }
    );
    this.creditInvoiceSub = this.makeAPayment.creditInvoiceDate$.subscribe(
      (res) => {
        if (res !== false) {
          this.paymentList = InvoiceUtils.getInvoiceTableData(res.openInvoices);
          this.creditDropDownSource = InvoiceUtils.getCreditDropDownSource(
            this.paymentList
          );
          this.creditList = InvoiceUtils.getCreditTableData(
            res.openInvoices,
            this.creditDropDownSource
          );

          this.paymentList = CommonUtils.sortArr(
            'blineDate',
            this.paymentList,
            'Date',
            -1
          );
          this.paymentList =
            !!this.paymentList && this.paymentList.length >= NumberTen
              ? this.paymentList.slice(0, NumberTen)
              : this.paymentList.slice();
          this.creditList = CommonUtils.sortArr(
            'blineDate',
            this.creditList,
            'Date',
            -1
          );
          this.creditList =
            !!this.creditList && this.creditList.length >= NumberTen
              ? this.creditList.slice(0, NumberTen)
              : this.creditList.slice();
          this.paymentList.forEach((element) => {
            element.paymentAmount = this.currencyPipe.transform(
              element?.amount,
              'USD',
              symbolNarrow,
              '1.2-2'
            );
          });
          this.isLoadingDone = true;
          this.tabNavigation(tabs[0]);
          this.cdr.detectChanges();
        }
      }
    );
  }

  getInvoicesCreditData() {
    this.makeAPayment.getInvoices({
      accountId: this.accountNumber,
      fields: 'DEFAULT',
    });
  }

  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }

  invoicePdf(invoiceNo): void {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  creditPdf(creditNo): void {
    this.makeAPayment.downloadPdf(creditNo);
  }

  tabNavigation(id): void {
    this.selectedTab = id;
    if (this.selectedTab === tabs[0]) {
      this.showXlsPdf =
        !!this.paymentList && this.paymentList.length > 0 ? true : false;
      this.cdr.detectChanges();
    } else if (this.selectedTab === tabs[1]) {
      this.showXlsPdf =
        !!this.creditList && this.creditList.length > 0 ? true : false;
      this.cdr.detectChanges();
    } else {
      this.showXlsPdf = false;
      this.cdr.detectChanges();
    }
  }

  generatePDF() {
    this.isPDF = true;
    this.cdr.detectChanges();
    const options = {
      margin: 10,
      filename:
        this.selectedTab === 'INVOICES'
          ? 'invoicedetail.pdf'
          : 'creditdetails.pdf',
      image: { type: 'jpeg' },
      html2canvas: {},
      jsPDF: { orientation: 'portrait' },
    };
    const elemId =
      this.selectedTab === 'INVOICES' ? 'invoice-table' : 'credits-table';
    const element = document.getElementById(elemId);
    const domClone = element.cloneNode(true);
    this.selectedTab === 'INVOICES'
      ? domClone?.childNodes[0].removeChild(
        domClone?.childNodes[0]?.childNodes[1]
      )
      : domClone.removeChild(domClone?.childNodes[1]);
    let section = null;
    section = document.createElement('SECTION');
    section.setAttribute('id', 'pdf-invoices');
    section.classList.add('pdf-invoices-view');
    section.appendChild(document.getElementById('pdf-header'));
    section.appendChild(document.getElementById('pdf-subheader'));
    section.appendChild(document.getElementById('line-sanofi'));
    section.appendChild(domClone);
    document.getElementById('pdf-dummy').appendChild(section);
    const updatedElem = document.getElementById('pdf-dummy');
    html2pdf().from(updatedElem).set(options).save();
    this.isPDF = false;
    this.cdr.detectChanges();
  }

  generateExcel() {
    const filename =
      this.selectedTab === 'INVOICES' ? 'invoicedetail' : 'creditdetails';
    let data = [];
    data = this.computeDataForExcel();
    this.acService.downloadXls(data, filename, true);
  }

  computeDataForExcel() {
    const data = [];
    if (this.selectedTab === 'INVOICES') {
      const invoiceLabelHeaders = [];
      for (const item of this.excelInvoiceHeaderIds) {
        invoiceLabelHeaders.push(document.getElementById(item).textContent);
      }
      this.paymentList.forEach((element) => {
        let invDocNo = '';
        invDocNo = this.findAccurateDocNo(element);
        data.push({
          [invoiceLabelHeaders[0]]: element.blineDate,
          [invoiceLabelHeaders[1]]: invDocNo,
          [invoiceLabelHeaders[NumberTwo]]: element.dueDate,
          [invoiceLabelHeaders[NumberThree]]: element.amount,
          //[invoiceLabelHeaders[NumberFour]]: element.amount,
        });
      });
    } else {
      const creditLabelHeaders = [];
      for (const item of this.excelCreditsHeaderIds) {
        creditLabelHeaders.push(document.getElementById(item).textContent);
      }
      this.creditList.forEach((element) => {
        let docNo = '';
        docNo = this.findAccurateDocNo(element);
        data.push({
          [creditLabelHeaders[0]]: element.blineDate,
          [creditLabelHeaders[1]]: docNo,
          [creditLabelHeaders[NumberTwo]]: element.amount,
        });
      });
    }
    return data;
  }

  findAccurateDocNo(elem) {
    if (
      (elem?.docType === 'DZ' && elem?.docNo?.startsWith('7')) ||
      (elem?.docType === 'DZ' && !elem?.docNo?.startsWith('7'))
    ) {
      return elem.allocNmbr;
    } else if (elem?.docType === 'YR') {
      return elem.refhdr;
    } else {
      return elem.refDocNo;
    }
  }

  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  dateComparison(dueDate): boolean {
    return CommonUtils.dateComparison(dueDate, this.myDate);
  }
  ngOnDestroy() {
    this.creditInvoiceSub?.unsubscribe();
    this.userAccSub?.unsubscribe();
  }
}
