import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FaqTitleModule } from '../shared/modules/faq-title/faq-title.module';
import { CustomPipesModule } from '../shared/pipes/custom-pipes.module';
import { AccordianAnswersComponent } from './accordian-answers/accordian-answers.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [FaqComponent, AccordianAnswersComponent],
  imports: [
    CommonModule,
    NgbModule,
    FaqTitleModule,
    I18nModule,
    BrowserModule,
    FormsModule,
    CustomPipesModule,
  ],
  providers: [
    provideOutlet({
      id: 'FAQ-left',
      position: OutletPosition.REPLACE,
      component: FaqComponent,
    }),
  ],
})
export class FaqModule {}
