<section class="product-refinement" *ngIf="productList$ | async as productList">
  <section class="header">
    <aside aria-label="Product Category" class="content content-left">
      <section *ngIf="false" class="selectBox">
        <b>{{ "plp.category" | cxTranslate }} </b>
        <ng-select
          [items]="categoryFormat"
          bindLabel="name"
          bindValue="value"
          [multiple]="false"
          name="selectedCategory"
          placeholder="Select Category"
          [(ngModel)]="selectedCategory"
        >
        </ng-select>
      </section>
    </aside>
  </section>
  <section class="main">
    <aside aria-label="Product Filterby" class="content content-left">
      <section class="title">
        <h3
          class="c-text c-heading-h3 font-weight-bold u-border-bottom-grey pb-4 font-bold"
          *ngIf="facets$?.length > 0 else NaFacets">
            {{ "plp.filterby" | cxTranslate }}
        </h3>
          <ng-template #NaFacets>
              <h3 class="c-text c-heading-h3 font-weight-bold u-border-bottom-grey pb-4 font-bold">
                  {{ "plp.productAll" | cxTranslate }}
              </h3>
          </ng-template>
        <cx-facet
          class="facets"
          *ngFor="let facet of facets$"
          #facetRef
          [facet]="facet"
        ></cx-facet>
      </section>
      <section
        class="title"
        *ngIf="
          productList?.products?.length == 0 &&
          productList?.freeTextSearch?.length > 0
        "
      >
        <section class="filterByTitle-main explorer">
          <h4 class="c-text c-heading-h3 font-weight-bold pb-3 font-bold">
            {{ "plp.explore" | cxTranslate }}
          </h4>
        </section>

        <div *ngIf="userDetails != undefined" class="mb-4">
          <a href="/ss/en/USD/Product-Catalog/c/1" class="c-link mb-2">
            {{ "plp.exploreProducts" | cxTranslate | titlecase }}</a
          ><br />
          <a href="/cart/save" class="c-link mb-2">
            {{ "plp.exploreSavedOrders" | cxTranslate | titlecase }}</a
          >
        </div>
        <section *ngIf="userDetails == undefined">
          <a href="/ss/en/USD/Product-Catalog/c/1" class="c-link">
            {{ "plp.products" | cxTranslate | titlecase }}</a
          >
        </section>
      </section>
    </aside>
  </section>
  <ng-template #facetsRwd>
    <section class="modal-dialog refinement-modal">
      <div class="modal-content">
        <div class="close" aria-hidden="true" (click)="hide()">
          <i
            class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
          ></i>
        </div>
        <div class="modal-header">
          <h2 class="c-heading-36 font-bold pb-4 w-100">
            {{ "plp.selectRefinement" | cxTranslate }}
          </h2>
        </div>
        <div class="modal-body">
          <h3
            class="filterByTitle-main mb-2"
            *ngIf="
              productList?.products?.length > 0 ||
                productList?.freeTextSearch?.length == 0;
              else noProductsExplorer
            "
          >
            {{ "plp.filterby" | cxTranslate }}
          </h3>
          <ng-template #noProductsExplorer>
            <section class="filterByTitle-main explore-refine">
              {{ "plp.explore" | cxTranslate }}
            </section>
          </ng-template>
          <section class="facets">
            <cx-facet
              class="facets"
              *ngFor="let facet of facets$"
              #facetRef
              [facet]="facet"
            ></cx-facet>
          </section>
          <span id="clearAll-filters">
            <a
              href="/ss/en/USD/Product-Catalog/c/1"
              data-di-id="di-id-37774359-a6b0cba1"
              *ngIf="
                productList?.products?.length > 0 ||
                  productList?.freeTextSearch?.length == 0;
                else noProducts
              "
              class="mt-4"
            >
              {{ "plp.clearFliter" | cxTranslate }}</a
            >
            <ng-template #noProducts>
              <a
                href="/ss/en/USD/Product-Catalog/c/1"
                class="float-left products-link mt-4"
              >
                {{ "plp.products" | cxTranslate | titlecase }}</a
              >
            </ng-template>
          </span>
        </div>
      </div>
    </section>
  </ng-template>
  <button
    class="c-button c-button--secondary mb-4 w-100"
    id="refineBtn"
    data-di-id="#refineBtn"
    *ngIf="facets$?.length > 0"
    (click)="refine()"
  >
    {{ "plp.refineButton" | cxTranslate
    }}<span class="activeclass" *ngIf="activefacets">({{ activefacets }})</span
    ><span></span>
  </button>
</section>
