import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthService, OCC_USER_ID_CURRENT, StateWithClientAuth,
  UserIdService
} from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { VscaOAuthLibWrapperService } from '../../core/auth/user-auth/service/vsca-oauth-lib-wrapper.service';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class ForgotUserPasswordService {
  public validateEmail = new BehaviorSubject(null);
  validateEmail$ = this.validateEmail.asObservable();
  public validateAnswer = new BehaviorSubject(null);
  validateAnswer$ = this.validateAnswer.asObservable();
  public userData = new BehaviorSubject(null);
  userData$ = this.userData.asObservable();
  public resetPasswordResponse = new BehaviorSubject(null);
  reserPasswordResponse$ = this.resetPasswordResponse.asObservable();

  constructor(
    public readonly baseService: BaseService,
    private readonly oAuthLibWrapperService: VscaOAuthLibWrapperService,
    private readonly userIdService: UserIdService,
    private readonly store: Store<StateWithClientAuth>,
    private readonly authService: AuthService
  ) {}

  public validateEmailId(recaptchaToken:string, params) {
    const headers = {}
    if(recaptchaToken) {
      headers['sap-commerce-cloud-captcha-token'] = recaptchaToken
    }
    this.baseService.post(
      config.VALIDATE_EMAIL,
      params, 
      headers
    ).subscribe(
      (res) => {
        this.userData.next(params);
        this.validateEmail.next(res);
      },
      (err) => {
        this.userData.next(params);
        this.validateEmail.next(err);
      }
    );
  }

  public validatePasswordAnswer(answer) {
    this.baseService.post(config.VALIDATE_PASSWORD_ANSWER, answer).subscribe(
      (res) => {
        this.validateAnswer.next(res);
      },
      (err) => {
        this.validateAnswer.next(err);
      }
    );
  }

  public resetPassword(params) {
    this.baseService.post(config.RESET_PASSWORD, params).subscribe(
      (res) => {
        this.resetPasswordResponse.next(res);
      },
      (err) => {
        this.resetPasswordResponse.next(err);
      }
    );
  }

  public loginWithCredentials(userId: string, password: string) {
    this.oAuthLibWrapperService
      .vscaAuthorizeWithPasswordFlowForbaseSiteId(userId, password, 'ss')
      .then((res) => {
        this.userIdService.setUserId(OCC_USER_ID_CURRENT);
        this.store.dispatch(new AuthActions.Login());
      });
  }
}
