<section class="container p-0 mt-md-4 py-3">
  <section class="row mb-4">
    <section class="col-md-12">
      <h3 class="mb-3 font-bold">{{ "account.select" | cxTranslate }}</h3>
      <div class="row">
        <div class="col-md-12">
          <i class="fa fa-map-marker-alt font-n"></i>
          <span class="px-3 text-fixed-medium"
            >{{ selectedData?.companyName }}
            {{ "(" + selectedData?.unitID + ")" }}</span
          >
        </div>
        <div class="col-md-12 pl-4 ml-3">
          <span
            class="c-link ml-1"
            role="button"
            *ngIf="changeLocation"
            (click)="open(content)"
            >{{ "account.changeLocation" | cxTranslate }}</span
          >
        </div>
        <ng-template #content let-modal>
          <section class="checkout-modal modal-selector modal-with-select">
            <section class="modal-dialog">
              <section class="modal-content">
                <section
                  class="close"
                  (click)="onCancel()"
                  (click)="modal.dismiss('Cross click')"
                >
                  <i
                    class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                  ></i>
                </section>
                <section class="modal-header cart-Modal-Header">
                  <h2
                    class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                    id="modal-basic-title"
                  >
                    {{ "account.changeLocation" | cxTranslate }}
                  </h2>
                </section>
                <section class="modal-body">
                  <section class="input-group loc-SearchBar">
                    <div class="c-text-input">
                      <cx-icon class="cx-icon fas fa-search"></cx-icon>
                      <input
                        type="text"
                        #clientName
                        (keyup)="SearchClientName(clientName.value)"
                        class="form-control searchboxnew"
                        placeholder="{{
                          'account.filterByLocation' | cxTranslate
                        }}	"
                      />
                    </div>
                  </section>

                  <section>
                    <section
                      [ngClass]="{
                        displayNone: searchedClientList?.length === 0
                      }"
                      class="custom-radio radio_grp label-location w-100 mt-4"
                    >
                      <table
                        *ngFor="let data of searchedClientList; let i = index"
                        class="w-100 mt-2"
                        aria-describedby="locTable"
                      >
                        <tr
                          class="line-location"
                          [ngClass]="{
                            'label-location':
                              i !== searchedClientList.length - 1
                          }"
                        >
                          <td class="u-border-top-grey pt-1">
                            <input
                              type="radio"
                              (change)="onOptionsSelected(data)"
                              [id]="data.id"
                              [checked]="selectedData.id == data.id"
                              name="customRadio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                              [for]="data.id"
                            >
                              {{ data?.companyName }}
                              {{ "(" + data?.unitID + ")" }}
                            </label>
                            <p class="labelpp mb-2 text-fixed-medium">
                              {{ data?.line1 ? data?.line1 + ", " : "" }}
                              {{ data?.line2 ? data?.line2 + ", " : "" }}
                              {{ data?.town ? data?.town + ", " : "" }}
                              {{
                                data?.region?.isocodeShort
                                  ? data?.region?.isocodeShort + " "
                                  : ""
                              }}
                              {{ data?.postalCode }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </section>
                  </section>
                </section>
                <section class="modal-footer justify-content-start">
                  <button
                    type="button"
                    class="c-button c-button--primary ml-3"
                    (click)="onSubmit()"
                    (click)="modal.close('Save click')"
                  >
                    {{ "account.done" | cxTranslate }}
                  </button>
                </section>
              </section>
            </section>
          </section>
        </ng-template>
      </div>
    </section>
  </section>
  <section class="row pt-3">
    <section class="col-xs-12 col-sm-12 col-md-12 text-fixed-medium">
      <section>
        <h3 class="font-bold mb-3">
          {{ "account.shippingAddress" | cxTranslate }}
        </h3>
        <section class="post-edit">
          <section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.facilityName" | cxTranslate
              }}</label>
              <span>{{ selectedData?.companyName || "--" }}</span>
            </section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.address" | cxTranslate
              }}</label>
              <span>{{ selectedData?.line1 }} {{ selectedData?.line2 }}</span>
            </section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.city" | cxTranslate
              }}</label>
              <span>{{ selectedData?.town || "--" }}</span>
            </section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.province" | cxTranslate
              }}</label>
              <span>{{ selectedData?.region?.isocodeShort || "--" }}</span>
            </section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.postalCode" | cxTranslate
              }}</label>
              <span>{{ selectedData?.postalCode || "--" }}</span>
            </section>
            <section class="mb-3">
              <section class="phone">
                <section class="row">
                  <section class="col-md-6">
                    <label class="font-bold d-block">{{
                      "account.phone" | cxTranslate
                    }}</label>
                    <span
                      id="phone-mask-text"
                      maxlength="12"
                      autocomplete="off"
                    >
                      {{ selectedData?.phone || "--" }}</span
                    >
                  </section>
                  <section class="col-md-6">
                    <label class="font-bold d-block">{{
                      "account.extension" | cxTranslate
                    }}</label>
                    <span> {{ selectedData?.extension || "--" }}</span>
                  </section>
                </section>
              </section>
            </section>
            <section class="mb-3">
              <label class="font-bold d-block">{{
                "account.practiceFacility" | cxTranslate
              }}</label>
              <span>{{ usergetDataObj?.practiceOrFacilityType || "--" }}</span>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
