import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseSiteService,
  getLastValueSync,
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
})
export class AccountInformationComponent implements OnInit, OnDestroy {
  accountInfoForm: FormGroup;
  errorMsg = false;
  billToShipError = false;
  errorMessageText: string = globalErrorMsgEng;
  accountInfoSub: Subscription;
  userSub: Subscription;
  captchaResp: string;
  existingUserContinue = false;
  emailRegexExp = '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';
  infoMsg: boolean;
  isIndirectB2BUnitValidated = false;
  validatedUserName: any;
  bannerMessage: any;

  isCaptchaEnabled$: Observable<boolean> = this.baseSiteService.get().pipe(
    map((baseSite: any) => baseSite?.captchaConfig?.enabled)
  );

  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService,
    private readonly router: RoutingService,
    private readonly user: UserAccountFacade,
    protected baseSiteService: BaseSiteService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    const maxlengthChar = 20;
    const maxlengthSeven = 7;
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountInfoForm = new FormGroup({
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+$'),
        Validators.maxLength(maxlengthChar),
      ]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{5}$|^[0-9]{5}-[0-9]{4}$'),
      ]),
      recaptchaReactive: new FormControl('', [getLastValueSync(this.isCaptchaEnabled$) ? Validators.required : Validators.nullValidator]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegexExp),
      ]),
    });
    this.userSub = this.user.get().subscribe((user) => {
      if (user) {
        this.router.goByUrl('/');
      }
    });
  }

  numberValidation(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateAccountInfo() {
    this.accountInfoForm.markAllAsTouched();
    if (this.accountInfoForm.valid) {
      if (this.isIndirectB2BUnitValidated) {
        this.validateIndirectAccountInAkamai();
      } else {
        if (this.existingUserContinue) {
          this.errorMsg = false;
          const accountInfo = {
            header: 'userInformation',
            form: this.accountInfoForm,
            akamaiRegistered: { registeredAkamaiUser: false }
          };
          this.accountRegService.sectionChange.next(accountInfo);
        }

        delete this.accountInfoForm.value?.recaptchaReactive;
        this.validateIndirectAccountInfo();
      }
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  validateIndirectAccountInfo() {
    this.accountInfoSub = this.accountRegService
      .postValidateIndirectAccountInfo(this.accountInfoForm.get('recaptchaReactive').value, this.accountInfoForm.value)
      .subscribe(
        (res) => {
          if (res.existingUserCheck) {
            this.validatedUserName = { 'userName': res?.userName };
            this.accountInfoForm.addControl('password', new FormControl('', Validators.required))
            this.isIndirectB2BUnitValidated = true;
            this.infoMsg = true;
            this.cdr.detectChanges();
          }
          if (!res.existingUserCheck) {
            this.existingUserContinue = !res.existingUserCheck;
            this.accountInfoForm.addControl('confirmEmail', new FormControl('', [Validators.required, Validators.pattern(this.emailRegexExp)]))
            this.accountInfoForm.addValidators(this.emailMatchValidator);
            this.cdr.detectChanges();
          }
        },
        (error) => {
          if (!!error) {
            this.accountErrors(error);
            this.generateErrors();
          }
        }
      );
  }

  validateIndirectAccountInAkamai() {
    if (this.accountInfoForm.valid) {
      const isRegisteredAkamaiUser = { registeredAkamaiUser: true }
      const formvalue = { ...this.accountInfoForm.value, ...isRegisteredAkamaiUser, ...this.validatedUserName }
      this.accountInfoSub = this.accountRegService
        .postValidateIndirectAccountInAkamai(formvalue)
        .subscribe(
          (res) => {
            this.errorMsg = false;
            const accountInfo = {
              header: 'userInformation',
              form: this.accountInfoForm,
              akamaiRegistered: {
                registeredAkamaiUser: true,
                uuid: res.uuid,
                ...this.validatedUserName
              }
            };
            this.accountRegService.sectionChange.next(accountInfo);
          },
          (error) => {
            if (!!error) {
              this.checkIndirectErrorsFromAkamai(error);
              this.generateErrors()
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  accountErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      if (error?.error?.errors[0].type === 'SanofiAkamaiWebServiceError') {
        this.generateErrorMessages('SanofiAkamaiWebServiceError')
      } else {
        (error?.error?.errors[0].message === 'b2bCustomer.register.accountNumber.invalidType') ?
         this.generateErrorMessages('billToShipToValidationMsg')
         : (error?.error?.errors[0].message === 'b2bCustomer.register.email.exists') ?
            this.generateErrorMessages('emailExists')
            : this.generateErrorMessages('formValidationMsg')
      }
      
    }
  }

  checkIndirectErrorsFromAkamai(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      (error.error.errors[0].message === 'register.janrain.invalid.password') ?
        this.generateErrorMessages('formValidationMsgPassword')
        : this.generateErrorMessages('SanofiAkamaiWebServiceError');
      }
  }

  sendResetPasswordEmail() {
    const email = this.accountInfoForm.get('email').value;
    if (!!email) {
      this.accountRegService.postSendResetPasswordEmail(email).subscribe(
        (res) => {
          this.generateErrorMessages('resetPasswordEmailSentMsg')
          window.scroll(0, 0);
          this.cdr.detectChanges();
        },
        (error) => {
          this.cdr.detectChanges();
        });
    }
  }

  emailMatchValidator(frm: FormGroup) {
    if (!!frm) {
      if (frm.get('email').value !== frm.get('confirmEmail').value) {
        return { emailNotMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  generateErrors() {
    window.scroll(0, 0);
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.globalMessage.add(this.errorMessageText, GlobalMessageType.MSG_TYPE_ERROR);
    this.cdr.detectChanges();
  }

  generateErrorMessages(message) {
    if (message === 'billToShipToValidationMsg') this.billToShipError = true;
    this.bannerMessage = "userRegistration.accountInformation." + message;
    this.errorMsg = true;
  }

  ngOnDestroy() {
    this.accountInfoSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }
}