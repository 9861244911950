<div class="acc-order-details mt-5">
  <div class="d-flex justify-content-between mx-0 mx-sm-4">
    <h3 class="c-heading c-heading-h2">
      {{ 'accountOrders.title' | cxTranslate }}
    </h3>
    <span class="text-fixed-medium color-black"
      >{{ ordersList.length || '' }} Most Recent Orders</span
    >
  </div>
  <app-loader *ngIf="!isLoadingDone"></app-loader>
  <div
    class="text-center no-orders"
    *ngIf="!!ordersList && ordersList.length === 0 && isLoadingDone"
  >
    {{ 'accountOrders.no_orders_found' | cxTranslate }}
  </div>

  <div *ngIf="!!ordersList && ordersList.length > 0 && isLoadingDone">
    <!--table starts-->
    <table
      aria-describedby="creditsTable"
      class="credits-table c-table c-table--branded d-none d-lg-table"
    >
      <thead>
        <tr>
          <th
            scope="col"
            class="date-col"
            (click)="sortColumn($event, 'placed', ordersList, 'Date')"
          >
            {{ 'accountOrders.date_label' | cxTranslate }}&nbsp;
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="sortColumn($event, 'code', ordersList, 'normal')"
          >
            {{ 'accountOrders.order_no_label' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="sortColumn($event, 'status', ordersList, 'normal')"
          >
            {{ 'accountOrders.order_status_label' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="
              sortColumn($event, 'purchaseOrderNumber', ordersList, 'normal')
            "
          >
            {{ 'accountOrders.order_po_num_label' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="ship-to-col"
            (click)="sortColumn($event, 'shipToId', ordersList, 'normal')"
          >
            {{ 'accountOrders.order_ship_to_label' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ordersList; let i = index">
          <td>
            <span>{{ data.placedDateObj | date : dateFormat }}</span>
          </td>
          <td class="td-long-content">
            <a
              [routerLink]="['/my-account/order', data.guid]"
              href="javascript: void(0);"
            >
              {{ data.guid }}</a
            >
          </td>
          <td class="status-txt">
            <span>{{ data.statusDisplay }} </span>
          </td>
          <td>
            <span class="po-size">{{
              getPONumber(data.purchaseOrderNumber)
            }}</span>
          </td>
          <td class="td-long-content-shipto">
            <span *ngIf="!!data?.shipToName">{{ data.shipToName }}</span>
            <br *ngIf="!!data?.shipToName" />
            <span>{{ data.shipToId }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <table
      aria-describedby="ordersTable"
      class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
    >
      <tr *ngFor="let data of ordersList; let i = index">
        <td class="header">
          {{ 'accountOrders.date_label' | cxTranslate }}
        </td>
        <td>
          {{ data?.placedDateObj | date : dateFormat }}
        </td>
        <td class="header">
          {{ 'accountOrders.order_no_label' | cxTranslate }}
        </td>
        <td>
          <a
            [routerLink]="['/my-account/order', data?.guid]"
            href="javascript: void(0);"
          >
            {{ data?.code }}</a
          >
        </td>
        <td class="header">
          {{ 'accountOrders.order_status_label' | cxTranslate }}
        </td>
        <td class="status-txt">{{ data?.statusDisplay }}</td>
        <td class="header">
          {{ 'accountOrders.order_po_num_label' | cxTranslate }}
        </td>
        <td
          class="po-size"
          [ngClass]="{
            'po-more-list':
              !!data?.purchaseOrderNumber &&
              data?.purchaseOrderNumber?.length > 20
          }"
        >
          {{ data?.purchaseOrderNumber }}
        </td>
        <td class="header">
          {{ 'accountOrders.order_ship_to_label' | cxTranslate }}
        </td>
        <td [ngClass]="{ last: !!data?.shipToName === true }">
          <span *ngIf="!!data?.shipToName">{{ data?.shipToName }}</span>
          <br *ngIf="!!data?.shipToName" />
          <span>{{ data?.shipToId }}</span>
        </td>
      </tr>
    </table>
  </div>
  <!--table ends-->
  <div
    class="invoice-btngrps"
    *ngIf="!!ordersList && ordersList.length > 0 && isLoadingDone"
  >
    <button
      type="button"
      class="c-button c-button--link c-button--link-primary-lg"
      data-di-id="di-id-41a05d18-6b04a424"
      [routerLink]="['/my-account/orders']"
    >
      {{ 'accountOrders.button_text' | cxTranslate }}
    </button>
  </div>
</div>
