import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  ConverterService,
  OccEndpointsService,
  OccProductSearchAdapter,
  SearchConfig,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomOccProductSearchAdapter extends OccProductSearchAdapter {
  emulateSub: Subscription;

  constructor(
    protected http: HttpClient,
    protected OccEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected userService: UserAccountFacade,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    super(http, OccEndpointsService, converterService);
  }
  protected getSearchEndpoint(
    query: string,
    searchConfig: SearchConfig
  ): string {
    let userId = 'current';
    //asm status and id taken from app.component.ts
    const asmEnabled = localStorage.getItem('asmEnabled');
    const asmUserId = localStorage.getItem('asmUserId');
    if (asmEnabled) {
      if (asmUserId) {
        userId = asmUserId;
      }
    } else {
      userId = 'current';
    }
    return this.occEndpoints.buildUrl('productSearch', {
      urlParams: { userId: userId },
      queryParams: { query, ...searchConfig },
    });
  }
}
