import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomRegistrationService } from '../custom-registration.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  header: string;
  formData: any;
  creditInvoice: string; 
  dummyData: any;
  akamaiRegistered: any;

  constructor(private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.header = 'accountInformation';
    this.creditInvoice = 'invoiceTable';

    this.accountRegService.sectionChange.subscribe((sectionDetails) => {
      this.header = sectionDetails.header;
      if (!!sectionDetails?.form) {
        this.formData = sectionDetails.form;
        this.akamaiRegistered = sectionDetails?.akamaiRegistered;
      }
      if (sectionDetails.header === 'confirmation') {
        this.formData = sectionDetails?.msgData;
      }
      this.cdr.detectChanges();
    });
  }

}