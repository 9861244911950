export const CATEGORY = '/catalogs/categories/1';
export const VALIDATE_EMAIL = '/forgotten-username-password/validate-email';
export const VALIDATE_PASSWORD_ANSWER =
  '/forgotten-username-password/validate-password-answer';
export const RESET_PASSWORD = '/forgotten-username-password/reset-password';
export const USER_NAME = '/users/current';
export const USER_ACCOUNT = '/users/';
export const ADD_CARD = '/users/current/paymentdetails';
export const GET_INVOICES = '/my-account/invoices';
export const DOWNLOAD_INVOICE_CREDIT_PDF = 'my-account/invoicepdf';
export const GET_CARD_DETAILS = '/my-account/payment-info';
export const POST_CARD_DETAILS = '/my-account/creditReview';
export const SUBMIT_CREDIT = '/my-account/payment-confirmation-credit';
export const GET_PROVINCES = '/countries/CA/regions';
export const GET_PRACTICEORFACILITYTYPE = '/enumeration';
export const POST_ACCOUNT_REGISTRATION_DETAILS = '/account/register';
export const SEND_RESET_PASSWORD_EMAIL = '/customer-register/reset-password';

export const VALIDATE_ACCOUNT_INFORMATION_USER_REGISTRATION =
  '/customer-register/account-information';

export const VALIDATE_INDIRECT_ACCOUNT_INFORMATION_USER_REGISTRATION =
  '/customer-register/indirect-account-information';

export const VALIDATE_INDIRECT_ACCOUNT_INFORMATION_IN_AKAMAI =
  '/customer-register/indirect-account-information-validation';

export const POST_USER_REGISTRATION_INFO =
  '/customer-register/customer-information';

export const GET_CARD = '/my-account/payment-info?';

export const GET_OPEN_STATEMENTS = '/my-account/statements';
export const GET_ALL_STATEMENTS_DATA_RANGE = '/my-account/statementsDisplay';

export const GET_SEARCH_HISTORY = '/users/';

export const GET_ACCOUNT_ID = '/users/current/b2bUnits';

export const SUBMIT_CREDIT_CARD_PAYMENT = '/my-account/ccinvoice-payment';
export const GET_ORDER_HISTORY = '/users/';
export const CATALOGS = '/catalogs/';
export const CATALOGSTYPE = '/materialProduct/get-material/';
export const FAQ = '/frequently-asked-questions';
export const HELP_CENTER = '/help-center/search';