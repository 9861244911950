<!-- <app-overview-important-banner></app-overview-important-banner> -->

<section class="col-12 col-xs-12 col-sm-7 col-md-8 left-section">
  <div class="c-widget c-widget--no-borders d-flex d-sm-none">
    <h3 class="c-widget-title c-heading c-heading-h2">{{ "accountOverview.myAccount_label" | cxTranslate }}</h3>
    <div class="c-widget-divider"></div>
    <div class="d-flex justify-content-between">
      <div class="c-widget--my-account-description">
        {{ "accountOverview.account_label" | cxTranslate }} &nbsp;
        <strong>{{ accountNumber }}</strong>
      </div>
      <div>
        <a
          class="c-link"
          data-di-id="di-id-9b8eeaed-3b366785"
          href="javascript:;"
          (click)="navigateToAccountDetails()"
        >
          {{ "accountOverview.view_label" | cxTranslate }}
        </a>
      </div>
    </div>
  </div>
  <app-order-list></app-order-list>


<!-- <div class="acc-order-details">
    <div class="row account-overview-header">
      <div class="col-xs-4 col-sm-5 col-md-7">
        <h3 class="reservation-details">
          {{ "accountOverview.FluReservations.title" | cxTranslate }}
        </h3>
      </div>
    </div>
    <hr />
    <app-flu-content></app-flu-content>
    <section class="row">
      <section class="col-12 col-sm-12 col-md-12">
        <div class="col-xs-4 col-sm-12 col-md-7">
          <h3 class="monograph-Title">
            {{ "accountOverview.product_monograph.title" | cxTranslate }}
          </h3>
        </div>
        <hr class="hr-Style" />
        <div *ngIf="productCodes">
          <div
            class="panel-group"
            id="accr-new"
            *ngFor="let prod of prodcutsInfo"
          >
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="firstLevel-loc product-text-img">
                  <label class="prod-Name"
                    >{{ prod?.name
                    }}<a
                      href="{{
                        prod.productDocuments?.productDocument[0]?.url
                      }}"
                      target="_blank"
                      class="pdf-Style"
                      data-di-id="di-id-61494698-8d975ab0"
                    >
                      <img src="..\..\assets\icons\pdf.png" alt="pdf" />
                      {{
                        "accountOverview.Financials.pdf_label" | cxTranslate
                      }}</a
                    >
                    <div class="sub-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(prod?.description)"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>  -->
  <!--iphone news updates-->
  <!-- <section class="col-12 displayBanner">
    <app-order-products></app-order-products>
  </section>
  <div class="line-divider"></div> -->
  <!-- <section class="col-12 displayBanner">
    <app-news-updates></app-news-updates>
  </section>
  <section class="col-12 displayBanner banner-Align">
    <app-flu-dashboard-banner
      [bannerComponent]="compNameAdBanner1"
    ></app-flu-dashboard-banner>
  </section>
  <section class="col-12 displayBanner banner-Align">
    <app-flu-dashboard-banner
      [bannerComponent]="compNameAdBanner2"
    ></app-flu-dashboard-banner>
  </section>
  <section class="col-12 displayBanner banner-Align">
    <app-flu-dashboard-banner
      [bannerComponent]="compNameAdBanner3"
    ></app-flu-dashboard-banner>
  </section> -->
</section>

<!--end-->
