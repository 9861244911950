<div
  id="location-select"
  class="mt-4 pt-2 pt-md-4 ml-2 text-md-right"
  *ngIf="showLocStrip"
>
  <i class="fa fa-map-marker-alt font-n"></i>
  <span class="px-3 text-fixed-medium"
    >{{ usergetDataObj?.name }} {{ "(" + usergetDataObj?.uid + ")" }}</span
  >
</div>
