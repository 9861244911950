import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, OccEndpointsService } from '@spartacus/core';
import { DisplayImgComponent } from '../display-img/display-img.component';
import { ResourcesService } from '../resources.service';
declare const require: any;

@Component({
  selector: 'app-patient-education',
  templateUrl: './patient-education.component.html',
  styleUrls: ['./patient-education.component.scss'],
})
export class PatientEducationComponent implements OnInit, OnDestroy {
  catalogsList: any = [];
  baseUrl = this.occEndPoint.getBaseUrl({
    baseUrl: true,
    prefix: false,
    baseSite: false,
  });
  fileUrl: any;
  defaultImg = '../../../assets/img/sanofishoppe_missing_product.png';
  catalogsDataSubscription: any;

  constructor(
    private readonly service: ResourcesService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly cd: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
    private readonly lang: LanguageService
  ) {}

  ngOnInit() {
    this.lang.getActive().subscribe((res) => {
      this.getCatalogsList();
    });
  }

  getCatalogsList() {
    this.service.getCatalogTypes('m1');
    this.catalogsDataSubscription = this.service.catalogs$.subscribe((res) => {
      if (res !== null) {
        if (res && res.categoryData && res.categoryData.length) {
          this.catalogsList = res.categoryData[0].products;
        }
        this.cd.detectChanges();
      }
    });
  }
  ngOnDestroy() {
    this.catalogsDataSubscription.unsubscribe();
  }

  downloadFile(downloadUrl, name) {
    if (downloadUrl && downloadUrl.url) {
      const pdfUrl = `${this.baseUrl}${downloadUrl.url}`;
      const pdfName = `${name}.pdf`;
      const FileSaver = require('file-saver');
      FileSaver.saveAs(pdfUrl, pdfName);
    }
  }

  openNewModalPopup(image) {
    const modalRef = this.modalService.open(DisplayImgComponent, {
      size: 'md',
      scrollable: false,
      windowClass: 'patient-edu-modal',
    });
    const img = `${this.baseUrl}${image.url}`;
    modalRef.componentInstance.image = img;
  }

  getImage(image) {
    if (image && image.url) {
      return `${this.baseUrl}${image.url}`;
    } else {
      return this.defaultImg;
    }
  }
}

