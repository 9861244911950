import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { I18nModule } from "@spartacus/core";
import { StockAvailabilityComponent } from "./stock-availability.component";

@NgModule({
  imports: [CommonModule, NgbModule, I18nModule],
  exports: [StockAvailabilityComponent],
  declarations: [StockAvailabilityComponent]
})
export class StockAvailabilityModule {}