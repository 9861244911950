<div class="faq-container mb-4 pl-md-4">
  <div class="w-100">
    <div class="searched">
      <p *ngIf="searchResults?.length > 0" class="m-0">
        {{ "plp.searchResultFor" | cxTranslate }}
        <span class="font-bold">"{{ searchInput }}"</span>
      </p>
      <h3 *ngIf="searchResults?.length < 1">
        <span class="font-bold">{{ "plp.noResultForHelp" | cxTranslate }}</span>
      </h3>
      <br />

      <div class="faq-item" *ngFor="let itemSearched of searchResults">
        <div class="item-child-container show">
          <div class="item-child">
            <div class="item-child-question">
              <h5 class="text-fixed-medium m-0">
                {{ itemSearched?.question }}
              </h5>
              <div class="toggle-item">
                <button
                  class="child-btn"
                  (click)="toggleChild($event)"
                ></button>
              </div>
            </div>
            <div class="item-child-answer" id="{{ itemSearched?.code }}">
              <div [innerHTML]="itemSearched?.answer"></div>
              <app-accordian-answers
                [answers]="itemSearched?.answer"
              ></app-accordian-answers>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="searchResults?.length <= 0">
        <p>
          {{ "plp.sorrySearchFor" | cxTranslate }}
          <span class="font-bold">"{{ searchInput }}"</span>
          {{ "plp.didNotMatch" | cxTranslate }}
          <span class="c-link" [routerLink]="['/faq']">{{
            "plp.helpCenter" | cxTranslate
          }}</span
          >.<br /><br />

          {{ "plp.tryDifferentSearch" | cxTranslate }}
          <span class="c-link" [routerLink]="['/Open-Catalogue/c/1']">{{
            "plp.products" | cxTranslate
          }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
