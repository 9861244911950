import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomSearchService {
  public $helpResults = new Subject<any>();
  public $helpValue = new BehaviorSubject<any>(null);

  constructor(private readonly baseService: BaseService) {}

  getSearchResults(userId) {
    return this.baseService.get(`/users/${userId}/recent-search`);
  }
  saveKeyword(params, userId) {
    this.baseService
      .post(`/users/${userId}/recent-search`, params)
      .subscribe((res) => {});
  }
  getHelpResults(query) {
    this.baseService.getHelp(`/help-center/search`, query).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.$helpResults.next(res);
      }
    });
  }
  updateHelpValue(value) {
    this.$helpValue.next(value);
  }
}
