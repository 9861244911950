<div class="container my-4 pt-4">
  <div class="row" *ngIf="displaySection">
    <div class="col-md-8 pb-2 u-border-right-grey pr-md-4">
      <img *ngIf="
          !productDetails?.images ||
          productDetails?.images == undefined ||
          !productDetails?.images?.PRIMARY
        " class="img-fluid mb-2 d-sm-none" src="{{ fallbackImage }}" [alt]="productDetails?.summary" />
      <cx-media class="img-fluid mb-2 d-sm-none" [container]="productDetails?.images?.PRIMARY" format="product"
        [alt]="productDetails?.summary"></cx-media>
      <h1 class="c-heading c-heading-h2 mb-3">
        {{ productDetails?.name }}
      </h1>
      <p class="text-fixed-medium" [innerHTML]="description"></p>
      <br />
      <div class="text-fixed-medium">
        <p *ngIf="productDetails?.batchInfo?.batchNumber">
          <b>{{ 'plp.batchNumber' | cxTranslate }} </b>
          <span>{{ productDetails?.batchInfo?.batchNumber }}</span>
        </p>
        <p *ngIf="productDetails?.batchInfo?.batchNumber">
          <b>{{ 'plp.expiryDate' | cxTranslate }} </b>
          <span>{{ productDetails?.batchInfo?.expiryDate }}</span>
        </p>
        <p *ngIf="productDetails?.batchInfo?.batchNumber">
          <b>{{ 'plp.batchInfo' | cxTranslate }}</b>
        </p>
        <p>
          <b>{{ 'plp.productNumber' | cxTranslate }} </b>
          <span>{{ productDetails?.sapEan }}</span>
        </p>
        <p *ngIf="productDetails?.actualPotency">
          <b>{{ 'plp.actualPotency' | cxTranslate }} </b>
          <span>{{ productDetails?.actualPotency }}</span>
        </p>
        <div class="" *ngIf="productDetails?.actualPotency">
          <span *ngIf="
              productDetails?.iuPrice == undefined ||
              productDetails?.iuPrice?.value === undefined
            "><b>{{ 'plp.iuPrice' | cxTranslate }} </b>
            -
          </span>
          <span *ngIf="
              productDetails?.iuPrice &&
              productDetails?.iuPrice?.value !== undefined
            ">
            <b>{{ 'plp.iuPrice' | cxTranslate }} </b>
            {{ productDetails?.iuPrice?.value | currency }}
          </span>
        </div>

        <div *ngIf="productDetails?.showStockAvailability">
          <div class="c-stock-level mb-2 mt-4 d-flex">
            Stock availability:&nbsp;&nbsp;
            <app-stock-availability [productDetails]="productDetails"></app-stock-availability>
          </div>
        </div>
        <!-- Availability text -->
        <p class="c-text color-confirm" *ngIf="productDetails?.availabilityText">
          {{ productDetails?.availabilityText }}
        </p>
        <hr class="line" />

        <div *ngIf="userDetails !== undefined">
          <div *ngIf="!productDetails?.flu">
            <div class="d-flex justify-content-around justify-content-md-between flex-wrap mt-2">
              <div class="mb-2">
                <h5 *ngIf="productDetails?.actualPotency; else notActualPotency"
                  class="mb-2 text-center font-weight-bold color-grey-dark">
                  {{ 'plp.unitPrice' | cxTranslate }}
                </h5>
                <ng-template #notActualPotency>
                  <h5 class="mb-2 text-center font-weight-bold color-grey-dark">
                    {{ 'plp.yourPrice' | cxTranslate }}
                  </h5>
                </ng-template>
                <p class="c-heading c-heading-h3">
                  <span *ngIf="
                      productDetails?.price == undefined ||
                      productDetails?.price?.value === undefined
                    ">-</span>
                  <span *ngIf="
                      productDetails?.price &&
                      productDetails?.price?.value !== undefined
                    ">
                    {{ productDetails?.price?.value | currency }}
                  </span>
                </p>
              </div>
              <div class="mb-2 units-area">
                <div *ngIf="
                    !productDetails?.discontinued && productDetails?.available
                  ">
                  <h5 class="mb-2 text-center font-weight-bold color-grey-dark">
                    {{ 'plp.units' | cxTranslate }}
                  </h5>

                  <div class="unit-area d-flex flex-nowrap align-items-center justify-content-center">
                    <span id="{{ 'unitMinus' + productDetails?.code }}" class="d-none"></span>
                    <span id="{{ 'unitMinusDisable' + productDetails?.code }}" class="d-none"></span>
                    <span id="{{ 'unitPlus' + productDetails?.code }}" class="d-none"></span>
                    <span id="{{ 'unitPlusDisable' + productDetails?.code }}" class="d-none"></span>
                    <button id="{{ 'minusIcon' + productDetails?.code }}" class="c-count-change-btn disabled"
                      aria-hidden="true" (click)="
                        performQuantityComputation(
                          2,
                          productDetails?.code,
                          productDetails?.quantityPerUnit
                        )
                      ">
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    </button>
                    <input type="number" class="prod-quantity text-center" value="1" (keyup)="
                        performQuantityComputation(
                          null,
                          productDetails?.code,
                          productDetails?.quantityPerUnit
                        )
                      " id="{{ 'prodQuantity' + productDetails?.code }}" min="1" max="9999"
                      onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                    <button id="{{ 'plusIcon' + productDetails?.code }}" class="c-count-change-btn" aria-hidden="true"
                      (click)="
                        performQuantityComputation(
                          1,
                          productDetails?.code,
                          productDetails?.quantityPerUnit
                        )
                      ">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="
                  !productDetails?.discontinued &&
                  productDetails?.available &&
                  !productDetails?.flu
                " class="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-0 my-4 my-sm-2 d-flex align-items-end button-area">
                <span *ngIf="
                    productDetails?.stockAvailableStatus == 'LIMITED' &&
                    productDetails?.allowLimitedStockProducts !== true
                  " class="c-text color-error" disabled>
                  {{ 'plp.unavailableToOrderText' | cxTranslate }}
                </span>
                <button *ngIf="
                    (!productDetails?.price ||
                      productDetails?.price?.value == undefined ||
                      productDetails?.price?.value == 0 ||
                      productDetails?.price?.value == 0.0) &&
                    !(
                      productDetails?.stockAvailableStatus == 'LIMITED' &&
                      productDetails?.allowLimitedStockProducts !== true
                    )
                  " class="c-button c-button--primary w-100" disabled>
                  {{ 'plp.unavailableToOrder' | cxTranslate }}
                </button>

                <ng-template #popOverLimited>
                  <div class="c-popover-black">
                    <p *ngIf="productDetails?.stockAvailableStatus == 'LOW'">
                      Low stock<br /><span>Stock is Limited</span>
                    </p>
                    <p *ngIf="productDetails?.stockAvailableStatus == 'NORMAL'">
                      Normal stock<br /><span>Stock is available</span>
                    </p>
                    <p *ngIf="
                        productDetails?.stockAvailableStatus == 'LIMITED' &&
                        productDetails?.allowLimitedStockProducts !== true
                      ">
                      Stock Unavailable<br /><span>Please call Customer Support</span>
                    </p>
                    <p *ngIf="
                        productDetails?.stockAvailableStatus == 'LIMITED' &&
                        productDetails?.allowLimitedStockProducts == true
                      ">
                      Stock is very Limited <br />
                    </p>
                  </div>
                </ng-template>
                <button class="c-button c-button--primary w-100" data-di-id="di-id-2fea5a72-b2e23002"
                  [ngbPopover]="popTitleSuccess" placement="bottom" triggers="manual" #p2="ngbPopover"
                  placement="bottom" popoverClass="pdp-itemsuccess-msg" [closeDelay]="500"
                  (click)="addToCart(productDetails?.code, p2)" *ngIf="
                    !productDetails?.discontinued &&
                    productDetails?.available &&
                    productDetails?.price?.value != undefined &&
                    productDetails?.price?.value != 0 &&
                    productDetails?.price?.value != 0.0 &&
                    (productDetails?.stockAvailableStatus !== 'LIMITED' ||
                      (productDetails?.stockAvailableStatus == 'LIMITED' &&
                        productDetails?.allowLimitedStockProducts == true))
                  ">
                  <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                  <span *ngIf="buttonName === 'Add to cart'" class="text-nowrap">{{ 'addToCartItems.addCart' |
                    cxTranslate }}</span>
                  <div *ngIf="
                      buttonName === 'Add to cart' &&
                      productDetails?.stockAvailableStatus == 'LIMITED'
                    " style="
                      background-color: transparent;
                      width: 100%;
                      height: 100%;
                      position: absolute;
                    " (mouseenter)="p2.open()" [ngbPopover]="popOverLimited" #p2="ngbPopover" placement="top"
                    (mouseleave)="p2.close()"></div>
                  <span *ngIf="buttonName === 'Login to order'" class="text-nowrap">{{ 'addToCartItems.loginToOrder' |
                    cxTranslate }}</span>
                  <span *ngIf="buttonName === 'Reserve now'" class="text-nowrap reserve-now-button">{{
                    'addToCartItems.reserveNow' | cxTranslate }}</span>
                </button>
              </div>
            </div>

            <div *ngIf="!productDetails?.discontinued && productDetails?.available"
              class="sap-allocation-area u-hide-if-empty">
              <div *ngIf="productDetails?.sapAllocation === true">
                <b class="prod-allocation">{{
                  'plp.productAllocation' | cxTranslate
                  }}</b>
                <p class="allocation-p">
                  {{ 'plp.allocationDetails' | cxTranslate }}
                </p>
              </div>
            </div>
          </div>

          <section *ngIf="productDetails?.discontinued || !productDetails?.available">
            <section *ngIf="productDetails?.discontinued && productDetails?.available">
              <p class="text-center product-discontinued-txt1">
                {{ 'plp.discontinued' | cxTranslate }}
              </p>
            </section>
            <section *ngIf="productDetails?.discontinued && !productDetails?.available">
              <p class="text-center product-discontinued-txt1">
                {{ 'plp.discontinued' | cxTranslate }}
              </p>
            </section>
            <section *ngIf="
                !productDetails?.discontinued && !productDetails?.available
              ">
              <section class="text-center1">
                <b class="text-center product-discontinued-txt1" *ngIf="productDetails?.availabilityText">{{
                  productDetails?.availabilityText }}</b>
                <b class="text-center product-discontinued-txt1" *ngIf="!productDetails?.availabilityText">{{
                  'plp.productUnavailable' | cxTranslate }}</b>
              </section>
            </section>
          </section>

          <section class="added-success-container">
            <ng-template #popTitleSuccess>
              <div class="cart-value-added">
                <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                  alt="success" />
                <span class="confirmation-itemsadded" *ngIf="!flagRemoved; else NA">{{ itemAddedQuantity }}
                  {{ 'addToCartItems.addEntrySuccess' | cxTranslate }}</span>
                <ng-template #NA>
                  <span class="confirmation-itemsadded">{{ itemAddedQuantity }}
                    {{
                    'addToCartItems.updateEntrySuccess' | cxTranslate
                    }}</span>
                </ng-template>
              </div>
            </ng-template>
          </section>

          <section class="flu-class" *ngIf="
              productDetails.flu &&
              productDetails?.available &&
              !productDetails?.discontinued
            ">
            <section>
              <p class="contact-info">
                {{ 'plp.productAllocationInfo1' | cxTranslate
                }}<b> {{ 'plp.emergencycontact' | cxTranslate }}</b>{{ 'plp.productAllocationInfo2' | cxTranslate }}
              </p>
            </section>
            <section class="col-md-4 col-sm-4 col-xs-12 button-area flu-button-area" *ngIf="
                productDetails.flu &&
                productDetails?.available &&
                !productDetails?.discontinued
              ">
              <button class="c-button c-button--primary login-btn ml10 flu-button" data-di-id="di-id-2fea5a72-b2e23002 "
                [ngbPopover]="popTitleSuccess" placement="bottom" triggers="manual" #p2="ngbPopover" placement="bottom"
                (click)="addToCart(productDetails?.code, p2)">
                <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                <span *ngIf="buttonName === 'Add to cart'">{{
                  'addToCartItems.addCart' | cxTranslate
                  }}</span>
                <span *ngIf="buttonName === 'Login to order'">{{
                  'addToCartItems.loginToOrder' | cxTranslate
                  }}</span>
                <span class="reserve-now-button" *ngIf="buttonName === 'Reserve now'">{{ 'addToCartItems.reserveNow' |
                  cxTranslate }}</span>
              </button>
            </section>
          </section>
        </div>

        <!-- The End -->
        <section>
          <section *ngIf="userDetails == undefined" class="col-md-4 col-sm-4 col-xs-12 button-area">
            <button type="button" class="c-button c-button--primary my-4" (click)="goToLogin()">
              <span *ngIf="buttonName === 'Add to cart'">{{
                'addToCartItems.addCart' | cxTranslate
                }}</span>
              <span *ngIf="buttonName === 'Login to order'">{{
                'addToCartItems.loginToOrder' | cxTranslate
                }}</span>
              <span class="reserve-now-button" *ngIf="buttonName === 'Reserve now'">{{ 'addToCartItems.reserveNow' |
                cxTranslate }}</span>
            </button>
          </section>

          <section *ngIf="userDetails !== undefined">
            <section *ngIf="!productDetails?.discontinued && productDetails?.available"
              class="products-accordion clear-fix pt2 u-hide-if-empty-child">
              <section class="panel-group">
                <section class="panel panel-default" *ngIf="productDetails?.volumePrices?.length > 0">
                  <section class="panel-heading panel-endborder">
                    <section class="first-level-acc">
                      <label><img src="../../assets/icons/price-master.svg" class="accordion-img" alt="" />
                        {{ 'plp.volumePricing' | cxTranslate }}</label>
                      <span data-toggle="collapse" data-parent="#accordion" href="#pdp-list1" class="acc-plus-icon fa"
                        aria-expanded="panelVolumeExpanded" (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="
                          panelVolumeExpanded ? 'fa-minus' : 'fa-plus collapsed'
                        "></span>
                    </section>
                  </section>
                  <section id="pdp-list1" class="panel-collapse collapse" aria-expanded="panelVolumeExpanded"
                    (click)="panelVolumeExpanded = !panelVolumeExpanded" [ngClass]="panelVolumeExpanded ? 'in' : ''">
                    <section class="panel-body">
                      <section id="vol-price-pdp">
                        <table aria-describedby="pdpTable" class="c-table c-table--branded mt-4">
                          <tbody>
                            <tr *ngFor="
                                let prices of productDetails?.volumePrices;
                                let i = index
                              ">
                              <td>
                                {{ prices?.minQuantity }} -
                                {{ prices?.maxQuantity }}
                                {{ 'plp.units' | cxTranslate }}:
                              </td>
                              <td>{{ prices?.value | currency }}</td>
                              <td>
                                <span *ngIf="i > 0"><span class="text-success text-fixed-legal text-uppercase">{{
                                    'plp.save' | cxTranslate }}</span>&nbsp;
                                  <span class="font-bold">{{
                                    productDetails?.volumePrices[0].value -
                                    productDetails?.volumePrices[i].value
                                    | currency
                                    }}</span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section class="products-accordion clear-fix pt2 u-hide-if-empty-child">
            <section class="panel-group">
              <!-- update2 -->
              <section class="panel panel-default" *ngIf="productDetails?.productUpdates">
                <section class="panel-heading">
                  <section class="first-level-acc">
                    <label><img src="../../assets/icons/info.png " class="accordion-img" alt=" " />{{
                      'plp.productUpdate' | cxTranslate }}
                    </label>
                    <span data-toggle="collapse " data-parent="#accordion " href="#pdp-list3 " class="acc-plus-icon fa"
                      aria-expanded="panelProductExpanded " (click)="panelProductExpanded = !panelProductExpanded"
                      [ngClass]="
                        panelProductExpanded ? 'fa-minus' : 'fa-plus collapsed'
                      "></span>
                  </section>
                </section>
                <section id="pdp-list3 " class="panel-collapse collapse" aria-expanded="panelProductExpanded "
                  (click)="panelProductExpanded = !panelProductExpanded" [ngClass]="panelProductExpanded ? 'in' : ''">
                  <section class="panel-body productupdate-p">
                    {{ productDetails?.productUpdates }}
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </div>
    </div>
    <aside aria-label="Product Details" class="col-md-4 pl-md-4">
      <div class="d-none d-sm-block mb-4">
        <a *ngIf="productDetails?.images; else noImage" (click)="openImage(content)" class="d-none d-sm-block">
          <img *ngIf="
              !productDetails?.images ||
              productDetails?.images == undefined ||
              !productDetails?.images?.PRIMARY
            " class="img-fluid mb-2 d-sm-none" src="{{ fallbackImage }}" [alt]="productDetails?.summary" />
          <cx-media *ngIf="productDetails?.images && productDetails?.images?.PRIMARY" class="img-fluid"
            [container]="productDetails?.images?.PRIMARY" format="product" [alt]="productDetails?.summary"></cx-media>
        </a>
        <ng-template #noImage>
          <a><img class="img-fluid" [src]="fallbackImage" alt="" /></a>
        </ng-template>
        <ng-template #content let-modal>
          <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false" *ngIf="
              productDetails?.images != undefined ||
              productDetails?.images?.length > 0
            ">
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img *ngIf="
                    !productDetails?.images ||
                    productDetails?.images == undefined ||
                    !productDetails?.images?.PRIMARY
                  " class="img-fluid mb-2 d-sm-none" src="{{ fallbackImage }}" [alt]="productDetails?.summary" />
                <cx-media *ngIf="
                    productDetails?.images && productDetails?.images?.PRIMARY
                  " class="cx-product-image carousel-pdp" [container]="productDetails?.images?.PRIMARY"
                  format="product" [alt]="productDetails?.name">
                </cx-media>
                <div class="xzoom-thumbs">
                  <a class="item">
                    <img *ngIf="
                        !productDetails?.images ||
                        productDetails?.images == undefined ||
                        !productDetails?.images?.PRIMARY
                      " class="img-fluid mb-2 d-sm-none" src="{{ fallbackImage }}" [alt]="productDetails?.summary" />
                    <cx-media *ngIf="
                        productDetails?.images &&
                        productDetails?.images?.PRIMARY
                      " class="cx-product-image carousel-zoom" [container]="productDetails?.images?.PRIMARY"
                      format="product" [alt]="productDetails?.name">
                    </cx-media>
                  </a>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </ng-template>
      </div>

      <div *ngIf="monographLink" aria-label="Product Details Pdf"
        class="col-sm-3 right-navigation float-left resources-section">
        <div>
          <div class="prod-detail-pdfs">
            <h3 class="c-heading c-heading-h4 color-secondary">
              {{ 'plp.resources' | cxTranslate }}
            </h3>
            <a [href]="monographLink" target="_blank" alt="" class="c-link">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
              {{ 'plp.monograph' | cxTranslate }}
            </a>
          </div>
        </div>
      </div>
    </aside>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-md-8 last-section" *ngIf="userDetails !== undefined">
      <div class="yCmsContentSlot productDetailsPageSectionCrossSelling">
        <div class="yCmsComponent productDetailsPageSectionCrossSelling-component">
          <div class="row mt-4">
            <div class="col-md-12" *ngIf="relatedProducts?.length > 0">
              <h2 class="c-heading c-heading-h2">
                {{ 'plp.relatedProducts' | cxTranslate }}
              </h2>
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">
                <app-custom-product-card ngxSlickItem *ngFor="let product of relatedProducts" [product]="product"
                  [iconDisplay]="false">
                </app-custom-product-card>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>