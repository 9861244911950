<section class="row makePaymentView" id="productList-area">
    <aside class="col-md-3 col-sm-3 col-lg-2 col-xs-12 applied-filter pr-0">
      <fieldset id="filter-by">
        <legend class="filterByTitle-main order-history-search">
          {{ "ordersList.searchBy" | cxTranslate }}
        </legend>
        <div class="grey-line"></div>
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.b2BUnit" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="b2BUnitFilter"
            [items]="searchFilters"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            [(ngModel)]="b2BUnitFilter"
          >
          </ng-select>
          <label class="searchbox orders-filter time-frame">
          <input
          type="text"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          aria-autocomplete="both"
          class="input-filter"
          [(ngModel)]="b2BUnitId"
        />
      </label>
        </div>
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.b2BUnitName" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="b2BUnitFilter"
            [items]="searchFilters"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            [(ngModel)]="b2BUnitNameFilter"
          >
          </ng-select>
          <label class="searchbox orders-filter time-frame">
          <input
          type="text"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          aria-autocomplete="both"
          class="input-filter"
          [(ngModel)]="b2BUnitName"
        />
      </label>
        </div>
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.email" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="b2BUnitFilter"
            [items]="searchFilters"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            [(ngModel)]="emailFilter"
          >
          </ng-select>
          <label class="searchbox orders-filter time-frame">
          <input
          type="text"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          aria-autocomplete="both"
          class="input-filter"
          [(ngModel)]="emailId"
        />
      </label>
        </div>
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.cusName" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="b2BUnitFilter"
            [items]="searchFilters"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            [(ngModel)]="cusNameFilter"
          >
          </ng-select>
          <label class="searchbox orders-filter time-frame">
          <input
          type="text"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          aria-autocomplete="both"
          class="input-filter"
          [(ngModel)]="customerName"
        />
      </label>
        </div>
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "ordersList.userName" | cxTranslate
            }}</label>
          </div>
          <ng-select
            name="b2BUnitFilter"
            [items]="searchFilters"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            [(ngModel)]="userNameFilter"
          >
          </ng-select>
          <label class="searchbox orders-filter time-frame">
          <input
          type="text"
          autocomplete="off"
          aria-describedby="initialDescription"
          aria-controls="results"
          aria-autocomplete="both"
          class="input-filter"
          [(ngModel)]="userName"
        />
      </label>
        </div>
        <div class="account-order-filter-btn">
          <button
            type="submit"
            class="btn btn-default blue-button"
            data-di-id="di-id-362f2c77-5f23e741"
            (click)="applyFilter(0)"
          >
            {{ "ordersList.applyFilters" | cxTranslate }}
          </button>
          <button
            type="button"
            id="clearOrderFiltersBtn"
            class="btn btn-default white-button"
            data-di-id="#clearOrderFiltersBtn"
            (click)="clearFilters()"
          >
            {{ "ordersList.clearFilters" | cxTranslate }}
          </button>
        </div>
      </fieldset>
      <div class="line-intersection"></div>
    </aside>
    <section class="col-md-9 col-sm-9 col-lg-10 table-s-section float-right">
      <section class="filter-section">
        <section class="row">
          <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
            <div id="filter-applied" class="applied-filter">
              <hr class="hidden-xs" />
              <div class="row page-section">
                <div class="col-md-5 col-sm-5 hidden-xs filterApply-showing">
                  <span class="total-records">{{ "ordersList.totalRecords" | cxTranslate }}={{totalRecords?.totalResults?totalRecords?.totalResults:0}}</span>
                  <a
                  aria-label="download order history as"
                  id="orderXLS"
                  (click)="generateXls()"
                >
                  <img
                    src="../../../assets/icons/MicrosoftTeams-image.png"
                    alt="pdf"
                    class="ad-pdf xls-img"
                  />
                  <span></span>
                  <span class="pdf-text pl-1">{{
                    "ordersList.xls" | cxTranslate
                  }}</span>
                </a>
                </div>
                <div class="col-md-4 col-sm-5 top-pagination left-pagination">
                  <div class="time-frame">
                    <span id="accPageInfo" class="top-page-id mr-2 pt-1">Page {{totalRecords?.totalPages>0?totalRecords?.currentPage+1:0}} of {{totalRecords?.totalPages?totalRecords?.totalPages:0}}</span>
                    <div class="pagination">
                      <button class="pagination-btns mr-1" [disabled]="!totalRecords ||totalRecords?.currentPage === 0" (click)="paginationPrev()">
                        <span class="chevronIconWhite prev"></span>
                      </button>
                      <button class="pagination-btns" [disabled]="(totalRecords?.currentPage+1) === totalRecords?.totalPages || totalRecords?.totalPages===0 || !totalRecords" (click)="paginationNext()">
                        <span class="chevronIconWhite next"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-5 col-xs-12 sortBy-select px-12">
                  <ng-select
                    name="pageSize"
                    [items]="pageList"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="set-pagination"
                    [(ngModel)]="pageSizeperPage"
                    (ngModelChange)="changePageSize()"
                  ></ng-select>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
  
      <section class="payment-section filter-section">
              <section class="dispaly-refine">
        <ng-template #facetsRwd class="select-refine">
          <section class="modal-class">
            <section class="modal-heading">
              <h2>
                <span class="close-icon" aria-hidden="true" (click)="hide()"
                  >&times;</span
                >
              </h2>
              <h2 class="select-heading">
                {{ "plp.selectRefinement" | cxTranslate }}
              </h2>
            </section>
            <section>
              <fieldset id="filter-by">
                <legend class="filterByTitle-main order-history-search">
                  {{ "ordersList.searchBy" | cxTranslate }}
                </legend>
                <div class="filterByGroup mb-3">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.b2BUnit" | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="b2BUnitFilter"
                    [items]="searchFilters"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="dropdown-credit"
                    [(ngModel)]="b2BUnitFilter"
                  >
                  </ng-select>
                  <label class="searchbox orders-filter time-frame">
                  <input
                  type="text"
                  autocomplete="off"
                  aria-describedby="initialDescription"
                  aria-controls="results"
                  aria-autocomplete="both"
                  class="input-filter"
                  [(ngModel)]="b2BUnitId"
                />
              </label>
                </div>
                <div class="filterByGroup mb-3">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.b2BUnitName" | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="b2BUnitFilter"
                    [items]="searchFilters"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="dropdown-credit"
                    [(ngModel)]="b2BUnitNameFilter"
                  >
                  </ng-select>
                  <label class="searchbox orders-filter time-frame">
                  <input
                  type="text"
                  autocomplete="off"
                  aria-describedby="initialDescription"
                  aria-controls="results"
                  aria-autocomplete="both"
                  class="input-filter"
                  [(ngModel)]="b2BUnitName"
                />
              </label>
                </div>
                <div class="filterByGroup mb-3">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.email" | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="b2BUnitFilter"
                    [items]="searchFilters"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="dropdown-credit"
                    [(ngModel)]="emailFilter"
                  >
                  </ng-select>
                  <label class="searchbox orders-filter time-frame">
                  <input
                  type="text"
                  autocomplete="off"
                  aria-describedby="initialDescription"
                  aria-controls="results"
                  aria-autocomplete="both"
                  class="input-filter"
                  [(ngModel)]="emailId"
                />
              </label>
                </div>
                <div class="filterByGroup mb-3">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.cusName" | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="b2BUnitFilter"
                    [items]="searchFilters"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="dropdown-credit"
                    [(ngModel)]="cusNameFilter"
                  >
                  </ng-select>
                  <label class="searchbox orders-filter time-frame">
                  <input
                  type="text"
                  autocomplete="off"
                  aria-describedby="initialDescription"
                  aria-controls="results"
                  aria-autocomplete="both"
                  class="input-filter"
                  [(ngModel)]="customerName"
                />
              </label>
                </div>
                <div class="filterByGroup mb-3">
                  <div class="filterByTitle">
                    <label for="invoice">{{
                      "ordersList.userName" | cxTranslate
                    }}</label>
                  </div>
                  <ng-select
                    name="b2BUnitFilter"
                    [items]="searchFilters"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="name"
                    bindValue="code"
                    class="dropdown-credit"
                    [(ngModel)]="userNameFilter"
                  >
                  </ng-select>
                  <label class="searchbox orders-filter time-frame">
                  <input
                  type="text"
                  autocomplete="off"
                  aria-describedby="initialDescription"
                  aria-controls="results"
                  aria-autocomplete="both"
                  class="input-filter"
                  [(ngModel)]="userName"
                />
              </label>
                </div>
                <div class="account-order-filter-btn">
                  <button
                    type="submit"
                    class="btn btn-default blue-button"
                    data-di-id="di-id-362f2c77-5f23e741"
                    (click)="applyFilter(0)"
                  >
                    {{ "ordersList.applyFilters" | cxTranslate }}
                  </button>
                  <button
                    type="button"
                    id="clearOrderFiltersBtn"
                    class="btn btn-default white-button"
                    data-di-id="#clearOrderFiltersBtn"
                    (click)="clearFilters()"
                  >
                    {{ "ordersList.clearFilters" | cxTranslate }}
                  </button>
                </div>
              </fieldset>
            </section>
          </section>
        </ng-template>
        <div class="account-order-filter-btn mb-1">
          <button
            class="btn btn-default white-button"
            data-di-id="#refineBtn"
            (click)="refine()"
          >
            {{ "ordersList.refine" | cxTranslate }}
          </button>
        </div>
        <div class="sortBy-select pb-4 mobile-view-select">
          <ng-select
            name="pageSize"
            [items]="pageList"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit set-pagination"
            [(ngModel)]="pageSizeperPage"
            (ngModelChange)="changePageSize()"
          ></ng-select>
        </div>
        <div>
          <span>{{ "ordersList.totalRecords" | cxTranslate }}={{totalRecords?.totalResults?totalRecords?.totalResults:0}}</span>
          <a
          aria-label="download order history as"
          id="orderXLSRWD"
          (click)="generateXls()"
        >
          <img
            src="../../../assets/icons/MicrosoftTeams-image.png"
            alt="pdf"
            class="ad-pdf xls-img"
          />
          <span></span>
          <span class="pdf-text pl-1">{{
            "ordersList.xls" | cxTranslate
          }}</span>
        </a>
        </div>
      </section>
        <table
          aria-describedby="invoicesTable"
          id="ordersTable"
          class="order-list-table table table-striped"
        >
          <thead>
            <tr>
              <th
                scope="col"
                
                class="date-title"
              >
                {{ "ordersList.b2bTableLabel" | cxTranslate }}

              </th>
              <th
                scope="col"
                class="type-title"
                
              >
                {{ "ordersList.b2bNameTableLabel" | cxTranslate }}

              </th>
              <th
                scope="col"
                
                class="order-title"
              >
                {{ "ordersList.b2bCusName" | cxTranslate }}

              </th>
              <th
                scope="col"
               
                class="status-title"
              >
                {{ "ordersList.email" | cxTranslate }}

              </th>
              <th
                scope="col"
                class="po-title"
              >
                {{ "ordersList.userName" | cxTranslate }}

              </th>
              <th scope="col" class="invoice-title">
                {{ "ordersList.userIDdis" | cxTranslate }}
              </th>
              <th scope="col" class="tracking-title">
                {{ "ordersList.viewActionButton" | cxTranslate }}
              </th>
            </tr>
          </thead>
          <tbody >
            <tr
              *ngFor="
                let data of b2bUnitUsers"
            >
              <td>
                <span>{{ data?.unitId }}</span>
              </td>
              <td>
                <span>{{ data?.unitName }}</span>
              </td>
              <td>
                <span>{{ data?.customerName }}</span>
              </td>
              <td>
                <span>{{ data?.email }}</span>
              </td>
              <td>
                <span>{{ data?.userName }}</span>
              </td>
              <td>
                <span>{{ data?.userDisabled?'Y':'N' }}</span>
              </td>
              <td>
            
                  <button type="button" (click)="openCustomerDetails(data?.userName)"
                    class="btn btn-primary float-right btnpaymentinvoicecredit">
                    {{ "ordersList.viewButton" | cxTranslate }}
                  </button>
                
              </td>
            </tr>
          </tbody>
        </table> 
        <section class="text-center" *ngIf="b2bUnitUsers?.length===0">
          {{ "ordersList.noRecords" | cxTranslate }}
        </section>
        <section class="orders-table-orders">
          <div
            class="orders-row-table"
            *ngFor="let data of b2bUnitUsers"
          >
            <div class="column header-column orders-table-col">
              <section class="col-header-rwd">
                <p>
                  <b>{{ "ordersList.b2bTableLabel" | cxTranslate }}</b>
                </p>
                <p>
                  {{ data?.unitId }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.b2bNameTableLabel" | cxTranslate }}
                  </b>
                </p>
                <p>
                  {{ data?.unitName }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.b2bCusName" | cxTranslate }}
                  </b>
                </p>
                <p>
                  {{ data?.customerName }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.email" | cxTranslate }}
                  </b>
                </p>
                <p>
                  {{ data?.email }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.userName" | cxTranslate }}
                  </b>
                </p>
                <p>
                  {{ data?.userName }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.userIDdis" | cxTranslate }}
                  </b>
                </p>
                <p>
                  {{ data?.userDisabled?'Y':'N' }}
                </p>
                <p>
                  <b>
                    {{ "ordersList.viewActionButton" | cxTranslate }}
                  </b>
                </p>
                <p>
                  <button type="button" (click)="openCustomerDetails(data?.userName)"
                  class="btn btn-primary btnpaymentinvoicecredit">
                  {{ "ordersList.viewButton" | cxTranslate }}
                </button>
                </p>
              </section>
            </div>
            <!-- <div class="column desc-column">
              <section>
                <p>
                  {{ data?.unitId }}
                </p>
                <p>
                  {{ data?.unitName }}
                </p>
                <p>
                  {{ data?.customerName }}
                </p>
                <p>
                  {{ data?.email }}
                </p>
                <p>
                  {{ data?.userName }}
                </p>
                <p>
                  {{ data?.userDisabled?'Y':'N' }}
                </p>
            
              </section>
            </div> -->
          </div>
        </section>
  
      </section>
      <section>
        <div class="top-pagination text-center dispaly-refine display-pag">
          <div class="">
            <span id="accPageInfo" class="top-page-id mr-2 pt-1">Page {{totalRecords?.totalPages>0?totalRecords?.currentPage+1:0}} of {{totalRecords?.totalPages?totalRecords?.totalPages:0}}</span>
            <div class="">
              <button class="pagination-btns mr-1" [disabled]="!totalRecords || totalRecords?.currentPage === 0" (click)="paginationPrev()">
                <span class="chevronIconWhite prev"></span>
              </button>
              <button class="pagination-btns" [disabled]="(totalRecords?.currentPage+1) === totalRecords?.totalPages || totalRecords?.totalPages===0 || !totalRecords" (click)="paginationNext()">
                <span class="chevronIconWhite next"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
  
      <div class="line-intersection mobile-view"></div>
    </section>
  </section>
  