import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { ResourcesBannerModule } from '../shared/modules/resources-banner/resources-banner.module';
import { BrowseByTopicComponent } from './browse-by-topic/browse-by-topic.component';
import { DisplayImgComponent } from './display-img/display-img.component';
import { HealthcareProviderInformationComponent } from './healthcare-provider-information/healthcare-provider-information.component';
import { PatientEducationComponent } from './patient-education/patient-education.component';
import { translationChunksConfig } from '@spartacus/assets';
import {
    AuthGuard, CmsConfig,
    ConfigModule,
    I18nModule,
    provideConfig
} from '@spartacus/core';
@NgModule({
  declarations: [PatientEducationComponent, HealthcareProviderInformationComponent, BrowseByTopicComponent, DisplayImgComponent],
  imports: [
    CommonModule,
    ResourcesBannerModule,
    FluDashboardBannerModule,
    NgSelectModule,
    FormsModule,
    I18nModule,
      ConfigModule.withConfig({
          cmsComponents: {
              SanofiPatientEducationHorizontalComponent: {
                  component: PatientEducationComponent,
                  guards: [AuthGuard],
              },
              SanofiPatientEducationVerticalComponent: {
                  component: BrowseByTopicComponent,
                  guards: [AuthGuard]
              },
          },
      } as CmsConfig),

  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class ToolsAndResourcesModule { }
