import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;
  userSub: Subscription;

  constructor(
    private _windowRef: WindowRef,
    private user: UserAccountFacade
  ) {
    this.window = this._windowRef.nativeWindow;
  }

  private pushData(obj) {
    if (obj) {
      this.window.dataLayer.push({ ecommerce: null });
      this.window.dataLayer.push(obj);
    }
  }

  // ** View item list (PLP)
  viewListItemsDataLayer(data): void {
    if (data) {
      let entryItems = [];
      let cat;
      data?.categories ? (cat = data?.categories[0]?.name) : (cat = '0');
      data?.products.forEach((entry) => {
        const entryItem = {
          item_id: entry?.code,
          item_name: entry?.name,
          affiliation: ' 0',
          coupon: '0',
          currency: entry?.price?.currencyIso,
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_list_id: '0',
          item_list_name: '0',
          item_variant: '0',
          price: entry?.price?.value,
          quantity: 1,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_item_list',
        interaction_type: 'ecommerce',
        process_type: 'standard process',
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // ** Add to Cart
  addCartDataLayer(data, type, quantity, page): void {
    if (data) {
      let entryItems = [];
      let cat;
      if (page !== 'cart item details') {
        data?.entry?.product?.categories?.length > 0
          ? (cat = data?.entry?.product?.categories[0]?.name)
          : (cat = '0');
        const entryItem = {
          item_id: data?.entry?.product?.code,
          item_name: data?.entry?.product?.name,
          affiliation: '0',
          currency: data?.entry?.basePrice?.currencyIso,
          discount: 0,
          index: parseInt(data?.entry?.entryNumber),
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_variant: '0',
          price: data?.entry?.basePrice?.value,
          quantity: parseInt(quantity),
        };
        entryItems.push(entryItem);
      } else {
        data?.product?.categories?.length > 0
          ? (cat = data?.product?.categories[0]?.name)
          : (cat = '0');
        const entryItem = {
          item_id: data?.product?.code,
          item_name: data?.product?.name,
          affiliation: '0',
          currency: data?.basePrice?.currencyIso,
          discount: 0,
          index: parseInt(data?.entryNumber),
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_variant: '0',
          price: data?.basePrice?.value,
          quantity: parseInt(quantity),
        };
        entryItems.push(entryItem);
      }

      let items = {
        event: 'add_to_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        add_to_cart_type: page,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // ** View Cart
  viewCartDataLayer(data): void {
    if (data) {
      let entryItems = [];
      let cat;
      data[0]?.entries.forEach((entry) => {
        entry?.product?.categories?.length > 0
          ? (cat = entry?.product?.categories[0]?.name)
          : (cat = '0');
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '0',
          currency: entry?.basePrice?.currencyIso,
          discount: 0,
          index: parseInt(entry?.entryNumber),
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_variant: '0',
          price: entry?.basePrice?.value,
          quantity: parseInt(entry?.quantity),
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: 'standard process',
        ecommerce: {
          currency: data[0]?.totalPrice?.currencyIso,
          value: data[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // ** Begin Checkout
  beginCheckoutDataLayer(data): void {
    if (data) {
      let entryItems = [];
      let cat;
      data[0]?.entries?.forEach((entry) => {
        entry?.product?.categories?.length > 0
          ? (cat = entry?.product?.categories[0]?.name)
          : (cat = '0');
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '0',
          coupon: '0',
          currency: entry?.basePrice?.currencyIso,
          index: parseInt(entry?.entryNumber),
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_variant: '0',
          price: entry?.basePrice?.value,
          quantity: parseInt(entry?.quantity),
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'begin_checkout',
        interaction_type: 'ecommerce',
        process_type: 'standard process',
        location_id: '0',
        multiple_locations: '0',
        locations_number: 0,
        ecommerce: {
          currency: data[0]?.totalPrice?.currencyIso,
          value: data[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // ** Purchase
  purchaseDataLayer(data): void {
    if (data) {
      let entryItems = [];
      let cat;
      data?.entries.forEach((entry) => {
        data?.entry?.product?.categories?.length > 0
          ? (cat = entry?.product?.categories[0]?.name)
          : (cat = '0');
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '0',
          currency: entry?.basePrice?.currencyIso,
          discount: 0,
          index: parseInt(entry?.entryNumber),
          item_brand: '0',
          item_category: cat,
          item_category2: '0',
          item_variant: '0',
          price: entry?.basePrice?.value,
          quantity: parseInt(entry?.quantity),
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'purchase',
        interaction_type: 'ecommerce',
        process_type: 'standard process',
        location_id: '0',
        multiple_locations: '0', // yes or no,
        locations_number: 0,
        ecommerce: {
          transaction_id: data?.code,
          transaction_type: data?.paymentType.code,
          transaction_update: data?.status,
          affiliation: '0',
          value: data?.totalPrice?.value,
          tax: data?.totalTax?.value,
          shipping: data?.deliveryCost?.value,
          currency: data?.totalTax?.currencyIso,
          coupon: '0',
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // ** View item
  viewItemDataLayer(data, type, page): void {
    if (data) {
      let cat;
      let items;
      if (page == 'pdp') {
        data?.categories?.length > 0
          ? (cat = data?.categories[0]?.name)
          : (cat = '0');
        items = {
          event: 'view_item',
          interaction_type: 'ecommerce',
          process_type: type,
          ecommerce: {
            items: [
              {
                item_id: data?.code,
                item_name: data?.name,
                affiliation: '0',
                coupon: '0',
                currency: data?.price?.currencyIso,
                item_brand: '0',
                item_category: cat,
                item_category2: ' 0',
                item_variant: '0',
                price: data?.price?.value,
                quantity: 1,
              },
            ],
          },
        };
      } else {
        data?.product?.categories?.length > 0
          ? (cat = data?.product?.categories[0]?.name)
          : (cat = '0');
        items = {
          event: 'view_item',
          interaction_type: 'ecommerce',
          process_type: type,
          ecommerce: {
            items: [
              {
                item_id: data?.product?.code,
                item_name: data?.product?.name,
                affiliation: '0',
                coupon: '0',
                currency: data?.basePrice?.currencyIso,
                item_brand: '0',
                item_category: cat,
                item_category2: ' 0',
                item_variant: '0',
                price: data?.basePrice?.value,
                quantity: 1,
              },
            ],
          },
        };
      }

      this.pushData(items);
    }
  }

  // ** Select item
  selectItemDataLayer(data, type): void {
    if (data) {
      let cat;
      data?.categories?.length > 0
        ? (cat = data?.categories[0]?.name)
        : (cat = '0');
      const items = {
        event: 'select_item',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          items: [
            {
              item_id: data?.code,
              item_name: data?.name,
              affiliation: '0',
              coupon: '0',
              currency: data?.price?.currencyIso,
              item_brand: '0',
              item_category: cat,
              item_category2: '0',
              item_variant: '0',
              price: data?.price?.value,
              quantity: 1,
            },
          ],
        },
      };
      this.pushData(items);
    }
  }

  // ** Download
  downloadDataLayer(name, format, page): void {
    if (name) {
      const items = {
        event: 'download',
        name: name,
        interaction_type: 'download',
        document_type: page, //page
        document_format: format,
      };
      this.pushData(items);
    }
  }

  //After reordering an order
  addUpdateCartDataLayer(data, type, quantity, page): void {
    let entryItems = [];
    if (data && type != 'new reservation') {
      data[0].entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      let items = {
        event: 'add_to_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        add_to_cart_type: page,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // after successfully logging in
  loginDataLayer(): void {
    this.userSub = this.user.get().pipe(take(2)).subscribe((res) => {
      if (res !== undefined) {
        const userInfo: any = res;
        let userId;
        if (userInfo?.akamaiId) {
          userId = userInfo?.akamaiId;
        } else {
          userId = userInfo?.uid;
        }
        const items = {
          event: 'login',
          interaction_type: 'authentication',
          user_id: userId,
        };
        this.pushData(items);
        this.userSub.unsubscribe();
      }
    });
  }

  // after successfull registration
  registrationDataLayer(): void {
    this.userSub = this.user.get().subscribe((res) => {
      if (res !== undefined) {
        const userInfo: any = res;
        let userId;
        if (userInfo?.akamaiId) {
          userId = userInfo?.akamaiId;
        } else {
          userId = userInfo?.uid;
        }
        const items = {
          event: 'sign_up',
          interaction_type: 'authentication',
          user_id: userId,
        };
        this.pushData(items);
        this.userSub.unsubscribe();
      }
    });
  }

  //Step 3
  shippingInfo(data, processType): void {
    if (data) {
      let entryItems = [];
      data[0]?.entries?.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.totalPrice.currencyIso,
          discount: '<discount value>',
          index: '<product position>',
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'add_shipping_info',
        interaction_type: 'ecommerce',
        process_type: processType,
        location_id: '<location_id>',
        multiple_locations: '<multiple_locations>',
        locations_number: '<locations_number>',
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  addReservationCartDataLayer(data, type, quantity, page): void {
    let entryItems = [];
    data.childCarts[0].entries.forEach((entry) => {
      const entryItem = {
        item_id: entry?.product?.code,
        item_name: entry?.product?.name,
        affiliation: '<type of funnel>',
        currency: entry?.basePrice?.currencyIso,
        discount: '<discount value>',
        index: entry?.entryNumber,
        item_brand: '<item brand>',
        item_category: entry?.product?.categories[0].name,
        item_category2: '<item category 2>',
        item_variant: '<item variant>',
        price: entry?.basePrice?.value,
        quantity: entry?.quantity,
      };
      entryItems.push(entryItem);
    });
    let items = {
      event: 'add_to_cart',
      interaction_type: 'ecommerce',
      process_type: type,
      add_to_cart_type: page,
      ecommerce: {
        items: entryItems,
      },
    };
    this.pushData(items);
  }

  //For updating quantinty in the cart page
  viewCartUpdateQuantityDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          currency: data?.totalPrice?.currencyIso,
          value: data?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  viewUpdateCartDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.carts[0]?.childCarts[0]?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          currency: data?.carts[0]?.childCarts[0]?.totalPrice?.currencyIso,
          value: data?.carts[0]?.childCarts[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  viewOTPDataLayer(data) {
    this.pushData(data)
  }
}
