import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { faPrint, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faRectangleList } from '@fortawesome/free-regular-svg-icons';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutComponent } from '../../shared/modules/checkout/checkout.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-standard-cart-checkout',
  templateUrl: './standard-cart-checkout.component.html',
  styleUrls: ['./standard-cart-checkout.component.scss']
})
export class StandardCartCheckoutComponent implements OnInit,OnDestroy {
  @ViewChild('p2') public savePopover: NgbPopover;
  @ViewChild(CheckoutComponent) checkoutDetails;
cartDetails:any[];
stdCart=true;
usergetDataObj: any;
getuserData: any;
searchedClientList:any;
selectedData:any;
listSelectedData:any;
userAccountgetData: any;
saveCartName:string;
errorMsg:string;
cartId:string;
productEntries: any = [];
saveCartSub:Subscription;
cartItemsSub:Subscription;
userAccountSub:Subscription;
invalidNameSub:Subscription;

  // icons
  faPrint = faPrint;
  faFileExcel = faFileExcel;
  faRectangleList = faRectangleList;
  faChevronLeft = faChevronLeft;

  constructor(private readonly addToCart:CustomAddToCartService,
    readonly user: UserAccountFacade,private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res && res != null) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
      }
    });
    this.cdr.detectChanges();
    this.cartItemsSub=this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        let stdCartDetails = [];
        this.productEntries=[];
        stdCartDetails = cartData?.childCarts;
        this.cartId=cartData?.code;
        if (stdCartDetails) {
          stdCartDetails = stdCartDetails.filter(
            (item) => item.cartType === 'STANDARD'
          );
          for (const cartDetails of stdCartDetails) {
            for (const entries of cartDetails.entries) {
              this.productEntries.push(entries);
            }
          }
          this.cdr.detectChanges();
        }
      }
    });
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg='';
    this.saveCartName=this.checkoutDetails?.selectedData?.companyName?.slice(0,30);
  }
  getUserAccountData(userId): void {
      this.userAccountSub=this.addToCart.userAccountDetails.subscribe((userDataget) => {
        if(userDataget){
        this.getuserData = userDataget.res.shippingAddresses;
        this.searchedClientList = userDataget.res.shippingAddresses;
        this.selectedData = this.getuserData[0];
        this.saveCartName=this.checkoutDetails?.selectedData?.companyName?.slice(0,30);
        }
      });
      this.cdr.detectChanges();
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
    this.addToCart.saveCartDetails(this.saveCartName,this.cartId);
    this.invalidNameSub=this.addToCart.invalidCartName$.subscribe((res)=>{
      if(res){
      for(const error of res?.error?.errors){
        this.errorMsg=error?.message;
        }
      }
    });
    this.saveCartSub=this.addToCart.saveCartSuccess$.subscribe((res)=>{
      if(res){
        if(this.savePopover){
          this.savePopover.close();
        }
        this.errorMsg='';
        this.saveCartName=this.checkoutDetails?.selectedData?.companyName?.slice(0,30);
      }
    });
  }else{
    if(this.saveCartName){
      this.errorMsg= 'Save cart name is not valid.';
    }else{
    this.errorMsg = 'Cart name cannot be blank';
    }
  }
  }
  exportXls() {
    const cartProducts=[];
    for(const entries of this.productEntries){
      cartProducts.push({
        'Customer':this.selectedData?.sapCustomerID,
        'Material ID':entries?.product?.code,
        'Quantity':entries?.quantity,
        'PO Number':this.checkoutDetails?.orderNo
      });
    }
    this.addToCart.downloadXls(cartProducts, 'Checkout');
  }
  omit_special_char(event){
    this.errorMsg='';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.cartItemsSub?.unsubscribe();
    this.saveCartSub?.unsubscribe();
    this.invalidNameSub?.unsubscribe();
    this.userAccountSub?.unsubscribe();
  }
}
