<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 mb-2 py-3">
    <div class="col-xs-12 col-md-12 mb-5">
      <h2 class="mb-4 c-form-title">
        {{ "userRegistration.userInformation.header" | cxTranslate }}
      </h2>
      <h4 class="mb-4 c-form-copy" *ngIf="!!accountData"> {{
        "userRegistration.userInformation.locationStripSubMessage"
        | cxTranslate
        }} ({{ accountData?.uid }})</h4>

      <p class="text-fixed-legal-copy">{{
        "userRegistration.userInformation.formTitle" | cxTranslate
        }}</p>
    </div>
    <form [formGroup]="userInfoForm" id="userInfoForm" (ngSubmit)="submitUserInfo()">
      <div class="row mb-2">
        <div id="registerBilling" class="col-xs-12 col-md-12">
          <div formGroupName="userInfoDetails">
            <!-- Username -->
            <div class="
              col-xs-12 col-sm-12 col-md-6
              c-text-input
              mb-3
            ">
              <label for="username"
                class="Fields-label icon-label d-inline c-form-label">{{"userRegistration.userInformation.username.label"
                | cxTranslate}}
              </label>
              <ng-template #popContent>{{"userRegistration.userInformation.username.popover_msg_1" | cxTranslate}}
                <br />{{"userRegistration.userInformation.username.popover_msg_2" | cxTranslate}}
                <br />{{"userRegistration.userInformation.username.popover_msg_3" | cxTranslate}}
              </ng-template>
              <a placement="top" [ngbPopover]="popContent" class="d-inline">
                <i color="red" class="fa fa-question-circle c-icon-primary-sm"></i>
              </a>
              <input id="username" name="userName" data-valid="valid_facility"
                aria-labelledby="userName tooltipFacilityName" type="text" class="Fields-input user" autocomplete="off"
                formControlName="userName" data-di-id="#userName" />
              <cx-form-errors *ngIf="isUsernameExists && !userInfoForm.get('userInfoDetails.userName').touched"
                class="errorMessage">
                <p>{{"userRegistration.userInformation.username.existingUsernameMsg" | cxTranslate}}</p>
              </cx-form-errors>

              <cx-form-errors *ngIf="
                !!userInfoForm.get('userInfoDetails.userName').value &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                userInfoForm.get('userInfoDetails.userName').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage">
                <p>{{
                  "userRegistration.userInformation.username.usernameSameAsAccountNo"
                  | cxTranslate
                  }}</p>
              </cx-form-errors>

              <cx-form-errors *ngIf="
                !!userInfoForm.get('userInfoDetails.userName').value &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                !userInfoForm.get('userInfoDetails.userName').errors
                  ?.sameAsaccountNo &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage">
                <p>{{
                  "userRegistration.userInformation.username.inValidUsername"
                  | cxTranslate
                  }}</p>
              </cx-form-errors>
              <cx-form-errors *ngIf="
                userInfoForm.get('userInfoDetails.userName').errors?.required &&
                !userInfoForm.get('userInfoDetails.userName').valid &&
                userInfoForm.get('userInfoDetails.userName').touched
              " class="errorMessage" role="alert" aria-live="assertive">
                <p>{{
                  "userRegistration.userInformation.requiredMsg" | cxTranslate
                  }}</p>
              </cx-form-errors>
            </div>
            <div class="row" *ngIf="!akamaiRegistered?.registeredAkamaiUser">

              <div class="col-md-6">
                <div class="col-md-12">
                  <!-- Password -->
                  <div class="
                c-text-input
                mb-3
              ">
                    <label for="userPassword" class="c-form-label">{{
                      "userRegistration.userInformation.password.label" | cxTranslate
                      }}
                    </label>
                    <input id="userPassword" name="password" data-valid="valid_facility"
                      aria-labelledby="password tooltipFacilityName" type="password" class="Fields-input"
                      autocomplete="off" formControlName="password" data-di-id="#userPassword" />

                    <cx-form-errors *ngIf="
                  !!userInfoForm.get('userInfoDetails.password')?.value &&
                  userInfoForm.get('userInfoDetails.password')?.valid &&
                  !userInfoForm.get('userInfoDetails.password')?.errors
                    ?.sameAsaccountNo &&
                  userInfoForm.get('userInfoDetails').errors
                    ?.userNamePasswordMatch &&
                  userInfoForm.get('userInfoDetails.password')?.touched
                " class="errorMessage">
                      <p>{{
                        "userRegistration.userInformation.password.usernameAndPasswordNotSame"
                        | cxTranslate
                        }}</p>
                    </cx-form-errors>

                    <cx-form-errors *ngIf="
                  !!userInfoForm.get('userInfoDetails.password')?.value &&
                  !userInfoForm.get('userInfoDetails.password')?.valid &&
                  userInfoForm.get('userInfoDetails.password')?.errors
                    ?.sameAsaccountNo &&
                  userInfoForm.get('userInfoDetails.password')?.touched
                " class="errorMessage">
                      <p>{{
                        "userRegistration.userInformation.password.passwordSameAsAccountNo"
                        | cxTranslate
                        }}</p>
                    </cx-form-errors>

                    <cx-form-errors *ngIf="
                  !userInfoForm.get('userInfoDetails.password')?.valid &&
                  !userInfoForm.get('userInfoDetails.password')?.errors
                    ?.required &&
                  !userInfoForm.get('userInfoDetails.password')?.errors
                    ?.sameAsaccountNo &&
                  userInfoForm.get('userInfoDetails.password')?.touched
                " class="errorMessage">
                      <p>{{
                        "userRegistration.userInformation.password.inValidPassword"
                        | cxTranslate
                        }}</p>
                    </cx-form-errors>
                    <cx-form-errors *ngIf="
                  userInfoForm.get('userInfoDetails.password').errors?.required &&
                  !userInfoForm.get('userInfoDetails.password').valid &&
                  userInfoForm.get('userInfoDetails.password').touched
                " class="defaultMessage" role="alert" aria-live="assertive">
                      <p>{{
                        "userRegistration.userInformation.requiredMsg" | cxTranslate
                        }}</p>
                    </cx-form-errors>
                  </div>
                  <!-- Password Confirm-->

                  <div class="
                c-text-input
                mb-3
              ">
                    <label for="userConfirmPassword" class="Fields-label c-form-label">{{
                      "userRegistration.userInformation.confirmPassword.label"
                      | cxTranslate
                      }}</label>
                    <input id="userConfirmPassword" name="confirmPassword" data-valid="valid_zip_canada" type="password"
                      class="Fields-input" autocomplete="off" formControlName="confirmPassword"
                      data-di-id="#userConfirmPassword" />

                    <cx-form-errors *ngIf="
                  !userInfoForm.get('userInfoDetails.confirmPassword')?.valid &&
                  !userInfoForm.get('userInfoDetails.confirmPassword')?.errors
                    ?.required &&
                  userInfoForm.get('userInfoDetails.confirmPassword')?.errors
                    ?.isPasswordInvalid &&
                  userInfoForm.get('userInfoDetails.confirmPassword')?.touched
                " class="errorMessage">
                      <p>{{
                        "userRegistration.userInformation.password.inValidPassword"
                        | cxTranslate
                        }}</p>
                    </cx-form-errors>

                    <cx-form-errors *ngIf="
                  !!userInfoForm.get('userInfoDetails.confirmPassword')?.value &&
                  userInfoForm.get('userInfoDetails.confirmPassword')?.touched &&
                  !userInfoForm.get('userInfoDetails.confirmPassword')?.errors
                    ?.isPasswordInvalid &&
                  userInfoForm.get('userInfoDetails').errors?.mismatch
                " class="errorMessage">
                      <p>{{
                        "userRegistration.userInformation.notMatchMsg" | cxTranslate
                        }}</p>
                    </cx-form-errors>
                    <cx-form-errors *ngIf="
                  userInfoForm.get('userInfoDetails.confirmPassword')?.errors
                    ?.required &&
                  !userInfoForm.get('userInfoDetails.confirmPassword')?.valid &&
                  userInfoForm.get('userInfoDetails.confirmPassword')?.touched
                " class="defaultMessage" role="alert" aria-live="assertive">
                      <p>{{
                        "userRegistration.userInformation.requiredMsg" | cxTranslate
                        }}</p>
                    </cx-form-errors>
                  </div>

                </div>
              </div>

              <div class="col-md-6 password-description" id="passwordDesc" tabindex="-1">
                <span class="security-Notify d-block mb-2">{{
                  "userRegistration.userInformation.password.passwordRuleSection"
                  | cxTranslate
                  }}</span>
                <ul class="required-ul">
                  <li>
                    {{
                    "userRegistration.userInformation.password.passwordCondition_1"
                    | cxTranslate
                    }}
                  </li>
                  <li>
                    {{
                    "userRegistration.userInformation.password.passwordCondition_2"
                    | cxTranslate
                    }}
                  </li>
                  <li>
                    {{
                    "userRegistration.userInformation.password.passwordCondition_3"
                    | cxTranslate
                    }}
                  </li>
                  <li>
                    {{
                    "userRegistration.userInformation.password.passwordCondition_4"
                    | cxTranslate
                    }}
                  </li>
                </ul>
              </div>

            </div>

          </div>
          <!-- Password recovery -->
          <div class="row">
            <div class="col-md-12">
              <div class="
            col-xs-12 col-sm-12 col-md-6
            Fields-container
            asses_dropdown
            c-text-input
            mb-3
          ">
                <label for="userPasswordRecoverQuestion" id="userPasswordQuestionLabel" class="c-form-label">{{
                  "userRegistration.userInformation.passwordRecovery.question.label"
                  | cxTranslate
                  }}</label>

                <cx-form-errors *ngIf="
            userInfoForm.get('passwordQuestion').errors?.required &&
            !userInfoForm.get('passwordQuestion').valid &&
            userInfoForm.get('passwordQuestion').touched
          " class="errorMessage pt-2" role="alert" aria-live="assertive">
                  <p>{{
                    "userRegistration.userInformation.requiredMsg" | cxTranslate
                    }}</p>
                </cx-form-errors>
                <ng-select name="passwordQuestion" [items]="passwordQuestions" [searchable]="false" [clearable]="false"
                  bindLabel="name" bindValue="code" placeholder="Select" formControlName="passwordQuestion"
                  class="dropdown-credit mb-4"></ng-select>
              </div>
            </div>
          </div>
          <!-- Password recovery question list -->
          <div class="
            col-xs-12 col-sm-12 col-md-6
            c-text-input
            mb-3
            Fields-container
            zip-container
          ">
            <div class="">
              <label for="userPasswordAnswer" class="c-form-label">{{
                "userRegistration.userInformation.passwordRecovery.answer.label"
                | cxTranslate
                }}</label>
              <input id="userPasswordAnswer" name="passwordAnswer" data-valid="valid_zip_canada" type="text"
                class="Fields-input" autocomplete="off" formControlName="passwordAnswer"
                data-di-id="#userPasswordAnswer" />
              <cx-form-errors *ngIf="
                  userInfoForm.get('passwordAnswer').errors?.required &&
                  !userInfoForm.get('passwordAnswer').valid &&
                  userInfoForm.get('passwordAnswer').touched
                " class="defaultMessage" role="alert" aria-live="assertive">
                <p>{{
                  "userRegistration.userInformation.requiredMsg" | cxTranslate }}</p>
              </cx-form-errors>
            </div>
          </div>
        </div>
      </div>
      <!-- About Me -->
      <div class="col-xs-12 col-md-12 mb-2">
        <h2 class="mb-4 c-form-title">
          {{ "userRegistration.userInformation.subTitle" | cxTranslate }}
        </h2>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-12" id="registerBilling">
          <!-- First name -->
          <div class="
            col-xs-12 col-sm-12 col-md-6
            c-text-input
            mb-3
          ">
            <label for="userFirstName" class="c-form-label">{{
              "userRegistration.userInformation.firstName.label" | cxTranslate
              }}
            </label>
            <input id="userFirstName" name="firstName" data-valid="valid_facility"
              aria-labelledby="firstName tooltipFacilityName" type="text" class="Fields-input" autocomplete="off"
              formControlName="firstName" data-di-id="#userFirstName" />

            <cx-form-errors *ngIf="
              userInfoForm.get('firstName').errors?.required &&
              !userInfoForm.get('firstName').valid &&
              userInfoForm.get('firstName').touched
            " class="defaultMessage" role="alert" aria-live="assertive">
              <p>{{
                "userRegistration.userInformation.requiredMsg" | cxTranslate }}</p>
            </cx-form-errors>
          </div>
          <!-- Last name -->
          <div class="
            col-xs-12 col-sm-12 col-md-6
            c-text-input
            mb-3
          ">
            <label for="userLastName" class="c-form-label">{{
              "userRegistration.userInformation.lastName.label" | cxTranslate
              }}
            </label>
            <input id="userLastName" name="lastName" data-valid="valid_facility"
              aria-labelledby="lastName tooltipFacilityName" type="text" class="Fields-input" autocomplete="off"
              formControlName="lastName" data-di-id="#userLastName" />

            <cx-form-errors *ngIf="
              userInfoForm.get('lastName').errors?.required &&
              !userInfoForm.get('lastName').valid &&
              userInfoForm.get('lastName').touched
            " class="defaultMessage" role="alert" aria-live="assertive">
              <p>{{
                "userRegistration.userInformation.requiredMsg" | cxTranslate }}</p>
            </cx-form-errors>
          </div>
          <!-- Role -->
          <div class="row">
            <div class="col-md-12">
              <div class="
              col-xs-12 col-sm-12 col-md-6
              c-text-input
              mb-3
            ">
                <label for="primaryRole" id="roleLabel" class="c-form-label">{{
                  "userRegistration.userInformation.role.label" | cxTranslate
                  }}</label>
                <cx-form-errors *ngIf="
                  userInfoForm.get('role').errors?.required &&
                  !userInfoForm.get('role').valid &&
                  userInfoForm.get('role').touched
                " class="errorMessage pt-2" role="alert" aria-live="assertive">
                  <p>{{
                    "userRegistration.userInformation.requiredMsg" | cxTranslate
                    }}</p>
                </cx-form-errors>


                <ng-select name="role" [items]="contactRole" [searchable]="false" [clearable]="false" bindLabel="name"
                  bindValue="code" placeholder="Select" formControlName="role" class="dropdown-credit mb-4"></ng-select>
              </div>



            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-12 mb-2">
        <h2 class="mb-4 c-form-title">
          {{ "userRegistration.userInformation.anotherSubTitle" | cxTranslate }}
        </h2>
      </div>
      <!-- email consent -->
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6 c-text-input
          mb-3">
            <label for="userEmailConsent" id="usrEmailConsentLabel" class="c-form-label mb-3">{{
              "userRegistration.userInformation.emailConsent.label" | cxTranslate
              }}</label>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="acceptEmailConsent" name="emailConsent"
                [value]="isEmailConsent[0]" formControlName="emailConsent" />
              <label class="custom-control-label radio_align" for="acceptEmailConsent">{{
                "userRegistration.userInformation.emailConsent.accepted"
                | cxTranslate
                }}</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="rejectEmailConsent" name="emailConsent"
                [value]="isEmailConsent[1]" formControlName="emailConsent" />
              <label class="custom-control-label radio_align" for="rejectEmailConsent">{{
                "userRegistration.userInformation.emailConsent.rejected"
                | cxTranslate
                }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- <span class="addAlternateEmail" (click)="addAlternateEmail()"
        *ngIf="!altEmailSection && userInfoForm.get('emailConsent').value">{{
        "userRegistration.userInformation.alternateEmail.title" | cxTranslate
        }}</span> -->
      <!-- <div class="noemailUpdates" style="display: block"
        *ngIf="altEmailSection && userInfoForm.get('emailConsent').value">
        <div formGroupName="alternateEmailInfo">
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label for="altEmail" class="Fields-label">
              {{
              "userRegistration.userInformation.alternateEmail.label"
              | cxTranslate
              }}
            </label>
            <input id="altEmail" name="alternateEmail" data-valid="valid_mail" type="text"
              class="Fields-input field-optional" data-view="altemail" formControlName="alternateEmail"
              autocomplete="off" data-di-id="#altEmail" />

            <a (click)="deleteAltEmailSection()" title="" class="alternate-mail-delete"
              data-di-id="di-id-503c60dc-e8d584e6">
              <img src="../../assets/icons/trash.svg" alt="Delete" />{{
              "userRegistration.userInformation.alternateEmail.delete_alt_section_text"
              | cxTranslate
              }}
            </a>

            <span *ngIf="
              (userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                ?.email ||
                userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                  ?.pattern) &&
              !userInfoForm.get('alternateEmailInfo.alternateEmail').valid &&
              userInfoForm.get('alternateEmailInfo.alternateEmail').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>
            <span *ngIf="
              userInfoForm.get('alternateEmailInfo.alternateEmail').errors
                ?.required &&
              !userInfoForm.get('alternateEmailInfo.alternateEmail').valid &&
              userInfoForm.get('alternateEmailInfo.alternateEmail').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate }}
            </span>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label for="confirmAltEmail" class="Fields-label">
              {{
              "userRegistration.userInformation.alternateEmail.confirm_alt_email_label"
              | cxTranslate
              }}
            </label>
            <input id="confirmAltEmail" name="confirmAlternateEmail" data-valid="valid_mail" type="text"
              class="Fields-input field-optional" data-view="confirmaltemail" formControlName="confirmAlternateEmail"
              autocomplete="off" data-di-id="#confirmAltEmail" />

            <span *ngIf="
              (userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .errors?.email ||
                userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                  .errors?.pattern) &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .valid &&
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>
            <span *ngIf="
              !!userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .value &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .invalid &&
              userInfoForm.get('alternateEmailInfo').errors?.altEmailNotMatch
            " class="errorMessage">{{
              "userRegistration.userInformation.notMatchMsg" | cxTranslate
              }}</span>
            <span *ngIf="
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .errors?.required &&
              !userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .valid &&
              userInfoForm.get('alternateEmailInfo.confirmAlternateEmail')
                .touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate }}
            </span>
          </div>
        </div>
      </div>
      <hr *ngIf="altEmailSection && userInfoForm.get('emailConsent').value"
        class="Fields-container-line userInfoPage" /> -->
      <div class="row mb-4">
        <div class="col-md-12 mb-1">
          <div class="col-md-6 c-text-input">
            <div class="invoice-btngrps" [ngClass]=" !!altEmailSection && userInfoForm.get('emailConsent').value
          ? 'alt-section'
          : ''
      ">
              <button type="submit" class="c-button c-button--primary c-button--lg reviewvalidbtn"
                data-di-id="di-id-7a6cb2cd-cb37948a">
                {{ "userRegistration.userInformation.submit_button" | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>