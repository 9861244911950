<!-- modal for continue  -->
<section #continueCheckout class="checkout-popover">
  <section class="checkout-modal">
    <div class="modal-dialog">
      <div class="modal-content overlay">
        <div class="close" (click)="cancelModal()">
          <i
            class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
          ></i>
        </div>
        <div class="modal-header overlay-heading">
          <h3 class="font-bold u-border-bottom-grey pb-4 w-100">
            <ng-container *ngIf="!cartHasItem"
              >{{ "logoutModal.heading" | cxTranslate }}</ng-container
            >
            <ng-container *ngIf="cartHasItem"
              >{{ "logoutModal.haveItemHeading" | cxTranslate }}</ng-container
            >
          </h3>
        </div>

        <div class="modal-body confirm-order">
          <div class="text-fixed-medium">
            <p *ngIf="cartHasItem">
              {{ "logoutModal.haveItemCopy" | cxTranslate }}
            </p>
            <p class="mt-2">{{ "logoutModal.copy" | cxTranslate }}</p>
          </div>
          <!-- Buttons -->
          <div class="row u-border-top-grey pt-5">
            <div class="col-md-6 d-flex justify-content-center">
              <button
                type="button"
                class="c-button c-button--secondary w-100 mb-4"
                data-toggle="modal"
                data-target="#overlay"
                data-di-id="di-id-35119eec-5a7dc742"
                (click)="cancelModal()"
              >
                {{ "logoutModal.cancel" | cxTranslate }}
              </button>
            </div>
            <div class="col-md-6 d-flex justify-content-center">
              <button
                type="submit"
                class="c-button c-button--primary w-100 mb-4"
                data-toggle="modal"
                data-target="#loading-order"
                id="orderConfirmbtn"
                data-di-id="#orderConfirmbtn"
                (click)="logout()"
              >
                {{ "logoutModal.proceed" | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
