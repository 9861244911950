<div class="bc-makePayment-Div row no-print mb-4">
  <div
    class="back-To-makePayment col-md-12 text-md-right mt-4 mt-lg-0 pl-4 pl-md-0 pr-md-4"
  >
    <a
      href="javascript: void(0);"
      [routerLink]="['/', 'my-account', 'make-payment']"
      class="c-link"
    >
      <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon> Back to make a
      payment</a
    >
  </div>
</div>
<section
  class="row makePaymentView u-border-left-grey pb-4"
  id="productList-area"
>
  <section class="col-md-12 pay-section">
    <app-payment-header
      [tableCategory]="creditInvoice"
      selectedHeader="review"
    ></app-payment-header>
    <section class="row pl-md-3">
      <section class="col-md-12 col-xs-12 op_ovice">
        <h2 class="font-bold u-border-bottom-grey pb-4 mb-4">
          <span>Review</span>
        </h2>
      </section>
    </section>
    <!-- Invoice -->
    <section *ngIf="invoicePaymentList?.length > 0" class="row pl-md-3 mb-4">
      <div class="col-md-12">
        <p class="text-fixed-medium">
          {{ 'makeAPayment.nameofPersonAuthorizingPayment' | cxTranslate
          }}<br />
          {{ personName }}
        </p>
        <p class="text-fixed-medium">
          {{ 'makeAPayment.EmailforConfirmation' | cxTranslate }}<br />
          <a
            class="c-link"
            href="mailto:{{ userData?.email }}"
            *ngIf="!isAsmEnabled"
            >{{ userData?.email }}</a
          >
          <a
            class="c-link"
            href="mailto:{{ asmEmailDetails?.selectedEmail }}"
            *ngIf="
              isAsmEnabled &&
              asmEmailDetails?.selectedEmail !== 'Add Other E-mail Address'
            "
            >{{ asmEmailDetails?.selectedEmail }}</a
          >
          <a
            class="c-link"
            href="mailto:{{ asmEmailDetails?.newEmail }}"
            *ngIf="
              isAsmEnabled &&
              asmEmailDetails?.selectedEmail === 'Add Other E-mail Address'
            "
            >{{ asmEmailDetails?.newEmail }}</a
          >
        </p>
      </div>

      <!-- ////// -->

      <section class="col-md-12">
        <table
          aria-describedby="Credits"
          id="applyCreditsTable"
          class="payment-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
        >
          <thead>
            <tr class="heading-table">
              <th
                id="table1col1"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_option' | cxTranslate }}&nbsp;
              </th>
              <th
                id="table1col2"
                class="sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-sort="descending"
                aria-label="Invoice #: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice#' | cxTranslate }}
              </th>
              <th
                id="table1col3"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending"
              >
                {{ 'makeAPayment.due_date' | cxTranslate }}
              </th>
              <th
                id="table1col4"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice_balance' | cxTranslate }}
              </th>
              <th
                id="table1col5"
                class="pr8 sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment amount: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_amount' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col6"
                class="sorting payment-method white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment method: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_method' | cxTranslate }}
              </th>
              <th
                id="table1col7"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment date: activate to sort column ascending"
              >
                {{ 'makeAPayment.payment_date' | cxTranslate }}
              </th>
            </tr>
          </thead>
          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of invoicePaymentList">
              <td headers="table1col1">
                {{ 'makeAPayment.pay_now' | cxTranslate }}
              </td>
              <td headers="table1col2" class="darkblue sorting_1">
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="blue underline"
                  (click)="invoicePdf(data?.refDocNo)"
                >
                  {{ data?.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="blue underline"
                  (click)="invoicePdf(data?.allocNmbr)"
                >
                  {{ data?.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data?.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                  "
                >
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
                <input
                  id="invoiceDatas0.credit"
                  name="invoiceDatas[0].credit"
                  type="hidden"
                  value="92258122"
                  data-di-id="#invoiceDatas0.credit"
                />
              </td>
              <td headers="table1col3">{{ data?.dueDate }}</td>
              <td headers="table1col4" class="text-right">
                {{ data?.invoiceBalance | currency }}
                <input
                  id="invoiceDatas0.invoiceBalance"
                  name="invoiceDatas[0].invoiceBalance"
                  value="1479.49"
                  type="hidden"
                  data-di-id="#invoiceDatas0.invoiceBalance"
                />
              </td>

              <td headers="table1col5" class="text-right">
                {{ data?.paymentAmount }}
              </td>
              <td headers="table1col6">
                <img
                  src="../../../assets/icons/visa.png"
                  class="card-type"
                  *ngIf="selectedCardDetails?.cardType === 'VISA'"
                  alt="VISA"
                />
                <img
                  src="../../../assets/icons/mastercard.png"
                  class="card-type"
                  alt="MASTER"
                  *ngIf="selectedCardDetails?.cardType === 'MC'"
                />
                #{{ dispCardNumber?.cardNumber }}
              </td>
              <td headers="table1col7">
                {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>

        <section class="credits-table-2column">
          <div
            class="row credits-row-rwd"
            *ngFor="let data of invoicePaymentList"
          >
            <div class="column creadits-header-columnrwd">
              <section>
                <p
                  id="table1col1"
                  class="sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment-option: activate to sort column ascending"
                >
                  {{ 'makeAPayment.payment_option' | cxTranslate }}
                </p>
                <p
                  id="table1col2"
                  class="sorting_desc"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-sort="descending"
                  aria-label="Invoice #: activate to sort column ascending"
                >
                  {{ 'makeAPayment.invoice#' | cxTranslate }}
                </p>
                <p
                  id="table1col3"
                  class="sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Due date: activate to sort column ascending"
                >
                  {{ 'makeAPayment.due_date' | cxTranslate }}
                </p>
                <p
                  id="table1col4"
                  class="pr8 sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending"
                >
                  {{ 'makeAPayment.invoice_balance' | cxTranslate }}
                </p>
                <p
                  id="table1col5"
                  class="pr8 sorting_desc"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment amount: activate to sort column ascending"
                >
                  {{ 'makeAPayment.payment_amount' | cxTranslate }}
                </p>
                <p
                  id="table1col6"
                  class="sorting payment-method"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment method: activate to sort column ascending"
                >
                  {{ 'makeAPayment.payment_method' | cxTranslate }}
                </p>
                <p
                  id="table1col7"
                  class="sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment date: activate to sort column ascending"
                >
                  {{ 'makeAPayment.payment_date' | cxTranslate }}
                </p>
              </section>
            </div>
            <div class="column credits-desc-column">
              <section>
                <p headers="table1col1">
                  {{ 'makeAPayment.pay_now' | cxTranslate }}
                </p>
                <p headers="table1col2" class="darkblue sorting_1">
                  <a
                    *ngIf="
                      data?.docType === 'RV' ||
                      data?.docType === 'RD' ||
                      data?.docType === 'RF'
                    "
                    href="javascript: void(0);"
                    class="blue underline"
                    (click)="invoicePdf(data?.refDocNo)"
                  >
                    {{ data?.refDocNo }}
                  </a>
                  <a
                    *ngIf="
                      data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                    "
                    href="javascript: void(0);"
                    class="blue underline"
                    (click)="invoicePdf(data?.allocNmbr)"
                  >
                    {{ data?.allocNmbr }}
                  </a>
                  <span
                    *ngIf="
                      data?.docType === 'DR' ||
                      data?.docType === 'DG' ||
                      data?.docType === 'DY'
                    "
                  >
                    {{ data?.refDocNo }}
                  </span>
                  <span
                    *ngIf="
                      data?.docType === 'DZ' &&
                      !data?.allocNmbr?.startsWith('7')
                    "
                  >
                    {{ data?.allocNmbr }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data?.refhdr }}
                  </span>
                  <input
                    id="invoiceDatas0.credit"
                    name="invoiceDatas[0].credit"
                    type="hidden"
                    value="92258122"
                    data-di-id="#invoiceDatas0.credit"
                  />
                </p>
                <p headers="table1col3">{{ data?.dueDate }}</p>
                <p headers="table1col4">
                  {{ data?.invoiceBalance | currency }}
                  <input
                    id="invoiceDatas0.invoiceBalance"
                    name="invoiceDatas[0].invoiceBalance"
                    value="1479.49"
                    type="hidden"
                    data-di-id="#invoiceDatas0.invoiceBalance"
                  />
                </p>

                <p headers="table1col5" class="mb20">
                  {{ data?.paymentAmount }}
                </p>
                <p headers="table1col6" class="mb20">
                  <img
                    src="../../../assets/icons/visa.png"
                    class="card-type"
                    *ngIf="selectedCardDetails?.cardType === 'VISA'"
                    alt="VISA"
                  />
                  <img
                    src="../../../assets/icons/mastercard.png"
                    class="card-type"
                    alt="MASTER"
                    *ngIf="selectedCardDetails?.cardType === 'MC'"
                  />
                  #{{ dispCardNumber?.cardNumber }}
                </p>
                <p headers="table1col7">
                  {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
                </p>
              </section>
            </div>
          </div>
        </section>
      </section>

      <div class="col-md-4"></div>
      <div
        class="col-md-8 u-border-top-grey pt-4 mt-4 d-flex justify-content-between"
      >
        <h2 class="font-bold mb-4">
          {{ 'makeAPayment.pay_now' | cxTranslate }}
        </h2>
        <p class="total amount">{{ totalInvoiceAmount | currency }}</p>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <p class="p-0">
          {{ 'makeAPayment.totalamountinfo' | cxTranslate }}
        </p>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <div class="row grey-bg p-3">
          <div class="col-md-6">
            <h4 class="font-bold m-0">
              {{ 'makeAPayment.payment_total' | cxTranslate }}
            </h4>
          </div>
          <div class="col-md-6 text-right">
            <h4 class="total font-bold m-0">
              {{ totalInvoiceAmount | currency }}
            </h4>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 d-flex justify-content-end">
            <button
              type="button"
              (click)="back()"
              class="c-button c-button--secondary mr-4"
            >
              {{ 'makeAPayment.cancel' | cxTranslate }}
            </button>
            <button
              type="submit"
              class="c-button c-button--primary"
              data-di-id="di-id-5896ea52-8b55cbae"
              (click)="submitPayment()"
            >
              {{ 'makeAPayment.continue_button_txt' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- Credit Review Table -->
    <section *ngIf="creditPaymentList.length > 0" class="row pl-md-3 mb-4">
      <section class="col-md-12">
        <table
          aria-describedby="Invoices"
          id="applyCreditsTable"
          class="payment-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
        >
          <thead>
            <tr class="heading-table">
              <th
                id="table1col1"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit#' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col2"
                class="sorting_desc white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-sort="descending"
                aria-label="Invoice #: activate to sort column ascending"
              >
                {{ 'makeAPayment.apply_to_invoice' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col3"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_applied' | cxTranslate }}
              </th>
              <th
                id="table1col4"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_balance' | cxTranslate }}
              </th>
              <th
                id="table1col5"
                class="pr8 sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending"
              >
                {{ 'makeAPayment.invoice_balance' | cxTranslate }} &nbsp;
              </th>
              <th
                id="table1col6"
                class="sorting white-space-wrap"
                tabindex="0"
                aria-controls="applyCreditsTable"
                aria-label="Payment method: activate to sort column ascending"
              >
                {{ 'makeAPayment.credit_applied_date' | cxTranslate }}
              </th>
            </tr>
          </thead>

          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of creditPaymentList">
              <td headers="table1col1" class="sorting_1">
                <a
                  *ngIf="
                    data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.refDocNo)"
                >
                  {{ data?.refDocNo }}
                </a>
                <a
                  *ngIf="
                    data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="creditPdf(data?.allocNmbr)"
                >
                  {{ data?.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data?.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')
                  "
                >
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
              </td>
              <td headers="table1col2">{{ data?.applyToInvoice }}</td>
              <td headers="table1col3" class="right-text">
                {{ data?.creditsApplied | currency }}
              </td>
              <td headers="table1col4" class="right-text">
                {{ data?.creditBalance | currency }}
              </td>
              <td headers="table1col5" class="right-text">
                {{ data?.invoiceBalance | currency }}
              </td>
              <td headers="table1col6">
                {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>
        <section class="invoicepayment-table-2column">
          <div
            class="row invoice-2col-row-rwd"
            *ngFor="let data of creditPaymentList"
          >
            <div class="column invoice-header-rwd">
              <section>
                <p
                  id="table1col1"
                  class="sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment-option: activate to sort column ascending"
                >
                  {{ 'makeAPayment.credit#' | cxTranslate }};
                </p>
                <p
                  id="table1col2"
                  class="sorting_desc"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-sort="descending"
                  aria-label="Invoice #: activate to sort column ascending"
                >
                  {{ 'makeAPayment.apply_to_invoice' | cxTranslate }};
                </p>
                <p
                  id="table1col3"
                  class="pr8 sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Due date: activate to sort column ascending"
                >
                  {{ 'makeAPayment.credit_applied' | cxTranslate }}
                </p>
                <p
                  id="table1col4"
                  class="pr8 sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending"
                >
                  {{ 'makeAPayment.credit_balance' | cxTranslate }}
                </p>
                <p
                  id="table1col5"
                  class="pr8 sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending"
                >
                  {{ 'makeAPayment.invoice_balance' | cxTranslate }};
                </p>
                <p
                  id="table1col6"
                  class="sorting"
                  tabindex="0"
                  aria-controls="applyCreditsTable"
                  aria-label="Payment method: activate to sort column ascending"
                >
                  {{ 'makeAPayment.credit_applied_date' | cxTranslate }}
                </p>
              </section>
            </div>
            <div class="column invoice-desc-column">
              <section>
                <p headers="table1col1" class="sorting_1">
                  <a
                    *ngIf="
                      data?.docType === 'RV' ||
                      data?.docType === 'RD' ||
                      data?.docType === 'RF'
                    "
                    href="javascript: void(0);"
                    class="underline"
                    (click)="creditPdf(data?.refDocNo)"
                  >
                    {{ data?.refDocNo }}
                  </a>
                  <a
                    *ngIf="
                      data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')
                    "
                    href="javascript: void(0);"
                    class="underline"
                    (click)="creditPdf(data?.allocNmbr)"
                  >
                    {{ data?.allocNmbr }}
                  </a>
                  <span
                    *ngIf="
                      data?.docType === 'DR' ||
                      data?.docType === 'DG' ||
                      data?.docType === 'DY'
                    "
                  >
                    {{ data?.refDocNo }}
                  </span>
                  <span
                    *ngIf="
                      data?.docType === 'DZ' &&
                      !data?.allocNmbr?.startsWith('7')
                    "
                  >
                    {{ data?.allocNmbr }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data?.refhdr }}
                  </span>
                </p>
                <p headers="table1col2" class="table1col2">
                  {{ data?.applyToInvoice }}
                </p>
                <p headers="table1col3" class="right-text">
                  {{ data?.creditsApplied | currency }}
                </p>
                <p headers="table1col4" class="right-text">
                  {{ data?.creditBalance | currency }}
                </p>
                <p headers="table1col5" class="right-text">
                  {{ data?.invoiceBalance | currency }}
                </p>
                <p headers="table1col6">
                  {{ paymentDateDefault | date : 'dd/MM/yyyy' }}
                </p>
              </section>
            </div>
          </div>
        </section>
      </section>
      <section class="col-md-12 d-flex justify-content-end">
        <button
          type="button"
          (click)="back()"
          class="c-button c-button--secondary mr-4"
        >
          {{ 'makeAPayment.back_btn' | cxTranslate }}
        </button>
        <button
          type="button"
          class="c-button c-button--primary"
          (click)="applyCredits()"
          [disabled]="creditSubmitFlag"
          data-di-id="di-id-7a6cb2cd-cb37948a"
        >
          <i class="fa fa-spinner fa-spin" *ngIf="creditSubmitFlag"></i>
          {{ 'makeAPayment.credit_title' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
</section>
