import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { BackToTopComponent } from './back-to-top.component';

@NgModule({
  declarations: [BackToTopComponent],
  imports: [CommonModule],
  exports: [BackToTopComponent],
  providers: [
    provideOutlet({
      id: 'Footer',
      position: OutletPosition.AFTER,
      component: BackToTopComponent,
    }),
  ],
})
export class BackToTopModule {}
