import { Component, OnInit } from '@angular/core';
import { CmsService, OccEndpointsService } from '@spartacus/core';

@Component({
  selector: 'app-profile-ad-banner',
  templateUrl: './profile-ad-banner.component.html',
  styleUrls: ['./profile-ad-banner.component.scss'],
})
export class ProfileAdBannerComponent implements OnInit {
  cmsResponse: any;
  baseUrl: any;
  type : string;
  imageUrl: any;
  redirectionLink: any;
  constructor(
    private readonly cmsData: CmsService,
    private readonly occEndPoint: OccEndpointsService
  ) {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }

  ngOnInit() {
    this.cmsData.getComponentData('ProfileBannerComponent').subscribe((res) => {
      this.cmsResponse = res;
      if(this.cmsResponse.external === 'true'){
        this.type = 'external';
      }
      else {
        this.type = 'internal';
      }
      this.imageUrl = this.cmsResponse?.media?.url
        ? this.validateDomainNameExistence(this.cmsResponse?.media?.url)
          ? this.cmsResponse.media.url
          : this.baseUrl + this.cmsResponse.media.url
        : '';
      this.redirectionLink = this.cmsResponse?.urlLink
        ? this.validateDomainNameExistence(this.cmsResponse?.urlLink)
          ? this.cmsResponse?.urlLink
          : this.baseUrl + this.cmsResponse?.urlLink
        : '';
    });
  }

  validateDomainNameExistence(url: string) {
    return url.indexOf('https://') === 0 || url.indexOf('www.') === 0;
  }
}
