<div class="row patient-three-row">
  <ng-container *ngFor="let catalog of catalogsList">
    <div
      class="
        col-xs-12 col-sm-12 col-md-4
        patient-card
        checkout-details
        desktop-view
      "
    >
    <section class="patient-img">
      <img
        [src]="getImage(catalog.localizedPicture)"
        alt=""
        class="flu-poster"
      />
    </section>
      <span
        (click)="openNewModalPopup(catalog.localizedPicture)"
        *ngIf="catalog && catalog.localizedPicture"
      >
        <img
          src="../../../assets/icons/fullscreen.svg"
          class="fullscreen"
          alt="full"
        />
      </span>
      <h3 innerHtml="{{ catalog.name }}"></h3>
      <p></p>
      <div class="row numb-img">
        <div class="col-xs-6 col-sm-6 col-md-9 p0">
          <p class="poster-numb">
            <span class="bold"> {{ catalog.code }}</span> |
            {{ catalog?.downloadedMediaType }}
          </p>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 p0 download-sec">
          <img src="../../../assets/icons/download-icon.svg" alt="download" />
          <a
            (click)="
              downloadFile(catalog.localizedDownloadedMedia, catalog.code)
            "
            >Download</a
          >
        </div>
      </div>
    </div>

    <div
      class="
        col-xs-12 col-sm-12 col-md-12
        patient-card
        checkout-details
        tab-view
      "
    >
    <section class="patient-img">
      <img
        [src]="getImage(catalog.localizedPicture)"
        alt=""
        class="flu-poster"
      />
    </section>
      <span
        (click)="openNewModalPopup(catalog.localizedPicture)"
        *ngIf="catalog && catalog.localizedPicture"
      >
        <img
          src="../../../assets/icons/fullscreen.svg"
          class="fullscreen"
          alt="full"
        />
      </span>
      <h3 innerHtml="{{ catalog.name }}"></h3>
      <p></p>
      <div class="row numb-img">
        <div class="col-xs-6 col-sm-6 col-md-9 p0">
          <p class="poster-numb">
            <span class="bold"> {{ catalog?.code }}</span> |
            {{ catalog?.downloadedMediaType }}
          </p>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 p0 download-sec">
          <img src="../../../assets/icons/download-icon.svg" alt="download" />
          <a
            (click)="
              downloadFile(catalog.localizedDownloadedMedia, catalog.code)
            "
            >Download</a
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
