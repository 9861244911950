import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '@spartacus/storefront';
import * as $ from 'jquery';
import { ExternalsiteAlertComponent } from '../externalsite-alert/externalsite-alert/externalsite-alert.component';

@Component({
  selector: 'app-custom-bottom-footer-links',
  templateUrl: './custom-bottom-footer-links.component.html',
  styleUrls: ['./custom-bottom-footer-links.component.scss'],
})
export class CustomBottomFooterLinksComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    protected service: NavigationService,
    private readonly renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      const container = event.target.closest('.BottomFooter .childs');
      if (container) {
        if (event.target.href.indexOf('/trade-terms') == -1) {
          event.preventDefault();
          this.openPopUp(event);
        }
      }
    });

    $('#click-dns-cookie').on('click', function () {
      $('#ot-sdk-btn').click();
    });
  }

  openPopUp(event) {
    const modalRef = this.modalService.open(ExternalsiteAlertComponent, {
      windowClass: 'externalsite-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.eventReference = event.target;
  }
}
