<section class="main-Cntn-Fluid">
  <section class="pt-3">
    <section class="my-3 tabs-Section-Container m-0">
      <hr />
      <p class="txt-para" [innerHTML]="cmsValueSanetize()"></p>
      <hr />
    </section>

    <ng-container *ngIf="!isBIVV">
      <!-- ===================Credit Card========================================== -->
      <section class="lbl ml-0">
        <span>{{ 'paymentPandPre.creditCard' | cxTranslate }}</span>
      </section>
      <!-- ===================Table========================================== -->
      <section class="m-0 largeDevice-Table-Row" *ngIf="!smallDevices">
        <section class="largeDevice-Table-Div">
          <table
            aria-describedby="cardDetailsTable"
            class="cardDetailsTable largeDevice-Table"
          >
            <thead>
              <tr>
                <th class="pl-0">
                  {{ 'paymentPandPre.cardNumber' | cxTranslate }}
                </th>
                <th>{{ 'paymentPandPre.expiration' | cxTranslate }}</th>
                <th>{{ 'paymentPandPre.setDefault' | cxTranslate }}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody *ngFor="let i of userCardDataArray; let j = index">
              <ng-container *ngIf="i.cardType !== 'EC'">
                <ng-container
                  *ngTemplateOutlet="largeDevice; context: { i: i, j: j }"
                ></ng-container>
              </ng-container>
            </tbody>
          </table>
        </section>
      </section>
      <!-- ===================Table========================================== -->

      <!-- ===================Small device========================================== -->

      <section *ngIf="smallDevices" class="small-Tab-Outer-Div">
        <section
          class="row small-Tab-Row"
          *ngFor="let i of userCardDataArray; let j = index"
        >
          <ng-container *ngIf="i.cardType !== 'EC'">
            <section class="col-6 col-sm-6 col-md-6 margin-Gap heading-Small-Device">
              {{ 'paymentPandPre.cardNumber' | cxTranslate }}
            </section>
            <ng-container
              *ngTemplateOutlet="smallDevice; context: { i: i, j: j }"
            ></ng-container>
          </ng-container>
        </section>
      </section>

      <!-- ===================//Small device========================================== -->

      <section class="col-md-12 add-Card-Div p-0 mb-4">
        <hr />
        <section class="add-cards" (click)="openMFA()" *ngIf="!isAsmUser">
          <img
            alt=""
            class="mr-1 img-fluid"
            src="../../../assets/icons/more.svg"
          />
          <span class="add-card-font">{{
            'paymentPandPre.addCard' | cxTranslate
          }}</span>
        </section>
      </section>
      <!-- ===================//Credit Card========================================== -->
    </ng-container>

    <!-- ===================ACH========================================== -->
    <section class="lbl ml-0">
      <span>{{ 'paymentPandPre.checkingAccs' | cxTranslate }}</span>
    </section>
    <!-- ===================Table========================================== -->
    <section class="m-0 largeDevice-Table-Row" *ngIf="!smallDevices">
      <section class="largeDevice-Table-Div">
        <table
          aria-describedby="cardDetailsTable"
          class="cardDetailsTable largeDevice-Table"
        >
          <thead>
            <tr>
              <th class="pl-0">
                {{ 'paymentPandPre.accountNumber' | cxTranslate }}
              </th>
              <th></th>
              <th>{{ 'paymentPandPre.setDefault' | cxTranslate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody *ngFor="let i of userCardDataArray; let j = index">
            <ng-container *ngIf="i.cardType === 'EC'">
              <ng-container
                *ngTemplateOutlet="largeDevice; context: { i: i, j: j, hideExpire: true }"
              ></ng-container>
            </ng-container>
          </tbody>
        </table>
      </section>
    </section>
    <!-- ===================//Table========================================== -->

    <!-- ===================Small device========================================== -->
    <section *ngIf="smallDevices" class="small-Tab-Outer-Div">
      <section
        class="row small-Tab-Row"
        *ngFor="let i of userCardDataArray; let j = index"
      >
        <ng-container *ngIf="i.cardType === 'EC'">
          <section class="col-6 col-sm-6 col-md-6 margin-Gap heading-Small-Device">
            {{ 'paymentPandPre.accountNumber' | cxTranslate }}
          </section>
          <ng-container
            *ngTemplateOutlet="smallDevice; context: { i: i, j: j, hideExpire: true }"
          ></ng-container>
        </ng-container>
      </section>
    </section>
    <!-- ===================//Small device========================================== -->
    <section class="col-md-12 add-Card-Div p-0 mb-4">
      <hr />
      <section class="add-cards" (click)="openAch()">
        <img
          alt=""
          class="mr-1 img-fluid"
          src="../../../assets/icons/more.svg"
        />
        <span class="add-card-font">{{
          'paymentPandPre.addCheckingAccount' | cxTranslate
        }}</span>
      </section>
    </section>
  </section>
</section>

<ng-template #largeDevice let-i="i" let-j="j" let-hideExpire="hideExpire">
  <tr>
    <td class="table-primary tddata-no-bold">
      <img
        class="card-img my-auto"
        src="../../../assets/img/{{ i.cardType }}.svg"
        alt="bank"
      />
      <span>xxxx-xxxx-xxxx-{{ i.lastFourCardDigits }}</span>
    </td>
    <!-- ================Expiry ==================== -->
    <td class="table-primary tddata-bold" [ngStyle]="{visibility: hideExpire ? 'hidden' : 'visible'}">
      <span id="{{ 'expiryDisplay' + j }}"> {{i.expDate }}</span>
      <section id="{{ 'expirySlctDrpDwn' + j }}">
        <select
          class="controls px-1 dropdown-arrow dropdown-mr"
          id="ccmonth"
          [(ngModel)]="selectedExpiryMonth"
        >
          <option *ngFor="let month of monthArray" value="{{ month }}">
            {{ month }}
          </option>
        </select>

        <select class="controls px-1" [(ngModel)]="selectedExpiryYear">
          <option *ngFor="let yr of yearArray" value="{{ yr }}">
            {{ yr }}
          </option>
        </select>
      </section>
    </td>
    <!-- ================Default Check==================== -->
    <td class="table-primary">
      <section
        class="default-card-radio custom-payment-radio-control custom-radio radio_grp label-location"
      >
        <input
          type="radio"
          id="{{ 'radioBtn' + j }}"
          name="paymentInfo"
          class="custom-control-input"
          [value]="i.creditCardDefault"
          [checked]="i.creditCardDefault == true"
          [ngClass]="{ disabled: editIsClicked == false }"
          [disabled]="cardIndex != j"
        />
        <label
          class="custom-control-label secloct"
          for="{{ 'radioBtn' + j }}"
          [ngClass]="{ disabled: editIsClicked == false }"
        >
        </label>
      </section>
    </td>
    <!-- ================Action==================== -->
    <td class="table-primary text-color td-width-edit">
      <span
        id="{{ 'cancelBtn' + j }}"
        (click)="cancelCardEdit(j)"
        class="add-cards cubtn pointer btnAction mr-2"
        >{{ 'paymentPandPre.cancel' | cxTranslate }}</span
      >
      <span
        class="add-cards cubtn btnAction"
        id="{{ 'updateBtn' + j }}"
        (click)="updateCardDetails(j, i.cardId)"
        >{{ 'paymentPandPre.update' | cxTranslate }}</span
      >
      <a
        (click)="editCard(j)"
        class="cubtn pointer"
        id="{{ 'editBtn' + j }}"
        [ngClass]="{ disabled: editIsClicked == true }"
        [ngClass]="{ disablled: i.creditCardDefault == true }"
        [disabled]="editDisabled"
      >
        <i class="fas fa-pencil-alt pointer mr-2 fa-lg"></i>
        <span class="btnHover" (click)="onEdit(i)">{{
          'paymentPandPre.edit' | cxTranslate
        }}</span>
      </a>
    </td>
    <!-- ================Delete==================== -->
    <td
      class="table-primary text-color td-width-delete delete-popover-container"
    >
      <a
        class="cubtn pointer"
        [ngbPopover]="popContent"
        placement="top"
        triggers="manual"
        #g="ngbPopover"
        (click)="g.open()"
        [popoverTitle]="popTitle"
      >
        <i class="fas fa-trash-alt pointer mr-2 fa-lg"></i>
        <span class="btnHover">{{
          'paymentPandPre.delete' | cxTranslate
        }}</span>
      </a>
      <ng-template #popContent>
        <button
          class="c-button c-button--secondary card-delete-cancel"
          (click)="g.close()"
        >
          {{ 'paymentPandPre.cancel' | cxTranslate }}
        </button>
        <button
          class="c-button c-button--primary card-delete-confirm"
          (click)="deleteCard(i.cardId, i.creditCardDefault)"
        >
          {{ 'paymentPandPre.delete' | cxTranslate }}
        </button>
      </ng-template>
      <ng-template #popTitle
        ><b>{{ 'paymentPandPre.deleteCard' | cxTranslate }}</b></ng-template
      >
    </td>
  </tr>
</ng-template>

<ng-template #smallDevice let-i="i" let-j="j" let-hideExpire="hideExpire">
  <section class="col-6 col-sm-6 col-md-6 margin-Gap inner-Contents-Font">
    <img
      class="card-img my-auto"
      src="../../../assets/img/{{ i.cardType }}.svg"
      alt="bank"
    />
    <span>xxxx-xxxx-xxxx-{{ i.lastFourCardDigits }}</span>
  </section>
  <section class="col-6 col-sm-6 col-md-6 margin-Gap heading-Small-Device" [ngStyle]="{visibility: hideExpire ? 'hidden' : ''}">
    {{ 'paymentPandPre.expiration' | cxTranslate }}
  </section>
  <section class="col-6 col-sm-6 col-md-6 margin-Gap inner-Contents-Font" [ngStyle]="{visibility: hideExpire ? 'hidden' : ''}" >
    <span id="{{ 'expiryDisplay' + j }}"> {{ i.expDate }}</span>

    <section id="{{ 'expirySlctDrpDwn' + j }}">
      <select
        class="controls px-1 dropdown-arrow dropdown-mr"
        id="ccmonth"
        [(ngModel)]="selectedExpiryMonth"
      >
        <option *ngFor="let month of monthArray" value="{{ month }}">
          {{ month }}
        </option>
      </select>

      <select class="controls px-1" [(ngModel)]="selectedExpiryYear">
        <option *ngFor="let yr of yearArray" value="{{ yr }}">
          {{ yr }}
        </option>
      </select>
    </section>
  </section>
  <section class="col-6 col-sm-6 col-md-6 margin-Gap heading-Small-Device">
    {{ 'paymentPandPre.setDefault' | cxTranslate }}
  </section>
  <section class="col-6 col-sm-6 col-md-6 margin-Gap inner-Contents-Font">
    <section class="custom-control custom-radio radio_grp label-location">
      <input
        type="radio"
        id="{{ 'radioBtn' + j }}"
        name="paymentInfo"
        class="custom-control-input"
        [value]="i.creditCardDefault"
        [checked]="i.creditCardDefault == true"
        [ngClass]="{ disabled: editIsClicked == false }"
      />
      <label
        class="custom-control-label secloct"
        for="{{ 'radioBtn' + j }}"
        [ngClass]="{ disabled: editIsClicked == false }"
      >
      </label>
    </section>
  </section>
  <section class="col-12 col-sm-12 col-md-12 margin-Gap edit-button-control">
    <span
      id="{{ 'cancelBtn' + j }}"
      (click)="cancelCardEdit(j)"
      class="add-cards cubtn pointer btnAction mr-2"
      >{{ 'paymentPandPre.cancel' | cxTranslate }}</span
    >
    <span
      class="add-cards cubtn btnAction"
      id="{{ 'updateBtn' + j }}"
      (click)="updateCardDetails(j)"
      >{{ 'paymentPandPre.update' | cxTranslate }}</span
    >
    <a
      (click)="editCard(j)"
      class="cubtn pointer"
      id="{{ 'editBtn' + j }}"
      [ngClass]="{ disabled: editIsClicked == true }"
    >
      <i class="fas fa-pencil-alt pointer mr-2 fa-lg"></i>
      <span class="btnHover">{{ 'paymentPandPre.edit' | cxTranslate }}</span>
    </a>
  </section>
  <section class="col-4 col-sm-4 col-md-4 margin-Gap delete-popover-container">
    <i class="fas fa-trash-alt pointer mr-2 fa-lg"></i>
    <span
      class="btnHover"
      [ngbPopover]="popContent"
      #g1="ngbPopover"
      (click)="g1.open()"
      [popoverTitle]="popTitle"
      >{{ 'paymentPandPre.delete' | cxTranslate }}</span
    >
    <ng-template #popContent>
      <button
        class="c-button c-button--secondary popover-close popover-cancel-button"
        (click)="g1.close()"
      >
        {{ 'paymentPandPre.cancel' | cxTranslate }}
      </button>
      <button
        class="c-button c-button--primary popover-cancel-button"
        (click)="deleteCard(i.cardId, i.creditCardDefault)"
      >
        {{ 'paymentPandPre.delete' | cxTranslate }}
      </button>
    </ng-template>
    <ng-template #popTitle
      ><b>{{ 'paymentPandPre.deleteCard' | cxTranslate }}</b></ng-template
    >
  </section>
</ng-template>
