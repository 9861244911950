import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CmsService, OccConfig, RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { VscaLoginFormComponentService } from './vsca-login-form-component.service';

@Component({
  selector: 'vsca-cx-login-form',
  templateUrl: './vsca-login-form.component.html',
  styleUrls: ['./vsca-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VscaLoginFormComponent implements AfterContentInit, OnDestroy {
  constructor(
    protected service: VscaLoginFormComponentService,
    private readonly route: RoutingService,
    private sanitizer: DomSanitizer,
    private cmsService: CmsService,
    private cdr: ChangeDetectorRef,
    protected occConfig: OccConfig,
    private dataLayerService: DataLayerService
  ) { }

  form: FormGroup = this.service.form;
  isUpdating$ = this.service.isUpdating$;
  backgroundImg = '';
  formMargin = '';
  applicationForm;
  subscription: Subscription;
  formURL = this.occConfig.backend.occ.baseUrl;
  @HostBinding('class.user-form') style = true;
  componentData: any = '';

  @HostBinding('style')
  get applyBanner(): SafeStyle {
    if (!this.backgroundImg) {
      return null;
    }

    return this.sanitizer.bypassSecurityTrustStyle(`
      background-image: ${this.backgroundImg};
      background-repeat: no-repeat;
      background-size: cover;
      right: initial;
      top: initial;
    `);
  }

  onSubmit(): void {
    this.service.login();
    this.dataLayerService.loginDataLayer();
  }

  gotoLoginHelp() {
    this.route.goByUrl('/login/loginhelp');
  }

  // tslint:disable-next-line:typedef
  ngAfterContentInit() {
    this.subscription = this.cmsService.getComponentData('ReturningCustomerLoginComponent').subscribe(res => {
      this.componentData = res
      this.cdr.detectChanges()
    })
    const loginPageWrapper =
      document.getElementsByClassName('LoginPageTemplate');
    const smartEditCollection = document.getElementsByTagName('body');
    const isLoginPage = !!loginPageWrapper.length;
    if (
      smartEditCollection[0].classList.contains('smartedit-page-uid-homepage')
    ) {
      return;
    }
    if (isLoginPage) {
      this.backgroundImg = '';
      this.formMargin = '';
      return;
    }
    const sectionB2Collection = document.getElementsByClassName('Section2B');
    if (!sectionB2Collection.length) {
      return;
    }
    const sectionB2 = Array.from(sectionB2Collection)[0];
    const bannerCollection = sectionB2.getElementsByTagName('cx-banner');

    if (!bannerCollection.length) {
      return;
    }
    const bannerElement = Array.from(bannerCollection)[0];
    const imageCollection = bannerElement.getElementsByTagName('img');
    if (!imageCollection.length) {
      return;
    }
    const imgSrc = Array.from(imageCollection)[0].getAttribute('src');
    if (!imgSrc) {
      return;
    }
    bannerElement.className = 'd-block d-sm-none';
    this.backgroundImg = `url(${imgSrc})`;
    this.formMargin = '30px 3%';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}