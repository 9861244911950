import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "@spartacus/core";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { BaseService } from "../../services/base.service";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit, OnDestroy {
  baseSiteId: string;
  userAccountSubscriber: Subscription;
  userFormGrp: FormGroup;
  userId: string;
  userIsLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  sendFail: boolean = false;
  constructor (
    protected readonly modal: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private fb : FormBuilder,
    private readonly baseService: BaseService,
    private readonly authService: AuthService,
  ) {}
  ngOnInit(): void {    
    this.userAccountSubscriber = this.user.get().subscribe((res) => {
      if (res) {
        this.setFormValue(res);
        this.userId = res?.uid
      } 
    });
    this.ref.detectChanges();
  }
  setFormValue(res) {
    this.userFormGrp = new FormGroup({
      userId: new FormControl(res?.uid, [
        Validators.required
      ]),
      email: new FormControl(res?.email, [
        Validators.required,
        Validators.email,
      ]),
      subject: new FormControl('', [
        Validators.required,
        Validators.pattern("[^{}<>]*$"),
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.pattern("[^{}<>]*$"),
      ]),
    })
  }
  onSubmit(e) {
    const feedback = {
      subject: this.userFormGrp.get('subject').value,
      message: this.userFormGrp.get('message').value,
      ticketCategory: {
        name: 'Feedback',
        id: 'FEEDBACK'
      }
    }
    this.baseService.post('/users/'+this.userId+'/tickets/new',feedback).pipe(take(1)).subscribe((res) => {
      if (res != undefined || res != null) {
        this.modal.dismissAll(); 
      } else {
        this.sendFail = true;
      }
    })
  }
  openModal(feedbackModal):void {
    this.modal.open(feedbackModal, {
      centered: true,
      backdrop: 'static',
      windowClass: `locStripModal ${this.baseSiteId}`,
    });
  }
  ngOnDestroy(): void {
    this.userAccountSubscriber?.unsubscribe();
  }
}