import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomSearchService } from '../search-box/search-box/custom-search.service';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  public faqList = new BehaviorSubject<null>(null);
  faqList$ = this.faqList.asObservable();
  helpVal: any;

  constructor(
    public readonly baseService: BaseService,
    private readonly customSearchService: CustomSearchService
  ) {}

  getFAQ() {
    this.baseService.get(config.FAQ).subscribe((res) => {
      this.faqList.next(res);
      this.customSearchService.$helpValue.next('');
    });
  }
}
