<section class="row makePaymentView c-list-orders" id="productList-area">
  <section class="col-md-12">
    <table class="c-table c-table--branded d-none d-lg-table">
      <thead>
        <tr>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
          >
            {{ 'makeAPayment.invoice#' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.invoice_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.due_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-up"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'amount', paymentList, 'Number')"
          >
            {{ 'makeAPayment.invoice_balance' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of paymentList; let i = index">
          <td class="pay">
            <a
              *ngIf="
                data.docType === 'RV' ||
                data.docType === 'RD' ||
                data?.docType === 'RF'
              "
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.refDocNo)"
            >
              {{ data.refDocNo }}
            </a>

            <a
              *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.allocNmbr)"
            >
              {{ data.allocNmbr }}
            </a>
            <span
              *ngIf="
                data.docType === 'DR' ||
                data.docType === 'DG' ||
                data?.docType === 'DY'
              "
            >
              {{ data.refDocNo }}
            </span>
            <span
              *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
            >
              {{ data.allocNmbr }}
            </span>
            <span *ngIf="data.docType === 'YR'">
              {{ data.refhdr }}
            </span>
          </td>
          <td class="pay">{{ data.blineDate | euStringToUSDate }}</td>
          <td class="pay">
            <span
              [ngClass]="
                !!data.dueDate && dateComparison(data.dueDate) ? 'red' : ''
              "
              >{{ data.dueDate | euStringToUSDate }}
              <ng-template #popSaveContent>
                <span class="popover-content">{{
                  'makeAPayment.past_due_date' | cxTranslate
                }}</span>
              </ng-template>
              <span
                [ngbPopover]="popSaveContent"
                triggers="manual"
                #p1="ngbPopover"
                placement="top"
                (click)="toggle(p1)"
                container="body"
                popoverClass="popover-black popover-links-invoice"
                *ngIf="!!data.dueDate && dateComparison(data.dueDate)"
              >
                <img
                  src="assets/icons/warning_red.png"
                  alt="warningIcon"
                  class="warningIconEvent"
                />
              </span>
            </span>
          </td>
          <td class="pay">{{ data.amount | currency }}</td>
        </tr>
      </tbody>
    </table>

    <table
      aria-describedby="invoiceTable"
      class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
    >
      <tr *ngFor="let data of paymentList; let i = index">
        <td class="header">
          {{ 'makeAPayment.invoice#' | cxTranslate }}
        </td>
        <td>
          <a
            *ngIf="
              data.docType === 'RV' ||
              data.docType === 'RD' ||
              data?.docType === 'RF'
            "
            href="javascript: void(0);"
            class="underline"
            (click)="invoicePdf(data.refDocNo)"
          >
            {{ data.refDocNo }}
          </a>

          <a
            *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
            href="javascript: void(0);"
            class="underline"
            (click)="invoicePdf(data.allocNmbr)"
          >
            {{ data.allocNmbr }}
          </a>
          <span
            *ngIf="
              data.docType === 'DR' ||
              data.docType === 'DG' ||
              data?.docType === 'DY'
            "
          >
            {{ data.refDocNo }}
          </span>
          <span
            *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
          >
            {{ data.allocNmbr }}
          </span>
          <span *ngIf="data.docType === 'YR'">
            {{ data.refhdr }}
          </span>
        </td>
        <td class="header">
          {{ 'makeAPayment.invoice_date' | cxTranslate }}
        </td>
        <td>{{ data.blineDate | euStringToUSDate }}</td>
        <td class="header">
          {{ 'makeAPayment.due_date' | cxTranslate }}
        </td>
        <td>{{ data.dueDate | euStringToUSDate }}</td>
        <td class="header">
          {{ 'makeAPayment.invoice_balance' | cxTranslate }}
        </td>
        <td>{{ data.amount | currency }}</td>
      </tr>
    </table>
    <div class="col-md-12 mt-4 mt-lg-0">
      <p
        class="text-medium-fixed text-center"
        *ngIf="!(isRetrievingData | async) && !paymentList.length"
      >
        {{ 'ordersList.noOrders' | cxTranslate }}
      </p>
      <p class="text-medium-fixed text-center" *ngIf="isRetrievingData | async">
        {{ 'ordersList.loadingOrders' | cxTranslate }}
      </p>
    </div>
  </section>
</section>
