import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CheckoutService } from '../../shared/modules/checkout/checkout.service';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';

@Component({
  selector: 'app-custom-profile-and-preferences',
  templateUrl: './custom-profile-and-preferences.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomProfileAndPreferencesComponent implements OnInit, OnDestroy {
  tabSelected = 'User';
  smallerDevice: boolean;
  userData: any;
  selecTabVal = 'User Profile';
  tabDataArray = [
    { key: 'User', value: this.selecTabVal },
    { key: 'Account', value: 'Account information' },
    { key: 'Payment', value: 'Payment Settings' },
  ];
  paymentEnabled: boolean;
  userSubscriber : Subscription;
  userAccountSubscriber : Subscription;
  cardDetailsSubscriber : Subscription;
  constructor(
    private readonly pfservice: CustomProfileAndPreferencesService,
    private readonly user: UserAccountFacade,
    private readonly checkoutService: CheckoutService,
    private readonly ref: ChangeDetectorRef,
  ) {
    this.tabSelected = this.selecTabVal;
  }

  ngOnInit(): void {
    this.userSubscriber = this.user.get().subscribe((data) => {
      if (data !== undefined) {
        this.userData = data;
        this.pfservice.getAccountIdFromDb();
        this.pfservice.getUserAccountDataFromDb(this.userData.userName);
        this.userAccountSubscriber = this.pfservice.userAccountIdDetails.subscribe((data) => {
          this.pfservice.getCardFromDb(data.defaultB2BUnit.uid);
        });
        this.cardDetailsSubscriber = this.pfservice.cardDetails.subscribe((data) => {
          if (data) {
            this.paymentEnabled = data?.payNowEnabledForSite;
            this.getPaymentStatus(this.paymentEnabled);
            this.ref.detectChanges();
          }
         })
      }
    });
    this.checkoutService.tabselected$.subscribe((res: any) => {
      if (res) {
        this.tabSelected = res;
        if (this.tabSelected === 'Account information') {
          document.getElementById('Account').style.display = 'block';
          document.getElementById('User').style.display = 'none';
          document.getElementById('Payment').style.display = 'none';
        } else {
          document.getElementById('Account').style.display = 'none';
          document.getElementById('User').style.display = 'none';
          document.getElementById('Payment').style.display = 'block';
        }
        this.checkoutService.tabselected.next(false);
      }
    });
    //=====================Commented for future purpose===========================================
  }

  ngOnDestroy() {
    this.userSubscriber?.unsubscribe();
    this.userAccountSubscriber?.unsubscribe();
    this.cardDetailsSubscriber?.unsubscribe();
  }

  tabNavigationDropDown(): void {
    this.tabDataArray.forEach((data) => {
      if (data.value === this.tabSelected) {
        this.tabSelected = data.value;
        document.getElementById(data.key).style.display = 'block';
      } else {
        document.getElementById(data.key).style.display = 'none';
      }
    });
  }
  tabNavigation(content, id): void {
    // const idArray = ['User', 'Account', 'Payment'];
    const idArray = ['User', 'Account'];
    this.tabSelected = content;

    for (const item of idArray) {
      if (id === item) {
        this.pfservice.tabClicked.next(id);
        document.getElementById(item).style.display = 'block';
      } else {
        document.getElementById(item).style.display = 'none';
      }
    }
  }
  getPaymentStatus(paynowenabled) {
    if (paynowenabled) {
      this.tabDataArray = [
        { key: 'User', value: this.selecTabVal },
        { key: 'Account', value: 'Account information' },
        { key: 'Payment', value: 'Payment Settings' },
      ];
    } else {
      this.tabDataArray = [
        { key: 'User', value: this.selecTabVal },
        { key: 'Account', value: 'Account information' },
      ];
    }
  }
}
