import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthService,
  ConverterService,
  OccEndpointsService,
  OccProductAdapter,
  OccRequestsOptimizerService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomOccProductAdapter extends OccProductAdapter {
  emulateSub: Subscription;
  userSub: Subscription;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
    protected userService: UserAccountFacade,
    protected csAgentAuthService: CsAgentAuthService,
    protected authService: AuthService
  ) {
    super(http, occEndpoints, converter, requestsOptimizer);
  }

  protected getEndpoint(productCode: string, scope: string): string {
    let userId = 'current';
    this.emulateSub = this.csAgentAuthService
      .isCustomerEmulated()
      .subscribe((emStatus) => {
        if (emStatus) {
          this.userService.get().subscribe((user) => {
            if (user && user?.uid) {
              userId = user?.uid;
            }
          });
        }
      });
    const urlBuild = this.occEndpoints.buildUrl('product', {
      urlParams: { userId: userId, productCode: productCode },
      queryParams: { scope },
    });
    return urlBuild;
  }
}
