import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpErrorHandler,
  HttpResponseStatus,
  GlobalMessageType,
  Priority,
} from '@spartacus/core';

const     textMessageForWrongPdf = "Apologies, but this invoice is not available for PDF download on this platform. Please contact customer service for further details.";

@Injectable({
  providedIn: 'root',
})
export class ForbiddenHandler extends HttpErrorHandler {
  getPriority?(): Priority;

  responseStatus = HttpResponseStatus.BAD_REQUEST;

  handleError(request: HttpRequest<any>, errorResponse: HttpErrorResponse) {
    if (errorResponse.error.hasOwnProperty('errors')) {
      this.globalMessageService.add(
        errorResponse.error.errors[0].message,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
    else if (this.checkInvoicePdfError(errorResponse)) {
          this.globalMessageService.add(
              textMessageForWrongPdf,
          GlobalMessageType.MSG_TYPE_ERROR
        );
    } else {
      this.globalMessageService.add(
        errorResponse.error.error_description,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

    checkInvoicePdfError(errorResponse: HttpErrorResponse) {
        const regex = /invoicepdf\?invoiceNumber/;
        return regex.exec(errorResponse?.message);
    }
}
