<div class="c-round-chip" [ngClass]="{
    'c-round-chip-limited':
      productDetails?.stockAvailableStatus == 'LIMITED',
    'c-round-chip-low':
      productDetails?.stockAvailableStatus == 'LOW',
    'c-round-chip-normal':
      productDetails?.stockAvailableStatus == 'NORMAL'
  }" (mouseenter)="p1.open()" [ngbPopover]="popOverBatch" #p1="ngbPopover" [placement]="placement"
  (mouseleave)="p1.close()"></div>
<ng-template #popOverBatch>
  <div class="c-popover c-popover-white d-flex gap-2 justify-content-between" *ngIf="stockAvailableStatusForWarehouses.length">
    <div class="p-2 d-flex flex-column align-items-center" *ngFor="let stock of stockAvailableStatusForWarehouses">
      <div class="c-round-chip c-round-chip-{{stock?.stockAvailableStatus?.toLowerCase()}}"></div>
      <p class="text-center">
        {{ stock?.warehouseCode}}
      </p>
      <p class="w-75 text-center" [innerHTML]="('stock-availability.' + stock?.stockAvailableStatus) | cxTranslate">
      </p>
    </div>
  </div>
</ng-template>