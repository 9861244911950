import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomUserRegistrationModule } from '../custom-user-registration/custom-user-registration.module';
import { CheckoutModule } from '../shared/modules/checkout/checkout.module';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { CustomInfluenzaComponent } from './custom-influenza/custom-influenza.component';
import { FluCartComponent } from './flu-cart/flu-cart.component';
import { FluChartsComponent } from './flu-charts/flu-charts.component';
import { FluCheckoutComponent } from './flu-checkout/flu-checkout.component';
import { FluConfirmationRightContentComponent } from './flu-confirmation-right-content/flu-confirmation-right-content.component';
import { FluConfirmationComponent } from './flu-confirmation/flu-confirmation.component';
import { FluHeaderComponent } from './flu-header/flu-header.component';
import { FluReservationDetailsComponent } from './flu-reservation-details/flu-reservation-details.component';
import { InfluenzaDashbaordFooterComponent } from './influenza-dashbaord-footer/influenza-dashbaord-footer.component';
import { InfluenzaDashboardComponent } from './influenza-dashboard/influenza-dashboard.component';
import { ProductDetailsInfluenzaComponent } from './product-details-influenza/product-details-influenza.component';
import { SelectVaccinesComponent } from './select-vaccines/select-vaccines.component';

@NgModule({
  declarations: [
    CustomInfluenzaComponent,
    InfluenzaDashboardComponent,
    SelectVaccinesComponent,
    InfluenzaDashbaordFooterComponent,
    FluCartComponent,
    FluHeaderComponent,
    FluCheckoutComponent,
    ProductDetailsInfluenzaComponent,
    FluConfirmationComponent,
    FluChartsComponent,
    FluConfirmationRightContentComponent,
    FluReservationDetailsComponent,
  ],
  exports: [FluHeaderComponent],

  imports: [
    BaseChartDirective,
    I18nModule,
    CommonModule,
    NgbPopoverModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    BrowserModule,
    NgSelectModule,
    FluDashboardBannerModule,
    NgbModule,
    CheckoutModule,
    ReactiveFormsModule,
    FormsModule,
    CustomUserRegistrationModule,

    ConfigModule.withConfig({

      cmsComponents: {
        SelectVaccinePageComponent: {
          //uid
          component: SelectVaccinesComponent,
        },
        FluviewCheckoutPageComponent: {
          //uid
          component: FluCheckoutComponent,
        },
        FluCartMarketBannerComponent: {
          component: FluCartComponent,
        },
        FluviewCartPageComponent: {
          component: FluCartComponent,
        },
        SanofiFluDashboardBannerComponent: {
          component: InfluenzaDashboardComponent,
        },
        SanofiFluDashboardAlertBannerComponent: {
          component: InfluenzaDashboardComponent,
        },
        FluParagraphComponent: {
          component: InfluenzaDashboardComponent,
        },
        FluviewOrderConfirmationPageComponent: {
          component: FluConfirmationComponent,
        },
        FlureservationOverViewPageComponent: {
          component: FluReservationDetailsComponent
        },
      },
    } as CmsConfig),

  ],
  providers: [
    provideOutlet({
      id: 'FluParaMiddleSlottest',
      position: OutletPosition.REPLACE,
      component: InfluenzaDashboardComponent,
    }),
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
    provideCharts(withDefaultRegisterables())
  ],
})
export class CustomInfluenzaModule { }
