<section class="productList">
  <!-- Tab -->
  <prod-help-tab
    [selectedTab]="currentTab"
    (childSelectedTab)="childSelectedTab($event)"
    class="ml-4"
    *ngIf="searchInput?.path != 'c'"
  ></prod-help-tab>
  <!--PLP List -->
  <section class="product-page" *ngIf="productList$ | async as productList">
    <section *ngIf="currentTab == 'product'">
      <section
        *ngIf="
          productList?.products?.length > 0 ||
          productList?.freeTextSearch?.length == 0
        "
      >
        <!-- Header features -->
        <section class="pl-3 pl-lg-4 py-3 color-normal-content">
          <div
            class="row d-flex justify-content-between align-items-center mb-2"
          >
            <div class="col-md-6">
              <span class="d-none d-sm-block text-fixed-medium">
                {{ 'plp.showing' | cxTranslate }} {{ pageRange }}
                {{ 'plp.of' | cxTranslate }}
                {{ productList.pagination?.totalResults }}
                {{ 'plp.products' | cxTranslate }}
                <span *ngIf="productList?.freeTextSearch?.length > 0">
                  {{ 'plp.for' | cxTranslate }} &nbsp;
                  {{ productList?.freeTextSearch }}
                </span>
              </span>
            </div>
            <div class="col-md-6">
              <div
                class="d-flex justify-content-center justify-content-md-end text-fixed-medium"
              >
                <span class="top-page-id mr-2 pt-2" id="accPageInfo">
                  {{ 'ordersList.page' | cxTranslate }} {{ currentPage }}
                  {{ 'ordersList.of' | cxTranslate }}
                  {{ productList.pagination?.totalPages }}
                </span>
                <div class="c-pagination">
                  <cx-pagination
                    (viewPageEvent)="performPagination($event)"
                    queryParam="currentPage"
                    [pagination]="this.pagination"
                  >
                  </cx-pagination>
                </div>
              </div>
            </div>
          </div>

          <hr class="hidden-xs mt-0 mb-3" />
          <div
            class="row d-flex justify-content-between align-items-center mb-2"
          >
            <div class="col-md-12 col-lg-6 mb-4 mb-md-0">
              <div
                *ngIf="facets$?.length > 0; else filterNone"
                class="d-flex flex-column flex-md-row justify-content-begin d-lg-flex w-100"
              >
                <cx-active-facets #facetRef [facet]="facet$"></cx-active-facets>

                <div id="clearAll-filters">
                  <a
                    href="/ss/en/USD/Product-Catalog/c/1"
                    data-di-id="di-id-37774359-a6b0cba1"
                  >
                    {{ 'plp.clearFliter' | cxTranslate }}</a
                  >
                </div>
              </div>
              <ng-template #filterNone>
                <section
                  class="col-md-8 col-sm-7 hidden-xs js-facet-values filter-facet-none"
                >
                  {{ 'plp.filtersApplied' | cxTranslate }} :
                  <span>{{ 'plp.none' | cxTranslate }}</span>
                </section>
              </ng-template>
            </div>
            <section class="col-md-12 col-lg-6">
              <cx-sorting
                placeHolder="Select Sort"
                selectedOption="{{ selectedData }}"
                [sortOptions]="listSort"
                (sortListEvent)="sortList($event)"
                class="c-select"
              >
              </cx-sorting>
            </section>
          </div>
          <hr class="hidden-xs mt-3 mb-3" />
        </section>

        <section class="main">
          <section class="content content-right">
            <app-custom-product-card
              *ngFor="let product of productList.products"
              [product]="product"
              [iconDisplay]="true"
            >
            </app-custom-product-card>
          </section>
        </section>
        <section class="bottom-pagination text-fixed-medium my-4 text-center">
          <section class="showing-text">
            <span
              >{{ 'plp.showing' | cxTranslate }} {{ pageRange }}
              {{ 'plp.of' | cxTranslate }}
              {{ productList.pagination?.totalResults }}
              {{ 'plp.products' | cxTranslate }}</span
            >
          </section>
          <section class="d-flex justify-content-center my-4 c-pagination">
            <cx-pagination
              (viewPageEvent)="performPagination($event)"
              queryParam="currentPage"
              [pagination]="this.pagination"
            >
            </cx-pagination>
          </section>
          <section class="text-center">
            <span class="page-info"
              >{{ 'plp.page' | cxTranslate }} {{ currentPage }}
              {{ 'plp.of' | cxTranslate }}
              {{ productList.pagination?.totalPages }}
            </span>
          </section>
        </section>
      </section>
      <!-- No Products -->
      <section
        class="featureProdTxt mb-4 mt-4 pl-md-4"
        *ngIf="
          productList?.freeTextSearch?.length > 0 &&
          productList?.products?.length == 0
        "
      >
        <h3 class="font-bold">No results for Products</h3>
        <p id="noReuslt-found" class="mt-4" role="alert" aria-live="assertive">
          {{ 'plp.sorryNoProducts' | cxTranslate }}
          <span class="font-bold">"{{ productList?.freeTextSearch }}"</span>
          {{ 'plp.noProducts' | cxTranslate }} <br /><br />
          {{ 'plp.additionalSupport' | cxTranslate }}
          <span class="c-link" [routerLink]="['/faq']">Help Center</span>
        </p>
      </section>
    </section>
  </section>
  <!-- HELP -->
  <section *ngIf="currentTab == 'help'" class="mt-4">
    <faq-list
      [searchResults]="helpVal"
      [searchInput]="searchInput?.path"
    ></faq-list>
  </section>
</section>
