import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { LogoutModalComponent } from './logout-modal/logout-modal';

@Component({
  selector: 'app-custom-logout',
  templateUrl: './custom-logout.component.html',
  styleUrls: ['./custom-logout.component.scss'],
})
export class CustomLogoutComponent implements OnInit {
  name: string;
  nameSubscriber: Subscription;

  constructor(
    private authService: AuthService,
    private readonly user: UserAccountFacade,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.nameSubscriber = this.user.get().subscribe((res) => {
      if (res) {
        this.name = res.name;
      }
    });
  }

  // logout(): void {
  //   sessionStorage.removeItem('isShowFlag');
  //   sessionStorage.removeItem('userAccountId');
  //   this.authService.logout();
  // }
  openModal() {
    const modref = this.modalService.open(LogoutModalComponent);
    modref.componentInstance.isCheckbox = true;
  }
  ngOnDestroy() {
    if (this.nameSubscriber) {
      this.nameSubscriber.unsubscribe();
    }
  }
}
