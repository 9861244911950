import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { B2BUnitModel, DefaultB2BUnit } from '../shared/models/user.model';
import { BaseService } from '../shared/services/base.service';
@Injectable({
  providedIn: 'root',
})
export class CustomProfileAndPreferencesService {
  userData: any;
  userDataUpdate: any;
  userType: any;
  userRoles: any;
  passQues: any;
  iframeUrl: any;
  userAccountData: BehaviorSubject<DefaultB2BUnit>;
  userAccountDetails: any;
  userAccountType: any;
  userAccountdetailsType: any;
  cardDetails: any;
  tabClicked: any;
  deleteCard: any;
  updateCardDetails: any;
  profileAndPrefrencesIsActive: any;
  userAccountIdDetails: any;

  constructor(private readonly baseService: BaseService) {
    this.userDataUpdate = new Subject();
    this.userAccountData = new BehaviorSubject<DefaultB2BUnit>(null);
    this.userAccountDetails = new Subject();
    this.userData = new Subject();
    this.userRoles = new Subject();
    this.passQues = new Subject();
    this.iframeUrl = new Subject();
    this.cardDetails = new Subject();
    this.deleteCard = new Subject();
    this.updateCardDetails = new Subject();
    this.tabClicked = new Subject();
    this.profileAndPrefrencesIsActive = new Subject();
    this.userAccountIdDetails = new Subject();
  }

  public getUserDataFromDb(idNo) {
    const userId = localStorage.getItem('asmUserId');
    const asmEnabled = localStorage.getItem('asmEnabled');
    let currentUser;
    if (asmEnabled == 'false') {
      currentUser = config.USER_NAME;
    } else {
      currentUser = 'users/' + userId;
    }
    this.baseService.get(currentUser).subscribe((resp) => {
      if (resp !== null && resp !== undefined && resp?.length !== 0) {
        this.userType = resp.type;
        this.userData.next({ res: resp, id: idNo });
      }
    });
  }

  public updateUserData(params) {
    this.baseService.patch(config.USER_NAME, params).subscribe(
      (res) => {
        if (res == null) {
          this.userDataUpdate.next(true);
        }
      },
      (err) => {
        this.userDataUpdate.next(err);
      }
    );
  }

  public getUserAccountDataFromDb(userId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/`, { fields: 'FULL' })
      .subscribe((res: B2BUnitModel) => {
        const { defaultB2BUnit } = res;
        this.userAccountData.next(defaultB2BUnit);
      });
  }
  public getUserAccountDetailsDataFromDb(userId, accountId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/${accountId}`, {
        fields: 'FULL',
      })
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userAccountdetailsType = res.type;
          this.userAccountDetails.next({ res });
        }
      });
  }

  public getHintQuestions() {
    this.baseService
      .get('/enumeration?enumerationType=HintQuestions')
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.passQues.next(res);
        }
      });
  }
  public getUserRoles() {
    this.baseService
      .get('/enumeration?enumerationType=UserRole')
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userRoles.next(res);
        }
      });
  }

  public getIframeUrl(forCheckingAccounts: boolean) {
    this.baseService
      .get(
        forCheckingAccounts
          ? '/users/current/paymetric/get-iframe-ach'
          : '/users/current/paymetric/get-iframe'
      )
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.iframeUrl.next(res);
        }
      });
  }
  public getCardFromDb(id) {
    this.baseService
      .get(`${config.GET_CARD}accountId=${id}`)
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.cardDetails.next(res);
        }
      });
  }
  public getAccountIdFromDb() {
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.userAccountIdDetails.next(res);
      }
    });
  }

  public deleteCardFromDb(paymentdetailsId): void {
    this.baseService
      .delete(
        `${config.USER_ACCOUNT}current/paymentdetails/${paymentdetailsId}`,
        { fields: 'FULL' }
      )
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.deleteCard.next(res);
        }
      });
  }

  public editCardDetails(paymentdetailsId, request): void {
    this.baseService
      .patch(
        `${config.USER_ACCOUNT}current/paymentdetails/${paymentdetailsId}`,
        request
      )
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.updateCardDetails.next(res);
        }
      });
  }
}
