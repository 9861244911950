<!-- =========================Name Form============================================ -->
<section class="row py-3 mt-md-4">
  <section class="col-md-9 col-9 col-sm-9">
    <h3 class="font-bold">{{ "profile.name" | cxTranslate }}</h3>
  </section>
  <section class="col-md-3 col-3 col-sm-3">
    <div class="float-right c-link">
      <span (click)="editNameField()" *ngIf="nameEditIsNotClicked">{{
        "profile.edit" | cxTranslate
      }}</span>
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="nameEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<!-- List name -->
<section
  class="container p-0"
  [ngClass]="{
    'margin-Down': nameEditIsNotClicked,
    'edited-Fields-Margin-Value': !nameEditIsNotClicked
  }"
>
  <section class="row" *ngIf="nameEditIsNotClicked">
    <section class="col-md-12">
      <label class="font-bold"> {{ "profile.firstName" | cxTranslate }}</label>
    </section>
    <section class="col-md-12 mb-4">
      <span class="inner-Content inner-Content-Font-Color">{{
        nameForm.get("actualFirstName").value
      }}</span>
    </section>
    <section class="col-md-12">
      <label class="font-bold">{{ "profile.lastName" | cxTranslate }}</label>
    </section>
    <section class="col-md-12">
      <span class="inner-Content inner-Content-Font-Color">{{
        nameForm.get("actualLastName").value
      }}</span>
    </section>
  </section>
  <section
    class="row c-text-input mb-4"
    [formGroup]="nameForm"
    *ngIf="!nameEditIsNotClicked"
  >
    <section class="col-md-12">
      <label>{{ "profile.firstName" | cxTranslate }}</label>
    </section>
    <section class="col-md-6 mb-3">
      <input
        class="form-control text-Field-Styling"
        formControlName="actualFirstName"
        [ngClass]="{
          required:
            nameForm.get('actualFirstName').invalid &&
            (nameForm.get('actualFirstName').touched ||
              nameForm.get('actualFirstName').dirty)
        }"
      />
      <cx-form-errors
        class="text-danger"
        *ngIf="
          nameForm.get('actualFirstName').value.length == 0 &&
          (nameForm.get('actualFirstName').touched ||
            nameForm.get('actualFirstName').dirty)
        "
      >
        <p>{{ "profile.requiredError" | cxTranslate }}</p>
      </cx-form-errors>
      <cx-form-errors
        class="text-danger"
        *ngIf="
          nameForm.get('actualFirstName').value.length != 0 &&
          nameForm.get('actualFirstName').invalid &&
          (nameForm.get('actualFirstName').touched ||
            nameForm.get('actualFirstName').dirty)
        "
      >
        <p>{{ "profile.invalidName" | cxTranslate }}</p>
      </cx-form-errors>
    </section>
    <section class="col-md-12">
      <label>{{ "profile.lastName" | cxTranslate }}</label>
    </section>
    <section class="col-md-6">
      <input
        class="form-control text-Field-Styling"
        formControlName="actualLastName"
        [ngClass]="{
          required:
            nameForm.get('actualLastName').invalid &&
            (nameForm.get('actualLastName').touched ||
              nameForm.get('actualLastName').dirty)
        }"
      />
      <cx-form-errors
        class="text-danger"
        *ngIf="
          (nameForm.get('actualLastName').touched ||
            nameForm.get('actualLastName').dirty) &&
          nameForm.get('actualLastName').value.length == 0
        "
      >
        <p>{{ "profile.requiredError" | cxTranslate }}</p>
      </cx-form-errors>
      <cx-form-errors
        class="text-danger"
        *ngIf="
          nameForm.get('actualLastName').value.length != 0 &&
          nameForm.get('actualLastName').invalid &&
          (nameForm.get('actualLastName').touched ||
            nameForm.get('actualLastName').dirty)
        "
      >
        <p>{{ "profile.invalidName" | cxTranslate }}</p>
      </cx-form-errors>
    </section>
  </section>
  <section class="row" *ngIf="!nameEditIsNotClicked">
    <section class="col-md-12 d-flex justify-content-start">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelNameUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button
        class="c-button c-button--primary"
        (click)="updateNameField()"
        [disabled]="
          nameForm.get('actualFirstName').invalid ||
          nameForm.get('actualLastName').invalid
        "
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
<hr />
<!-- =========================Role============================================ -->
<section class="row mb-3">
  <section class="col-md-9 col-9 col-sm-9">
    <h3 class="font-bold">{{ "profile.role" | cxTranslate }}</h3>
  </section>
  <section class="col-md-3 col-3 col-sm-3">
    <div class="float-right c-link">
      <span
        class="edit-Option"
        (click)="editRoleField()"
        *ngIf="roleEditIsNotClicked"
        >{{ "profile.edit" | cxTranslate }}</span
      >
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="roleEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<section
  class="container p-0"
  [ngClass]="{
    'margin-Down': roleEditIsNotClicked,
    'edited-Fields-Margin-Value': !roleEditIsNotClicked
  }"
>
  <section class="row" *ngIf="roleEditIsNotClicked">
    <section class="col-md-12">
      <label class="ifont-bold">{{ displayRoleofUser }}</label>
    </section>
  </section>
  <section class="row mb-4" *ngIf="!roleEditIsNotClicked">
    <section class="col-md-6">
      <ng-select
        class="w-100"
        [items]="roleOfUserArray"
        bindLabel="name"
        bindValue="code"
        [multiple]="false"
        name="chosenRoleofUser"
        [clearable]="false"
        placeholder="Select Role"
        [(ngModel)]="chosenRoleofUser"
      >
      </ng-select>
    </section>
  </section>
  <section class="row" *ngIf="!roleEditIsNotClicked">
    <section class="col-md-12 d-flex justify-content-start">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelRoleUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button class="c-button c-button--primary" (click)="updateRoleField()">
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
<hr />
<!-- =========================password Form============================================ -->
<section class="row mb-3">
  <section class="col-md-9 col-9 col-sm-9 col-lg-9 col-xl-9">
    <h3 class="font-bold">
      <span class="header-Css">{{ "profile.password" | cxTranslate }}</span>
    </h3>
  </section>
  <section class="col-md-3 col-3 col-sm-3">
    <div class="float-right c-link">
      <span (click)="editPasswordField()" *ngIf="passwordEditIsNotClicked">{{
        "profile.edit" | cxTranslate
      }}</span>
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="passwordEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<section
  class="container p-0"
  [ngClass]="{
    'margin-Down': passwordEditIsNotClicked,
    'edited-Fields-Margin-Value': !passwordEditIsNotClicked
  }"
>
  <section class="row" *ngIf="passwordEditIsNotClicked">
    <section
      class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 fields-Margin-Value password-Display-Div"
    >
      <span class="inner-Content-Font-Color">********</span>
    </section>
  </section>
  <section
    class="container p-0"
    [formGroup]="passwordForm"
    *ngIf="!passwordEditIsNotClicked"
  >
    <section class="row">
      <!-- =================passwordSection==================== -->
      <section class="col-md-6 p-0">
        <section class="container">
          <section class="row c-text-input mb-4">
            <section class="col-md-12">
              <label class="inner-Headers">{{
                "profile.currentPassword" | cxTranslate
              }}</label>
            </section>
            <section class="col-md-12 mb-3">
              <input
                class="form-control text-Field-Styling"
                type="password"
                formControlName="currentPassword"
                role="presentation"
                autocomplete="off"
                [ngClass]="{
                  required:
                    currentPasswordIsNotValid &&
                    passwordForm.get('currentPassword').value.length >= 0 &&
                    (passwordForm.get('currentPassword').touched ||
                      passwordForm.get('currentPassword').dirty)
                }"
              />

              <cx-form-errors
                class="text-danger"
                *ngIf="
                  (passwordForm.get('currentPassword').dirty ||
                    passwordForm.get('currentPassword').touched) &&
                  passwordForm.get('currentPassword').value.length == 0
                "
                ><p>
                  {{ "profile.requiredError" | cxTranslate }}
                </p></cx-form-errors
              >

              <cx-form-errors
                class="text-danger"
                *ngIf="
                  (passwordForm.get('currentPassword').dirty ||
                    passwordForm.get('currentPassword').touched) &&
                  currentPasswordIsNotValid &&
                  passwordForm.get('currentPassword').value.length > 0
                "
                ><p>
                  {{ "profile.PasswordNotValid" | cxTranslate }}
                </p></cx-form-errors
              >
            </section>
            <section class="col-md-12">
              <label class="inner-Headers">{{
                "profile.newPassword" | cxTranslate
              }}</label>
            </section>
            <section class="col-md-12 mb-3">
              <input
                class="form-control text-Field-Styling"
                type="password"
                formControlName="newPassword"
                [ngClass]="{
                  required:
                    passwordForm.get('newPassword').value.length >= 0 &&
                    (passwordIsNotValid ||
                      passwordForm.get('newPassword').value ==
                        passwordForm.get('currentPassword').value) &&
                    (passwordForm.get('newPassword').dirty ||
                      passwordForm.get('newPassword').touched)
                }"
              />
              <cx-form-errors
                class="text-danger"
                *ngIf="
                  (passwordForm.get('newPassword').dirty ||
                    passwordForm.get('newPassword').touched) &&
                  passwordForm.get('newPassword').value.length == 0
                "
                ><p>
                  {{ "profile.requiredError" | cxTranslate }}
                </p></cx-form-errors
              >
              <cx-form-errors
                class="text-danger"
                *ngIf="
                  (passwordForm.get('newPassword').dirty ||
                    passwordForm.get('newPassword').touched) &&
                  !passwordIsNotValid &&
                  passwordForm.get('currentPassword').value.length > 0 &&
                  passwordForm.get('newPassword').value ==
                    passwordForm.get('currentPassword').value
                "
              >
                <p>
                  {{ "profile.newOldSamePass" | cxTranslate }}
                </p></cx-form-errors
              >
              <cx-form-errors
                class="text-danger"
                *ngIf="
                  (passwordForm.get('newPassword').dirty ||
                    passwordForm.get('newPassword').touched) &&
                  passwordIsNotValid &&
                  passwordForm.get('newPassword').value.length > 0
                "
                ><p>
                  {{ "profile.PasswordNotValid" | cxTranslate }}
                </p></cx-form-errors
              >
            </section>
            <section class="col-md-12">
              <label class="inner-Headers">{{
                "profile.confirmPassword" | cxTranslate
              }}</label>
            </section>
            <section class="col-md-12 fields-Margin-Value">
              <input
                id="confirmPasswordField"
                class="form-control text-Field-Styling"
                type="password"
                formControlName="confirmNewPassword"
                [ngClass]="{
                  required:
                    (passwordForm.get('confirmNewPassword').value.length == 0 ||
                      passwordForm.get('confirmNewPassword').value !=
                        passwordForm.get('newPassword').value) &&
                    (passwordForm.get('confirmNewPassword').touched ||
                      passwordForm.get('confirmNewPassword').dirty),
                  block:
                    passwordForm.get('newPassword').value.length == 0 ||
                    passwordForm.get('currentPassword').value.length == 0 ||
                    passwordForm.get('newPassword').value ==
                      passwordForm.get('currentPassword').value
                }"
              />
              <cx-form-errors
                class="text-danger"
                *ngIf="
                  passwordForm.get('confirmNewPassword').value !=
                    passwordForm.get('newPassword').value &&
                  passwordForm.get('confirmNewPassword').value.length > 0 &&
                  (passwordForm.get('confirmNewPassword').dirty ||
                    passwordForm.get('confirmNewPassword').touched)
                "
                ><p>
                  {{ "profile.passMisMatch" | cxTranslate }}
                </p></cx-form-errors
              >
              <cx-form-errors
                class="text-danger"
                *ngIf="
                  passwordForm.get('confirmNewPassword').value.length == 0 &&
                  (passwordForm.get('confirmNewPassword').dirty ||
                    passwordForm.get('confirmNewPassword').touched)
                "
                ><p>
                  {{ "profile.requiredError" | cxTranslate }}
                </p></cx-form-errors
              >
            </section>
          </section>
        </section>
      </section>
      <!-- ===================PasswordInfoSection========================== -->
      <section class="col-md-6 pt-4">
        <section class="passwordInfo-Div">
          <section class="passowrdInfo-Span">
            {{ "profile.passInstruction" | cxTranslate }}
          </section>
          <ul class="password-Instruction">
            <li>{{ "profile.lowerCaseLetter" | cxTranslate }}</li>
            <li>{{ "profile.upperCaseLetter" | cxTranslate }}</li>
            <li>{{ "profile.number" | cxTranslate }}</li>
            <li>
              {{ "profile.symbol" | cxTranslate }}
            </li>
          </ul>
        </section>
      </section>
    </section>
  </section>
  <span class="text-danger" *ngIf="cPassError !== ''">
    {{ cPassError }}
  </span>
  <section class="row" *ngIf="!passwordEditIsNotClicked">
    <section class="col-md-12 d-flex justify-content-start">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelPasswordUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button
        class="c-button c-button--primary"
        (click)="updatePasswordField()"
        [disabled]="
          passwordForm.invalid ||
          passwordIsNotValid ||
          currentPasswordIsNotValid ||
          passwordForm.get('newPassword').value !=
            passwordForm.get('confirmNewPassword').value
        "
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
<hr />
<!-- ==========================================Password Recocecry============================== -->
<section class="row mb-3">
  <section class="col-md-9 col-9 col-sm-9">
    <h3 class="font-bold">{{ "profile.passRecQue" | cxTranslate }}</h3>
  </section>
  <section class="col-md-3 col-3 col-sm-3">
    <div class="float-right c-link float-right">
      <span
        class="edit-Option"
        (click)="editPassRecField()"
        *ngIf="passRecEditIsNotClicked"
        >{{ "profile.edit" | cxTranslate }}</span
      >
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="nameEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<section
  class="container p-0"
  [ngClass]="{
    'margin-Down': passRecEditIsNotClicked,
    'edited-Fields-Margin-Value': !passRecEditIsNotClicked
  }"
>
  <section class="row" *ngIf="passRecEditIsNotClicked">
    <section class="col-md-12 mb-2">
      <span class="inner-Content inner-Content-Font-Color">{{
        displayQueForPassRecovery
      }}</span>
    </section>
    <section class="col-md-12">
      <span class="inner-Content-Font-Color">********</span>
    </section>
  </section>
  <section class="row" *ngIf="!passRecEditIsNotClicked">
    <section class="col-md-12 cx-text-input">
      <label class="inner-Headers"
        >{{ "profile.passRecQue" | cxTranslate }}
      </label>
    </section>
    <section class="col-md-6 mb-4">
      <ng-select
        [items]="passRecQuesArray"
        bindLabel="name"
        bindValue="code"
        [multiple]="false"
        name="chosenRoleofUser"
        [clearable]="false"
        placeholder="Select Question"
        [(ngModel)]="chosenQueForPassRecovery"
        class="w-100"
      >
      </ng-select>
    </section>
    <section class="col-md-12 c-text-input">
      <label class="inner-Headers">
        {{ "profile.passRecAns" | cxTranslate }}
      </label>
    </section>
    <section class="col-md-6 c-text-input">
      <input
        [ngClass]="{
          required: ansForQuePassRecovery == '' && ansForQuePassRecovery != null
        }"
        [(ngModel)]="ansForQuePassRecovery"
      />
      <cx-form-errors
        class="text-danger"
        *ngIf="ansForQuePassRecovery == '' && ansForQuePassRecovery != null"
      >
        <p>{{ "profile.requiredError" | cxTranslate }}</p>
      </cx-form-errors>
    </section>
  </section>
  <section class="row mt-4" *ngIf="!passRecEditIsNotClicked">
    <section class="col-md-12 d-flex justify-content-start">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelPassRecUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button
        [disabled]="
          ansForQuePassRecovery == '' || chosenQueForPassRecovery == ''
        "
        class="c-button c-button--primary"
        (click)="updatePassRecQues()"
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
<hr />
<!-- ==========================================Email============================== -->
<section class="row mb-3">
  <section class="col-9">
    <h3 class="font-bold">{{ "profile.email" | cxTranslate }}</h3>
  </section>
  <section class="col-3">
    <div class="float-right c-link">
      <span
        class="edit-Option"
        (click)="editEmailField()"
        *ngIf="emailEditIsNotClicked"
        >{{ "profile.edit" | cxTranslate }}</span
      >
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="emailEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<section
  class="container p-0"
  [ngClass]="{
    'margin-Down': emailEditIsNotClicked,
    'edited-Fields-Margin-Value': !emailEditIsNotClicked
  }"
>
  <section class="row" *ngIf="emailEditIsNotClicked">
    <section class="col-md-12">
      <span class="inner-Content inner-Content-Font-Color">{{
        emailForm.get("emailId").value
      }}</span>
    </section>
  </section>

  <section class="row" [formGroup]="emailForm" *ngIf="!emailEditIsNotClicked">
    <section class="col-md-12 c-text-input">
      <label class="inner-Headers"> {{ "profile.email" | cxTranslate }}</label>
    </section>
    <section class="col-md-6 c-text-input mb-3">
      <input
        class="form-control text-Field-Styling"
        formControlName="emailId"
        [ngClass]="{
          required:
            (emailForm.get('emailId').value.length == 0 ||
              emailForm.get('emailId').invalid) &&
            (emailForm.get('emailId').touched || emailForm.get('emailId').dirty)
        }"
      />

      <cx-form-errors
        class="text-danger"
        *ngIf="
          emailForm.get('emailId').invalid &&
          (emailForm.get('emailId').dirty ||
            emailForm.get('emailId').touched) &&
          emailForm.get('emailId').value.length != 0
        "
      >
        <p>{{ "profile.invalidEmail" | cxTranslate }}</p>
      </cx-form-errors>
      <cx-form-errors
        class="text-danger"
        *ngIf="
          (emailForm.get('emailId').dirty ||
            emailForm.get('emailId').touched) &&
          emailForm.get('emailId').value.length == 0
        "
      >
        <p>{{ "profile.requiredError" | cxTranslate }}</p>
      </cx-form-errors>
    </section>

    <section class="col-md-12 c-text-input">
      <label class="inner-Headers">{{
        "profile.confirmEmail" | cxTranslate
      }}</label>
    </section>
    <section class="col-md-6 c-text-input">
      <input
        class="form-control text-Field-Styling"
        formControlName="confirmEmailId"
        [ngClass]="{
          required:
            (emailForm.get('confirmEmailId').value.length == 0 ||
              emailForm.get('confirmEmailId').value !=
                emailForm.get('emailId').value) &&
            (emailForm.get('confirmEmailId').touched ||
              emailForm.get('confirmEmailId').dirty),
          block: emailForm.get('emailId').value.length == 0
        }"
      />
      <cx-form-errors
        class="text-danger"
        *ngIf="
          emailForm.get('confirmEmailId').value !=
            emailForm.get('emailId').value &&
          emailForm.get('confirmEmailId').value != 0 &&
          (emailForm.get('confirmEmailId').touched ||
            emailForm.get('confirmEmailId').dirty)
        "
      >
        <p>{{ "profile.EmailIdnotMisMatch" | cxTranslate }}</p>
      </cx-form-errors>
      <cx-form-errors
        class="text-danger"
        *ngIf="
          emailForm.get('confirmEmailId').value == 0 &&
          (emailForm.get('confirmEmailId').touched ||
            emailForm.get('confirmEmailId').dirty)
        "
      >
        <p>{{ "profile.requiredError" | cxTranslate }}</p>
      </cx-form-errors>
    </section>
  </section>
  <section class="row mt-4" *ngIf="!emailEditIsNotClicked">
    <section class="col-md-12 d-flex justify-content-start">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelEmailUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button
        class="c-button c-button--primary"
        (click)="updateEmailField()"
        [disabled]="
          emailForm.invalid ||
          emailForm.get('confirmEmailId').value !=
            emailForm.get('emailId').value
        "
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
<hr />
<!-- Communication-->
<section class="row mb-3">
  <section class="col-md-9 col-9 col-sm-9">
    <h3 class="font-bold">{{ "profile.communication" | cxTranslate }}</h3>
  </section>
  <section class="col-md-3 col-3 col-sm-3">
    <div class="float-right c-link">
      <span
        class="edit-Option"
        (click)="editEmailCommField()"
        *ngIf="commConfmEditIsNotClicked"
        >{{ "profile.edit" | cxTranslate }}</span
      >
      <fa-icon
        [icon]="faPenToSquare"
        *ngIf="commConfmEditIsNotClicked"
        class="icon"
      ></fa-icon>
    </div>
  </section>
</section>
<section
  class="container p-0 mb-4"
  [ngClass]="{
    'margin-Down': commConfmEditIsNotClicked,
    'edited-Fields-Margin-Value': !commConfmEditIsNotClicked,
    'emailDiv-Top': !commConfmEditIsNotClicked
  }"
>
  <section class="row" *ngIf="commConfmEditIsNotClicked">
    <section class="col-md-12 inner-Content inner-Content-Font-Color">
      <span
        class="emailConstent-msg"
        *ngIf="emailCommunicationStatus === 'Yes'"
        >{{ "profile.emailCommStatusYes" | cxTranslate }}</span
      >
      <span
        class="emailConstent-msg"
        *ngIf="emailCommunicationStatus != 'Yes'"
        >{{ "profile.emailCommStatusNo" | cxTranslate }}</span
      >
    </section>
  </section>
  <!-- <section
    class="row"
    *ngIf="
      commConfmEditIsNotClicked &&
      alternateEmailForm.get('alternateEmailId').value.length > 0
    "
  >
    <section class="col-md-12">
      <span class="alternate-Mail-Header">{{
        "profile.alternateEmail" | cxTranslate
      }}</span>
    </section>
    <section class="col-md-12">
      <span class="alternate-Mail-Value">{{
        alternateEmailForm.get("alternateEmailId").value
      }}</span>
    </section>
  </section> -->
  <section class="row" *ngIf="!commConfmEditIsNotClicked">
    <section class="col-md-12 col-12 col-sm-12">
      <span class="emailCommunication-Que">{{
        "profile.emailCommHeaderQue" | cxTranslate
      }}</span>
    </section>
    <section class="col-xl-2 col-lg-2 col-md-3 col-3 col-sm-3 w-50">
      <div class="custom-control custom-radio radio_grp label-location">
        <input
          type="radio"
          id="emailConsentYesRadio"
          name="emailConsent"
          class="custom-control-input"
          value="true"
          [(ngModel)]="emailConsent"
        />
        <label class="custom-control-label secloct" for="emailConsentYesRadio">
          Yes
        </label>
      </div>
    </section>
    <section class="col-xl-1 col-lg-1 col-md-3 col-3 col-sm-3 p-0">
      <div class="custom-control custom-radio radio_grp label-location w-50">
        <input
          type="radio"
          id="emailConsentNoRadio"
          name="emailConsent"
          class="custom-control-input"
          value="false"
          [(ngModel)]="emailConsent"
        />
        <label class="custom-control-label secloct" for="emailConsentNoRadio">
          No
        </label>
      </div>
    </section>
  </section>

  <!-- <section
    class="row alternate-Email-Row mt-3"
    *ngIf="emailConsent == 'true' && !commConfmEditIsNotClicked"
  >
    <div class="col-md-12">
      <span (click)="addAlternateEmail()" class="addAlternateEmail-Span">
        {{ "profile.AddAlterMail" | cxTranslate }}</span
      >
    </div>
  </section> -->
  <section
    class="row"
    [formGroup]="alternateEmailForm"
    *ngIf="showAlternateEmailField && emailConsent == 'true'"
  >
    <section class="col-md-12">
      <label class="inner-Headers">{{
        "profile.alterMailHeader" | cxTranslate
      }}</label>
    </section>
    <section
      class="col-9 col-xs-9 col-sm-10 col-md-10 col-lg-12 col-xl-7 fields-Margin-Value"
    >
      <input
        class="form-control text-Field-Styling"
        formControlName="alternateEmailId"
        [ngClass]="{
          required:
            (alternateEmailForm.get('alternateEmailId').invalid ||
              alternateEmailForm.get('alternateEmailId').value.length == 0) &&
            (alternateEmailForm.get('alternateEmailId').touched ||
              alternateEmailForm.get('alternateEmailId').dirty)
        }"
      />
      <span
        class="text-danger"
        *ngIf="
          (alternateEmailForm.get('alternateEmailId').dirty ||
            alternateEmailForm.get('alternateEmailId').touched) &&
          alternateEmailForm.get('alternateEmailId').value.length == 0
        "
        >{{ "profile.requiredError" | cxTranslate }}</span
      >
      <span
        class="text-danger"
        *ngIf="
          alternateEmailForm.get('alternateEmailId').invalid &&
          (alternateEmailForm.get('alternateEmailId').dirty ||
            alternateEmailForm.get('alternateEmailId').touched) &&
          alternateEmailForm.get('alternateEmailId').value.length != 0
        "
      >
        {{ "profile.invalidEmail" | cxTranslate }}</span
      >
    </section>
    <div class="email-Delete-Div">
      <i class="fas fa-trash-alt pointer"></i> &nbsp;<span
        class="email-Delete"
        (click)="deleteAlternateEmail()"
        >{{ "profile.delete" | cxTranslate }}</span
      >
    </div>
    <section class="col-md-12">
      <label class="inner-Headers">{{
        "profile.confirmAlterMail" | cxTranslate
      }}</label>
    </section>
    <section
      class="col-9 col-xs-9 col-sm-10 col-md-10 col-lg-12 col-xl-7 fields-Margin-Value"
    >
      <input
        class="form-control text-Field-Styling"
        formControlName="confirmAlternateEmailId"
        [ngClass]="{
          required:
            (alternateEmailForm.get('confirmAlternateEmailId').value.length ==
              0 ||
              alternateEmailForm.get('confirmAlternateEmailId').value !=
                alternateEmailForm.get('alternateEmailId').value) &&
            (alternateEmailForm.get('confirmAlternateEmailId').touched ||
              alternateEmailForm.get('confirmAlternateEmailId').dirty)
        }"
      />
      <span
        class="text-danger"
        *ngIf="
          (alternateEmailForm.get('confirmAlternateEmailId').touched ||
            alternateEmailForm.get('confirmAlternateEmailId').dirty) &&
          alternateEmailForm.get('confirmAlternateEmailId').value.length == 0
        "
        >{{ "profile.requiredError" | cxTranslate }}</span
      >
      <span
        class="text-danger"
        *ngIf="
          alternateEmailForm.get('confirmAlternateEmailId').value !=
            alternateEmailForm.get('alternateEmailId').value &&
          alternateEmailForm.get('confirmAlternateEmailId').value.length > 0 &&
          (alternateEmailForm.get('confirmAlternateEmailId').dirty ||
            alternateEmailForm.get('confirmAlternateEmailId').touched)
        "
        >Fields Doesn't Match</span
      >
    </section>
  </section>
  <!-- =======================================///alternateEMAILButton================== -->
  <section
    class="row w-100 btn-Row"
    *ngIf="
      !commConfmEditIsNotClicked &&
      showAlternateEmailField == true &&
      emailConsent == 'true'
    "
  >
    <section class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btn-Padding">
      <button
        class="btn btn-default cancelBtn-Css"
        (click)="cancelEmailConsentUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
    </section>
    <section
      class="col-md-4 col-12 col-sm-12 col-lg-3 col-xl-3 btnMargin-Top btn-Padding"
    >
      <button
        class="btn btn-info updateBtn-Css"
        [disabled]="
          alternateEmailForm.invalid ||
          alternateEmailForm.get('confirmAlternateEmailId').value !=
            alternateEmailForm.get('alternateEmailId').value
        "
        (click)="updateEmailCommunField()"
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
  <section
    class="row"
    *ngIf="
      !commConfmEditIsNotClicked &&
      (showAlternateEmailField == false || emailConsent == 'false')
    "
  >
    <section class="col-md-12 d-flex justify-content-start mt-4">
      <button
        class="c-button c-button--secondary mr-2"
        (click)="cancelEmailCommuUpdate()"
      >
        {{ "profile.cancel" | cxTranslate }}
      </button>
      <button
        class="c-button c-button--primary"
        (click)="updateEmailCommunField()"
      >
        {{ "profile.update" | cxTranslate }}
      </button>
    </section>
  </section>
</section>
