import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CmsService,
  LanguageService,
  ProductService,
  RoutingService,
} from '@spartacus/core';
import { CheckoutService } from '../../../shared/modules/checkout/checkout.service';
import { AccountOverviewService } from '../../account-overview.service';

@Component({
  selector: 'app-acc-overview-placeholder',
  templateUrl: './acc-overview-placeholder.component.html',
  styleUrls: ['./acc-overview-placeholder.component.scss'],
})
export class AccOverviewPlaceholderComponent implements OnInit {
  accountNumber: string = null;
  compNameAdBanner = 'SanofiAccountOverviewBannerComponent4';
  compNameAdBanner2 = 'SanofiAccountOverviewBannerComponent2';
  compNameAdBanner3 = 'SanofiAccountOverviewBannerComponent3';
  compNameAdBanner6 = 'SanofiAccountOverviewBannerComponent6';
  compNameAdBanner1 = 'SanofiAccountOverviewBannerComponent';
  cmsResponse: any;
  productCodes: any;
  prodcutsInfo: any = [];
  prodDocs: any = [];
  prodDocus: any = [];
  fluResevartion: boolean;

  constructor(
    private readonly acService: AccountOverviewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly cmsData: CmsService,
    protected productService: ProductService,
    private readonly checkoutService: CheckoutService,
    private readonly sanitizer: DomSanitizer,
    private readonly lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.lang.getActive().subscribe((res) => {
      if (res) {
        this.prodcutsInfo = [];
      }
    });
    this.acService.userAccountIdDetailsData.subscribe((res) => {
      if (this.accountNumber === null) {
        this.accountNumber = res.defaultB2BUnit.uid;
        this.cdr.detectChanges();
      }
    });

    this.cmsData
      .getComponentData('ProductMonographComponent')
      .subscribe((res) => {
        this.cmsResponse = res;
      });

    // this.productMonograph();
  }

  navigateToAccountDetails() {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  productMonograph() {
    this.productCodes = this.cmsResponse.productCodes;
    this.productCodes = this.productCodes.split(' ');
    this.productCodes.forEach((element) => {
      this.productService.get(element).subscribe((res) => {
        if (res) {
          this.prodcutsInfo.push(res);
          this.cdr.detectChanges();
        }
      });
    });
  }
}
