import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FacetCollapseState,
  FacetService,
  ProductFacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { ProductUtils } from '../../shared/utils/productUtils';
import { CustomProdPgService } from '../custom-prod-pg.service';

@Component({
  selector: 'app-custom-product-refinement',
  templateUrl: './custom-product-refinement.component.html',
})
export class CustomProductRefinementComponent implements OnInit, OnDestroy {
  [x: string]: any;
  public productList$ = this.productListService.model$;
  public facetList$ = this.test.facetList$;
  state$: Observable<FacetCollapseState>;
  facetList: any;
  facets: any;
  activefacets: any;
  userDetails: any;
  categoryList: any = [];
  categoryFormat: any = [];
  @ViewChild('facetsRwd', { static: false }) private readonly facetsRwd;
  selectedCategory: any;
  userDataSub: Subscription;
  facetListSub: Subscription;
  categorySub: Subscription;
  constructor(
    private readonly productListService: ProductListComponentService,
    private readonly test: ProductFacetService,
    private readonly facetService: FacetService,
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly customProdService: CustomProdPgService
  ) {}

  ngOnInit(): void {
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
    });

    let position;
    this.facetListSub = this.facetService.facetList$.subscribe((res) => {
      res.facets[0]?.values.forEach((resp, index) => {
        if (resp.name === 'Product Catalog') {
          position = index;
        }
      });
      const displayFacets = JSON.parse(JSON.stringify(res.facets));
      displayFacets[0]?.values.splice(position, 1);

      this.facets$ = displayFacets;

      this.activefacets = res?.activeFacets?.length;
//       this.hide();
    });

    this.customProdService.getCategory();
    this.categorySub = this.customProdService.category$.subscribe((res) => {
      if (res) {
        this.categoryList = res;
        this.categoryFormat = ProductUtils.categoryFormatting(
          this.categoryList
        );
        this.selectedCategory = this.categoryFormat[0].value;
      }
    });
  }

  refine(): void {
    this.modalService.open(this.facetsRwd);
  }

  hide(): void {
    this.modalService.dismissAll(this.facetsRwd);
  }
  ngOnDestroy() {
    this.userDataSub?.unsubscribe();
    this.facetListSub?.unsubscribe();
    this.categorySub?.unsubscribe();
  }
}
