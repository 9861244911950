import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appZipMask]'
})
export class ZipMaskDirective {
    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;

        let trimmed = input.value.replace(/\D/g, '');
        //let newVal = event.replace(/\D/g, '');
        if (trimmed.length > 10) {
            trimmed = trimmed.substr(0, 10);
        }
        trimmed = trimmed.replace(/-/g, '');
        let numbers = [];
        numbers.push(trimmed.substr(0, 5));
        if (trimmed.substr(5, 5) !== "")
            numbers.push(trimmed.substr(5, 5));
        input.value = numbers.join('-');
    }

}
