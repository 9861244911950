import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  GenericLinkModule,
  NavigationModule,
  OutletPosition,
  provideOutlet,
} from '@spartacus/storefront';
import { CustomBottomFooterLinksComponent } from './custom-bottom-footer-links.component';

@NgModule({
  declarations: [CustomBottomFooterLinksComponent],
  imports: [
    CommonModule,
    GenericLinkModule,
    RouterModule,
    NavigationModule,
    GenericLinkModule,
  ],
  exports: [CustomBottomFooterLinksComponent],
  providers: [
    provideOutlet({
      id: 'BottomFooter',
      position: OutletPosition.BEFORE,
      component: CustomBottomFooterLinksComponent,
    }),
  ],
})
export class CustomBottomFooterLinksModule {}
