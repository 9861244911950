<div class="row left-menu">
  <div class="menu-content">
    <h3 class="menu">{{ "patientEducation.browseByTopic" | cxTranslate }}</h3>
    <p *ngFor="let catalog of catalogTypes" class="list">
      <a class="title" (click)="getCatalogType(catalog.id)">{{
        catalog.name
      }}</a>
    </p>
  </div>
</div>
<div class="dropdown-list">
  <ng-select
    name="browseByTopic"
    [items]="catalogTypes"
    [searchable]="false"
    [clearable]="false"
    bindLabel="name"
    bindValue="id"
    placeholder="Browse by topic"
    class="dropdown-list"
    (ngModelChange)="getCatalogType($event)"
    [(ngModel)]="selectedtype"
  >
  </ng-select>
</div>
