<div class="row mt-4 mt-md-0">
  <div class="col-12">
    <div class="c-tabs d-flex justify-content-end">
      <div
        class="c-tab active pt-2 pb-2 pl-4 pr-4"
        (click)="toggleTab('product', $event)"
      >
        Products
      </div>
      <div
        class="c-tab pt-2 pb-2 pl-4 pr-4"
        (click)="toggleTab('help', $event)"
      >
        Help Center
      </div>
    </div>
    <hr class="ml-md-4 mt-0" />
  </div>
</div>
