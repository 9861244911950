import { NgModule } from '@angular/core';
import { EuStringToUSDate } from './eu-string-to-us-date';
import { SortPipe } from './sort.pipe';

const pipes = [EuStringToUSDate, SortPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class CustomPipesModule {}
